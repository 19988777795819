.actionlist-container {
    
}
.actionlist-container textarea {
    border: 1px solid transparent;
    resize: none;
    /* border: 1px solid #ccc;
    background: red; */
}
.actionlist-container textarea:hover {
    border: 1px solid #ccc;
}
    .actionlist-container textarea:focus {
    border: 1px solid #0099DE;
    resize: both;
}
/* '---------------------' */
.actionlist-container .date-selector-button {
    font-size: 12px;
    text-align: center;
    border-bottom: 2px solid transparent;
}
.actionlist-container .date-selector-button:hover {
    border-bottom: 2px solid #0099DE;
}
.actionlist-container input[type="text"].date-selector {
    border: 1px solid transparent;
    padding: 3px;
    font-size: 12px;
    min-width: 90px;
}
.actionlist-container input[type="text"].date-selector:hover {
    border: 1px solid #ccc;
}
.actionlist-container input[type="text"].date-selector:focus {
    border: 1px solid #0099DE;
}

improvement-action-list {
    .title-field {
        min-width: 250px;min-height:15px;
        display: flex;
        input {
            max-width: 62rem;
            flex-basis: 250px;
            flex-grow: 1;
            flex-shrink: 0;
        }
    }
}