.smart-kanban {
    height: 100%;
    background: var(--digilean-secondary-background);
    display: block;
    overflow-y: auto;
}
.smart-kanban .smart-kanban-column {
    background: var(--digilean-secondary-background);
}
.smart-kanban .smart-kanban-card {
    position: relative;
    padding: 10px;
    background: var(--digilean-secondary-background);
    margin-bottom: 10px;
    border: 1px solid silver;
    border-top: 3px solid #c0c0cc;
    border-radius: 5px;
    min-height: 50px;
    overflow: auto;
    /*-webkit-box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);-moz-box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);*/
}
.smart-kanban .column-title {
    min-height: 40px;
    padding: 5px;
    padding-right: 10px;
    position: relative;
    margin-bottom: 3px;
}
.smart-kanban .column-title h2 {
    margin: 0;
    font-size: 2em;
    font-weight: 400;
}
.smart-kanban .column-image {
    margin-right: 12px;
}
.smart-kanban .task-column {
    /* min-width: 300px; */
}
.smart-kanban .task-column .task-count {
    /* min-width: 300px; */
    text-align: center;
    border: 1px solid silver;
    padding: 3px;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 5px;
    border-radius: 10px;
    min-width: 30px;
    /*-webkit-box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);-moz-box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);*/
}
.smart-kanban .task-column .add-task-button {
    position: absolute;
    right: 5px;
}
.smart-kanban .task-column .add-task {
    font-size: 16px;
    height: 30px;
    width: 30px;
    padding: 3px;
    margin: 0;
}
.smart-kanban .task-list {
    background: var(--digilean-tasklist-background);/*#f0f0f0;*/
    margin-bottom: 20px;
    min-height: 200px;
    height: 100%;

}
.smart-kanban .smart-kanban-card .title {
    font-size: 1.3em;
    /* font-weight: bold; */
}
.smart-kanban .smart-kanban-card .responsible {
    position: absolute;
    top: 5px;
    right: 10px;
}
.smart-kanban .smart-kanban-card .subtasks {
   margin-right: 40px;
}
.smart-kanban .smart-kanban-card .duedate {
    text-align: left;
        width: 80px;
    font-size: 12px;
}
.smart-kanban .ui-sortable-placeholder {
    border: 1px dashed #cecece !important;
    background: var(--digilean-table-border);/*#e7eaec;*/
    min-height: 50px;
    margin: 10px;
}
.smart-kanban .smart-action-status {
    font-size: 28px;
}