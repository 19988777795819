zoom-selector, .zoom-selector {
    display: inline-block;
    width:fit-content;
}
.zoom-selector {
    background: white;
    border: 1px solid var(--digilean-input-border);
    border-radius: 5px;
}
.zoom-selector table {
    min-height: 20px;
}
.zoom-selector .zoom-icon {
    padding:5px;
    padding-right: 5px;
    font-size: 14px;
}
.zoom-selector .zoom-value {
    margin-left: 5px; 
    width: 40px;
     min-width: 40px; 
     display: inline-block;
     font-size: 12px;
     text-align: center;
}
.zoom-selector .zoom-button {
    width: 20px;
}
.zoom-selector .border-left {
    border-left: 1px solid var(--digilean-input-border);
}
.zoom-selector .border-right {
    border-right: 1px solid var(--digilean-input-border);
}
.zoom-selector .zoom-button div {
    height: 100%;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.zoom-selector .zoom-button:hover {
    background: var(--digilean-grey-haze);
}