
#actionlist .collapse-icon{
    margin-right:6px;
    font-size:10px;
}

#actionlist .title-line{
    border-bottom: 1px solid #e7eaec;
}

#actionlist .task-text{
    word-wrap:break-word;
}




