html {
    touch-action: auto;
    overscroll-behavior: none;
}
body {
    width: 100vw;
    background: var(--digilean-primary-background);
    margin: 0;
}

/*
loading logo
*/
svg.svg-defs {
    display: none;
}
div#loading-screen {
    padding: 0;
    margin: 0;
    height: 100vh;
    background: var(--digilean-main-menu-background);
    color: var(--digilean-primary-text);
    display: flex;
    justify-content: center;
    align-items: center;
}
figure.digilean-logo.loading, figure.digilean-logo.loading svg {
    width: 15rem;
    height: 15rem;
}
#digilean-logo {
    --logo-top-left-color: var(--digilean-blue-sky-light);
    --logo-top-right-color: var(--digilean-blue);
    --logo-bottom-left-color: var(--digilean-blue);
    --logo-bottom-right-color: var(--digilean-blue);
    animation: spin 3s linear infinite;
    transform-origin: center;
}
/*
*/
input[type=number] {
    text-align: right;
}

.full-size {
    width: 100%;
    height: 100%;
}

.darkblue-bg {
    background: #2f4050;
}

.navbar-static-side {
    height: 100vh;
    background: #2f4050;
}

.custom-dropdown-form {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--digilean-grey);
}

.pulseattention {
    animation: pulse 2s infinite;
  }
  .pulseattention:hover {
    animation: none;
  }

/* The Overlay (background) */
.overlay {
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: var(--digilean-main-menu-background);
    overflow-x: hidden;
    transition: 0.5s;
}


    .overlay h1 {
        color: var(--digilean-white);
    }
/* Position the content inside the overlay */
.overlay-content {
    position: relative;
    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}
.overlay-content .tabs-warning {
    margin: 20px;
    font-size: 16px;
}
.modal-info {
    background: var(--digilean-important);
    color: var(--digilean-primary-text);
    font-size: 11px;
}

.modal-danger {
    background: var(--digilean-danger);
    color: var(--digilean-primary-text);
    font-size: 14px;
}

#wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
}


.wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
}

.page-wrapper {
    overflow-y: hidden;
    height: 100%;
}

.on-drag-hover {
    background: var(--digilean-secondary-text);
    color: var(--digilean-primary-text);
}

.digilean-checkbox {
    font-size: 18px; 
    color: var(--digilean-secondary-text); 
    margin-left:4px;
}

.digilean-disabled{
    color: var(--digilean-disabled);
}

.digilean-ms-teams-background{
    background-color: var(--digilean-msteams-background);
}

.ibox-subtitle-digilean {
    font-size: 10px;
    font-weight: lighter;
}

.digilean-first-indent-level {
    margin-left: 16px;
}

.digilean-list-element {
    margin-top: 6px;
}

.digilean-table-selected {
    background-color: var(--digilean-selected);
}

.label-planned {
    background-color: var(--digilean-greyx); /*#434348;*/
    color:var(--digilean-white);
}

.digilean-label-dark {
    background-color: var(--digilean-dark-label);
    color: var(--digilean-white);
}

.digilean-label-historical {
    background-color: var(--digilean-tertiary-background);
    color: var(--digilean-tertiary-text);
}

.digilean-label-layout {
    padding-left: 6px;
}

.digilean-label-dark-primary {
    background-color: var(--digilean-dark-label-primary);
    color: var(--digilean-white);
}

.digilean-primary {
    color: var(--digilean-primary);
    background-color: transparent;
    border-color: var(--digilean-primary);
}

.digilean-btn-success {
    color: var(--digilean-button-success);
    background-color: transparent;
    border-color: var(--digilean-button-success-border);
}

.digilean-btn-warning {
    color: var(--digilean-button-warning);
    background-color: transparent;
    border-color: var(--digilean-button-warning-border);
}

.digilean-btn-danger {
    background-color: transparent;
    border-color: var(--digilean-danger);
    color: var(--digilean-danger);
}

.digilean-btn-undefined {
    color: var(--digilean-primary-text);
    background-color: transparent;
    border-color: var(--digilean-primary-text);
}
  
  
  /*This is in use - do not remove*/

button.digilean-btn-green.dim {
    box-shadow: inset 0 0 0 var(--digilean-green-olive), 0 5px 0 0 var(--digilean-green-olive), 0 10px 5px #999999;
}

button.digilean-btn-green.dim:active {
    box-shadow: inset 0 0 0 var(--digilean-green-olive), 0 2px 0 0 var(--digilean-green-olive), 0 5px 3px #999999;
}

.digilean-btn-green {
    background-color: var(--digilean-green-pea);
    border-color: var(--digilean-green-pea);
    color: var(--digilean-text-on-dark);
}

    .digilean-btn-green:hover,
    .digilean-btn-green:focus,
    .digilean-btn-green:active,
    .digilean-btn-green.active,
    .open .dropdown-toggle.digilean-btn-green,
    .digilean-btn-green:active:focus,
    .digilean-btn-green:active:hover,
    .digilean-btn-green.active:hover,
    .digilean-btn-green.active:focus {
        background-color: var(--digilean-green-pea);
        border-color: var(--digilean-green-pea);
        color: var(--digilean-text-on-dark);
    }

    .digilean-btn-green:active,
    .digilean-btn-green.active,
    .open .dropdown-toggle.digilean-btn-green {
        background-image: none;
    }

        .digilean-btn-green.disabled,
        .digilean-btn-green.disabled:hover,
        .digilean-btn-green.disabled:focus,
        .digilean-btn-green.disabled:active,
        .digilean-btn-green.disabled.active,
        .digilean-btn-green[disabled],
        .digilean-btn-green[disabled]:hover,
        .digilean-btn-green[disabled]:focus,
        .digilean-btn-green[disabled]:active,
        .digilean-btn-green.active[disabled],
        fieldset[disabled] .digilean-btn-green,
        fieldset[disabled] .digilean-btn-green:hover,
        fieldset[disabled] .digilean-btn-green:focus,
        fieldset[disabled] .digilean-btn-green:active,
        fieldset[disabled] .digilean-btn-green.active {
            background-color: var(--digilean-green-meadow);
            border-color: var(--digilean-green-meadow); 
        }

  .digilean-badge-completed {
    background-color: var(--digilean-button-success);
    color: var(--digilean-text-on-dark);
  }

.digilean-title-icon {
    float: left; 
    margin-right: 12px;
}


.logo-element {
    text-align: center;
    display: none;
    padding: 18px 0;
    background-color: var(--digilean-blue-deep);
}

.logo-image {
    text-align: center;
    margin: auto;
    width: 32px;
    height: 32px;
    background-image: url('https://digileanfiles.blob.core.windows.net/public-assets/images/DigiLean.png');
    background-size: cover;
    background-origin: border;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 800ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 800ms;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 800ms;
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
}

.logo-name-subheader {
    width: 60px;
    margin-left: 72px;
    text-decoration: none;
    color: var(--digilean-secondary-text);
}

.border-bottom-digilean {
    border-bottom: 1px solid var(--digilean-grey-fog);
    padding-bottom: 12px;
    padding-top: 12px;
    margin-bottom: 0px;
}

.digilean-border-top {
    border-top: 1px solid var(--digilean-grey-fog);
    padding-bottom: 6px;
    padding-top: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.digilean-row-space {
    padding-bottom: 6px;
    padding-top: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.digilean-element-m-l {
  margin-left:12px
}

.input-date-digilean {
    width: 70px;
}



#wrapper {
    background: var(--digilean-blue-deep);
}

#appcontainer {
    /* overflow-y: auto; */
    /*height: 100%;*/

}

.userisconnected {
    color: var(--digilean-primary);
}

    .userisconnected .connectedUser {
        font-family: verdana;
    }

.digilean-label {
    float: right;
}

.btn-right {
    float: right;
    margin-right: 34px;
    margin-top: 40px;
}

.label-default-digilean {
    background-color: var(--digilean-greyx);
    color: var(--digilean-text-on-dark);
}

.label-funeral-digilean {
    background-color: var(--digilean-funeral-background);
    color: var(--digilean-text-on-dark);
}

.label-info-digilean {
    background: var(--digilean-important);
}

.label-info-light-digilean {
    background: var(--digilean-important);
    color: var(--digilean-text-on-dark);
}

.label-regular-digilean {
    font-weight: normal;
}

.text-info-digilean {
    color: var(--digilean-important);
    background: var(--digilean-secondary-background);
}

.primary-foreground {
    color: var(--digilean-primary);
}

.green-bg {
    background-color: var(--digilean-button-success);
    color: var(--digilean-text-on-dark);
}



.darkgray-bg {
    background-color: var(--digilean-secondary-text);
    color: var(--digilean-primary-text);
}
.sortable-list  {
    min-height: 500px;
}

.comment {
    /*font-size: 10pt;*/
    color: var(--digilean-grey-default);
    margin: 6px;
    text-align: right;
}

.toolbar {
    padding: 6px;
}

.actionDeleteButton {
    float: left;
}


a.deleteIcon:hover {
    color: var(--digilean-red-sunset);
}

.col-modal-footer {
    padding: 20px 35px 20px 30px;
    text-align: right;
    border-top: 1px solid  var(--digilean-grey-rain);
}

    .col-modal-footer .btn + .btn {
        margin-bottom: 0;
        margin-left: 10px;
    }

    .col-modal-footer .btn-group .btn + .btn {
        margin-left: -1px;
    }

    .col-modal-footer .btn-block + .btn-block {
        margin-left: 0;
    }

/* Public DigiLean */
.tasktextarea {
    height: 100px !important;
}

/* color-picker */
.color-picker-button {
    height: 30px;
    width: 30px;
    margin-left: 12px;
    border: 1px solid var(--digilean-greydark-default);
}


/* Custom wizard style*/
ul.steps-list li {
    background: var(--digilean-important);
    color: var(--digilean-text-on-dark);
    font-size: 11px;
    min-height: 30px;
    width: 140px;
    margin: 6px;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
}

    ul.steps-list li.disabled {
        background: var(--digilean-white-wash);
        color: var(--digilean-grey-andesite);
    }

    ul.steps-list li.step-done {
        background: var(--digilean-wizard-unselected);
        color: var(--digilean-text-on-dark);
    }


.step-title {
    margin-top: 10px;
}

.step-content {
    background: var(--digilean-secondary-background);
    height: 300px;
    border: 10px solid var(--digilean-step-background);
    border-radius: 5px;
    /* overflow-y: auto; */
}


.step-header-item {
    width: 70px;
    text-align: center;
}

/* Boostrap */
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.dropdown-submenu {
    position: relative;
  }
  
.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: -93%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }
 
 .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }
/* angular bootstraplayout */
.nav-tabs > li.active {
    background: var(--digilean-secondary-background);
}

.tab-content {
    background: var(--digilean-secondary-background);
    border-left: 1px solid var(--digilean-grey-fog);
    border-right: 1px solid var(--digilean-grey-fog);
    border-bottom: 1px solid var(--digilean-grey-fog);
    border-radius: 2px;
    padding: 20px;
    position: relative;
}

.bold {
    font-weight: bold;
}

.popup-tab-background {
    background-color: var(--digilean-primary-background);
}

#language_button {
    padding-left: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 4px;
}

#language_buttons {
    padding-left: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 4px;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.uppercase {
    text-transform: uppercase;
}

.dropdown-heading {
    color: var(--digilean-grey-slate);
    background-color: var(--digilean-grey-haze);
    border-bottom: 1px solid var(--digilean-grey-sparrow);
    padding-left: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
}

.connectList {
    min-height: 400px;
}

/* Colors */
.text-warning-light {
    color: var(--digilean-warning-light);
}

.digilean-star-selected {
    color: var(--digilean-yellow-gold);
}
/* Three Dot animation */
@keyframes opacity {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes opacity {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.nofirstborder .table > tbody > tr:first-child > td {
    border-top:none;
}


.three-dot-loading-animation span {
    background: var(--digilean-yellow-gold-dark);
    min-width: 600px;
    min-height: 600px;
    border-radius: 6px;
    padding: 0px;
    margin-left: 6px;
    margin-top: 2px;
    height: 6px;
    width: 6px;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -moz-animation-name: opacity;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
}

    .three-dot-loading-animation span:nth-child(1) {
        animation-delay: 100ms;
        -moz-animation-delay: 100ms;
    }

    .three-dot-loading-animation span:nth-child(2) {
        animation-delay: 800ms;
        -moz-animation-delay: 800ms;
    }

    .three-dot-loading-animation span:nth-child(3) {
        animation-delay: 1500ms;
        -moz-animation-delay: 1500ms;
    }


.orgchart .ok .content {
    background-color: var(--digilean-green-pea);
    border-color: var(--digilean-green-pea);
}

.orgchart .warning .content {
    background-color: var(--digilean-yellow);
    border-color: var(--digilean-yellow);
}

.orgchart .critical .content {
    background-color: var(--digilean-red-carpet);
    border-color: var(--digilean-red-carpet);
}

.orgchart .node .title {
    background-color: var(--digilean-secondary-text);
    color: var(--digilean-primary-text);
}

.orgchart .verticalNodes > td::before {
    content: '';
    border: 1px solid var(--digilean-secondary-text);
}

.orgchart .verticalNodes ul > li::before,
.orgchart .verticalNodes ul > li::after {
    content: '';
    position: absolute;
    left: -6px;
    border-color: var(--digilean-secondary-text);
    border-style: solid;
    border-width: 0 0 2px 2px;
}

.orgchart tr.lines td.topLine {
    border-top: 2px solid var(--digilean-secondary-text);
}

.orgchart tr.lines td.rightLine {
    border-right: 1px solid var(--digilean-secondary-text);
    float: none;
    border-radius: 0px;
}

.orgchart tr.lines td.leftLine {
    border-left: 1px solid var(--digilean-secondary-text);
    float: none;
    border-radius: 0px;
}

.orgchart tr.lines .downLine {
    background-color: var(--digilean-secondary-text);
    margin: 0px auto;
    height: 20px;
    width: 2px;
    float: none;
}

.task-status-icon .fa-times-circle {
    color: var(--digilean-danger);
}

.task-status-icon .fa-check-square {
    color: var(--digilean-button-success);
}

.small-modal-window .modal-dialog {
    width: 400px;
}

#digilean-filter .selected-element {
    color: var(--digilean-white);
    background-color: var(--digilean-blue-whale);
    border-color: var(--digilean-blue-whale);
}

.digilean-list-item {
    border-bottom: 1px solid var(--digilean-grey-fog);
    padding: 5px 5px;
    padding-bottom: 10px;
    background: var(--digilean-secondary-background);
}

.userdropdown .default-person-s {
    margin:auto;
    color: var(--digilean-primary-text);;
    background: var(--digilean-primary);
    height: 24px; 
    width: 24px; 
    border-radius: 50%; 
    padding-top: 6px; 
    text-align: center;
    font-size: 9px;
}


.admin-label-title {
    font-size: 14px;
    color: var(--digilean-secondary-text);
    font-weight: 600;
    margin-top: 9px;
}

.component-new-button-s {
    height:30px;
    padding: 0 8px 0 8px;
    display: inline-table;
}


/*

.digilean-priority-list-blue {
    padding: 5px 5px;
    padding-bottom: 10px;
}

.digilean-priority-list-blue:nth-of-type(odd){
    background: #bce8f1;  
}

.digilean-priority-list-blue:nth-of-type(even){
    background: #d9edf7;  
}
.digilean-priority-list-green {
    
    padding: 5px 5px;
    padding-bottom: 10px;
    background: #dff0d8;
    border-bottom: 1px solid #A3A7A5;
}*/

.digilean-list-status {
    margin-bottom: 5px;
    margin-top: 5px;
}

.digilean-list-title {
    color: inherit;
    font-size: 13px;
    /*font-weight: 600;*/
    margin-bottom: 2px;
}

.digilean-list-info,
.digilean-list-info a {
    color: var(--digilean-grey-cement);
    font-size: 11px;
}


.digilean-list-info a:hover {
    color: var(--digilean-primary);
}

body.body-small .digilean-list-icon {
    display: none;
}


.digilean-left-space {
    margin-left: 6px;
}
/* override of style */
.vote-item {
    padding: 5px 5px;
}
.modal .tabheader {
    white-space: nowrap;
}
.modal .tab-item-count {
    margin-left: 6px;
}
/*bootstrap override*/
@media only screen and (min-width: 900px) {
    .modal-lg {
        width:1000px;
    }
}

@media (min-width: 992px) {
    .digilean-modal-lg {
        width: 900px;
    }
}

.colorpicker {
    z-index: 1100;
}

.todo-list {
    word-wrap: break-word;
}


.lightbox-image-container img{
    max-width: 100%;
}

.loading-indicator{
    position: absolute;
    top:0;
    background: var(--digilean-transparent-background);
    color: var(--digilean-primary-text);
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-family:serif;
    font-size:120px;
    visibility: hidden;
}

.loading-text{
    flex:1;
}

@keyframes blink {
    
    0% {
      transform:scale(0,0);
      opacity: .3;
    }
    
    20% {
        transform:scale(1,1);
      opacity: 2;
        
    }
    
    100% {
        transform:scale(0,0);
        opacity: .3;
    }
}

.saving {
    display:flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;

}

.saving div {
    /**
     * Use the blink animation, which is defined above
     */
    animation-name: blink;
    /**
     * The animation should take 1.4 seconds
     */
    animation-duration: 1.4s;
    /**
     * It will repeat itself forever
     */
    animation-iteration-count: infinite;
    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    animation-fill-mode: both;
 
}

.saving div:nth-child(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .2s;
}

.saving div:nth-child(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .4s;
}

#side-menu {
    background: var(--digilean-main-menu-background);
}

#side-menu .fa-edit {
    color: var(--digilean-greyz)
}


#side-menu .fa-edit:hover {
    color: var(--digilean-primary-text);
}

.dot{
    width:20px;
    height:20px;
    max-width:20px;
    max-height:20px;
    border-radius: 10px;
    background: var(--digilean-secondary-background);
    flex:1;
    margin:5px;
}

.busy{
    visibility:visible
}

#mailsender .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
}

#versions .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
}

hr.style2 {
    border-top: 3px double var(--digilean-greyy);
}

:focus { outline: none; }

body.mini-navbar .nav-third-level{
    left: 12px;
    display: block;
}


body.mini-navbar .nav-third-level li a span,
body.mini-navbar .nav-second-level li a .nav-folder-label {
    display: block;
    color: var(--digilean-primary-text);
}

body.mini-navbar li ul .nav-second-level,
body.mini-navbar .nav-second-level{
    display: block;
}

body.mini-navbar .nav-second-level li a span.nav-root-element{
    display: block;
    margin-left:-12px;   
}

body.mini-navbar .nav-second-level li a span.nav-folder-label{
    display: block;
    color: var(--digilean-primary-text);
}

body.mini-navbar .nav-second-level li a span.nav-folder-label span{
    display: block;   
}


body.mini-navbar .nav-second-level li .hide-mini-navbar,
body.mini-navbar .nav-second-level li a.hide-mini-navbar,
body.mini-navbar .nav-second-level li a .hide-mini-navbar,
body.mini-navbar .nav-second-level li a .fa-plus 
{
    display: none;   
}

.simpel-tag-list{
    font-size: 10px;
    background-color: var(--digilean-primary-background);
    padding: 2px 4px;
    color: inherit;
    border-radius: 2px;
    border: 1px solid var(--digilean-grey-fog);
    margin-right: 3px;
    margin-top: 3px;
    display: block;
}
/*
body.mini-navbar .nav-third-level li a .fa,
body.mini-navbar .nav-third-level li a .fab,
body.mini-navbar .nav-third-level li a .fal,
body.mini-navbar .nav-third-level li a .far,
body.mini-navbar .nav-third-level li a .fas
body.mini-navbar .nav-third-level .fas
{
    display: inline-block;   
}


body.mini-navbar .nav-second-level li a .fa-user
{
    display: inline-block;   
}*/

.push-user-list{
    margin:20px;
}
.push-user-list-item{
    margin:10px;
    float: left;   
}

.push-area{
    width:100%;
    height: 100%;
    resize: none;
    outline: none;
    border: none;
}
.vertical-scroll-only {
    overflow-y: auto;
    overflow-x: hidden;
}

.option-selector {
    font-size: 13px; 
    margin-left: 10px; 
    margin-right: 10px;
}

/*Sweet alert*/
.swal-footer{
    text-align: center;
}
.sa-digilean-button{
    text-align: center;
    background-color: var(--digilean-primary);
    color: var(--digilean-primary-text);
}

.sa-digilean-button-danger {
    text-align: center;
    background-color: var(--digilean-danger);
    color: var(--digilean-main-menu-text);
}

.digilean-favorite-button {
    font-size: 15px;
    margin-left: 10px;
    color: var(--digilean-grey-silver);
}

.digilean-favorite-active {
    color: var(--digilean-yellow-gold-default);  
}

.tip-description {
    font-size: 14px;
}



@media (min-width: 577px) and (max-width: 991px) {
    .modal-body .tabheaderdescription {
        display: none;
    }
  }

  .modal-center {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -1px);
  }
  
  /* default - should be moved to layout */
.dropdown-menu li.vertical-center {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    padding: 5px;
    align-items: center;
    cursor: pointer;
}
.dropdown-menu li .option-icon {
    height: 30px;
    width: 30px;
}
.dropdown-menu li .option-label {
    margin-left: 10px;
}
.dropdown-menu li.hover:hover {
    background: var(--digilean-tertiary-background);
}