
#A3PreviewDesigner .widget {
    height: calc(100% - 2px);
}

#A3PreviewDesigner .ibox-content {
    border: none;
    padding:0;
}

    #A3PreviewDesigner .preview-header {
        padding:6px; 
        margin-top:0px;
        padding-left:7px;
    
    }

    
    #A3PreviewDesigner .preview-control-header {
        display:flex; 
        flex-direction: row; 
        align-content: flex-end;
        justify-content: space-between;
    }

    #A3PreviewDesigner .widget .widget-component {
        height: inherit;
        height: 100%;
        overflow-y: scroll;
    }

    #A3PreviewDesigner .widget .widget-header {
        color: gray;
        background-color: #f0f0f0;
        border-color: silver;
    }

    #A3PreviewDesigner label {
        margin-top: 5px;
    }

    #A3PreviewDesigner .widget .widget-content {
        /*height: inherit;*/
        height: 100%;
        overflow-y: hidden;
        border: 2px solid #f0f0f0;   
    }

    #A3PreviewDesigner .widget .add-app-button {
        display: none;
    }

    #A3PreviewDesigner .participants {
        margin-bottom: 10px;
    }

    #A3PreviewDesigner .participants li {
        display: inline;list-style-type: none;padding-right: 6px; padding-bottom: 5px;
    }

    #A3PreviewDesigner .dropdown-menu{
        min-width: 220px;
    }
    

/*
#A3PreviewDesigner .table > tbody > tr:first-child > td {
    border: none;
}*/


/* angular-gridster demo css, remove if not used */

.box {
    height: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
}
.box-header {
    background-color: #eee;
    padding: 0 30px 0 10px;
    border-bottom: 1px solid #ccc;
    cursor: move;
    position: relative;
}
.box-header h3 {
    margin-top: 10px;
    display: inline-block;
}
.box-header-btns {
    top: 15px;
    right: 10px;
    cursor: pointer;
    position: absolute;
}
#A3PreviewDesigner a {
    color: #ccc;
    color: #676C6A;
}

#A3document a {
    color: #676C6A;
}

#A3PreviewDesigner .ibox .float-e-margins{
 /* background: green; */
}

#A3PreviewDesigner {
    /* background: red; */
}


#A3document{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex:1 ;
    vertical-align: top;
    transform-origin: top left;

}

form {
    margin-bottom: 0;
}

#A3PreviewDesigner .widget-component {
    border: 1px solid #ccc;
    /* box-shadow: 0 0 5px 1px rgba(0,0,0,.10); */
    max-height: 100%;
    overflow-y: auto;
}

.zoom-slider{
    max-width: 200px;
    /* float: right; */
    margin-right:20px;
    flex:1;
    display: flex;
    flex-direction: row;
    padding: 10px;
}

#A3PreviewDesigner .step-checkbox{
    margin-left: 8px;
}

.step-selector{
    width:50px;
    margin-right:50px;
}

#A3PreviewDesigner .a3background{
    overflow: auto;
    display: flex;
    align-content: center;
    /* justify-content: center; */
    border: 2px solid lightgray;
}

.preview-header-buttons{
    display:flex;
    flex-direction: row;
    max-width: 615px;
    width: 615px;
    float: right;
    margin-right: 10px;
}

#a3templateLayoutHeader .preview-header-buttons{
    width: 420px;
}

#A3PreviewDesignerHeader .print{
    margin-left:8px;
}

#A3PreviewDesigner .slider {
    margin-left: 10px;
    margin-right:10px; 
    margin-top:-5px;

}

_:-ms-fullscreen, :root #A3PreviewDesigner .slider{ 
    margin-top: -18px; 
}

#A3PreviewDesigner .fr-box.fr-basic .fr-element{
    /* height: 300px; */
    font-size: 14px;
}

#A3document .icon-button-position {
    z-index:6;
    position:absolute;
    margin-top:-6px;
    margin-right:-6px;
}

