.project-board {
 margin-top: 10px;
 padding: 10px;   
}
.project-board-container {
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
}

.project-board-table th{
    /* width: 100px; */
    height: 40px;
    background-color: var(--digilean-secondary-background);
    padding: 6px;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    position: -webkit-sticky;
    cursor: pointer;
    /* for Safari */
    position: sticky;
    top: 0;
    z-index: 999;
}
.project-board-table th.is-sorted {
    background: var(--digilean-cell-contrast); /*#f1f1f1;*/
    border-bottom: 2px solid #0099DE;
}
.project-board-table thead th:first-child {
    left: 0;
    z-index: 998;
  }
.project-board-table tbody td:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 997;
  }
.project-board-table {
    width:100%;
}

.smartdropdown .dropdown-submenu li {
    min-width:210px;
    max-width:210px;
}

.project-cell-aggregate {
    background: var(--digilean-cell-contrast);
    min-height:35px;
    padding-top:6px;
    padding-bottom:6px;
}

.project-board-title{
-moz-border-bottom-colors: none;
-moz-border-left-colors: none;
-moz-border-right-colors: none;
-moz-border-top-colors: none;
background-color:var(--digilean-secondary-background);
color: inherit;
margin-bottom: 0;
margin-left:6px;
min-height: 32px;
}
.drag-moved{
    /* border: 1px solid silver; */
}
.project-board-table .gu-transit{
    border: 1px solid #0099DE;
}
input.project-board-title{
    border: 0px;
    border-bottom: 1px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
}
input:focus.project-board-title{
    border: 1px dotted #0099DE;
}
.project-board-table input.column-name{
    border: 0px;
    border-bottom: 1px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
}
.project-board-table .drag-drop-icon{
    position: absolute;
    top: 10;
    left: 0;
    z-index: 2;
    cursor: move;
}
.project-board-table .option-icon{
    position: absolute;
    top: 10;
    right: 0;
    z-index: 2;
}
.project-board-table td .project-cell {
    height: 50px;
}
.project-board-table input:focus.column-name{
    border: 1px dotted #0099DE;
}

.project-board-table .milestone-completed {
    color: #fff;
    background-color: #0099DE;
    border-color: #a2c6e5;
}

.project-board-table .milestone-future {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc;
}

.project-board-table .milestone-missed {
    color:#d9534f
}

.project-board-table .date-before, .date-before:hover {
    color:#fcbb42
}



.project-board-table .project-customer{
    margin-top: 10px;
    color:#676a6c
}

.project-board-table .project-name{
    border: 0px;
    margin-top: 10px;
    font-weight: 600;
}
.project-board-table input.project-name{
    width: calc(100% - 20px);
}
.project-board-table input:focus.project-name {
    border: 1px dotted #0099DE;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
}

.project-board-table .project-number{
    border: 0px;
    margin-top: 10px;
    background: transparent;
    text-align: center;
    width: 100%;
}
.project-board-table input:focus.project-number{
    border: 1px dotted #0099DE;
    background: white;
}
/* .form-line-active input:focus, .form-line-active textarea:focus {
    border: 1px solid green !important;
    box-shadow: 0 0 3px green !important;
    -moz-box-shadow: 0 0 3px green !important;
    -webkit-box-shadow: 0 0 3px green !important;
    } */

.project-board-table td {
    background: var(--digilean-cell-contrast);
    font-size: 1.2rem;
    /* padding: 6px;
    padding-left: 12px;
    padding-right: 12px; */
    min-height: 40px;
    height: 40px;
    border-bottom: 2px solid var(--digilean-secondary-background);/*white;*/
    border-right: 2px solid var(--digilean-secondary-background);/*white;*/
}
.project-board-table td .project-cell{
    padding-top: 6px;
    padding-left: 2px;
    padding-right: 2px;
    height: 100%;
    }
.project-board-table td .project-cell-project-name {
    font-weight: 600;
    background: var(--digilean-cell-title);
    border-bottom: 2px solid var(--digilean-cell-contrast); /*#f1f1f1;*/
}
.project-board-table td .progress {
    border: 1px solid #0099DE;
    cursor: pointer;
}

.project-board-table .dropdown-desciption-text {
    font-size: 12px;
    text-align: right;
}

.project-board-table td .date {
    font-weight: bold;
    margin-top: 10px;
    height:100%; 
    width:100%;
    text-align: center;
}

.project-board-table .default-person {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin:auto; */
    color:white;
    background: #0099DE;
    height: 2.8em; 
    width: 2.8em;  
    border-radius: 50%;
    /* padding-top: 10px; */
    text-align: center;
    font-weight: bold;
}
.activeuser .default-person {
    margin:auto;
    color:white;
    background: #0099DE;
    height: 37px; 
    width: 37px; 
    border-radius: 50%; 
    padding-top: 10px; 
    text-align: center;
    font-weight: bold;
}

#projectSelectorTable .table-big-text {
    font-size: 14px;
    color: #676a6c;
}
#projectSelectorTable .table-big-text-bold {
    font-size: 14px;
    color: #676a6c;
    font-weight: 600;
}

.project-icon {
    font-size:33px;
}

.project-dropdown-menu {
    min-width:230px;
    max-height: 360px; 
    overflow-y: auto;
 }    

 .search-form .project-search-input {
    width:210px;
    height:26px; 
    margin-left: 5px; 
    margin-right: 5px;
 }

 .project-clear {
    height: 38px; 
 }

 .project-clear .project-clear-icon{
    float: left;
    margin-left:12px;
    margin-top:10px;
    margin-bottom:4px;
 }

 .project-clear .project-clear-text{
    display: inline-block;
    margin-left:6px;
    margin-top:12px;
    font-size:14px;
 }

 .project-dropdown-li {
    padding-left:12px; 
    padding-top:8px;
    height: 45px;
 }
 .project-dropdown-item {
    height: 45px; 
 }

 .project-dropdown-item .dropdown-text {
    display: inline-block;
    margin-left:0px;
    margin-top:6px;
    font-size:14px; 
 }