.goal-create .color-container {
    /* display: inline-block; */
    margin: 5px;
    /* font-size: 28px; */
    padding: 5px;
    height: 40px;
    width: 40px;
    float: left;
}
.goal-create .color-container:hover {
    background: #f0f0f0;
}
.goal-create .color-container .selected-color {
    /* font-size: 36px; */
}

.goal-create li.digilean-list-element {
    padding: 3px;
}
.goal-create li.digilean-list-element:hover {
    background: #f0f0f0;
}
.goal-create .color-circle {
    width: 30px;
    height: 30px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}
.goal-create .color-circle.selected {
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 3px;
    font-size: 24px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
}