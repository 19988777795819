.boards-header {
    padding-left: 25px;
    padding-right: 25px;
}

.boards-header h2 {
    margin: 0;
}

.boards-header .boards-header-icon {
    margin-right: 12px;
}

@media (min-width: 577px) {
    .boards {
        border: 1px solid #e7eaec;
        border-top: 3px solid #e7eaec;
        background: var(--digilean-secondary-background);
        margin: 15px;
        padding: 15px;
        padding-left: 25px;
    }
}

@media (max-width: 576px) {
    .boards {
        border: 1px solid #e7eaec;
        border-top: 3px solid #e7eaec;
        background: var(--digilean-secondary-background);
    }
}

.boards .folder-title {
    border-bottom: 1px solid #f0f0f0;
}

.boards .folder-title.all {
    height: 70px;
    border-top: 3px solid #f0f0f0;
    padding-top: 10px;
    background: var(--digilean-secondary-background);
}

.boards .folder-content.closed {
    visibility: collapse;
    height: 0;
    overflow: hidden;
}

.boards .folder-content.open {
    visibility: visible;
    height: unset;
}

.boards .folder h4 {
    text-transform: uppercase;
    color: var(--digilean-secondary-text);
    font-size: 13px;
    letter-spacing: 1px;
}

.boards .folder .folder-icon {
    font-size: 14px;
    margin-right: 6px;
}

.boards .folder .drop-container {
    min-height: 80px;
}

.boards .folder .drop-over {
    background: lightblue;
    min-height: 300px;
}

.boards .tag-list li a.selected {
    color: white;
    background-color: #0099DE;
    border-color: #0099DE;

}

#page-wrapper::before {
    content: "";
    background-size: cover;
    /* position: absolute; !!! IMPORTANT !!! position must not be set here. Will fuck up stuff" */
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

#flexiboard .dashboard-header-element {
    margin-bottom: 5px;
    padding-left: 15px;
}

#flexiboard .dashboard-update-box {
    padding: 5px;
    width: 220px;
    background: white;
    border-radius: 5px;
    border: 1px solid var(--digilean-primary);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#flexiboard .dashboard-update-box .notification-icon {
    color: var(--digilean-primary);
}

#flexiboard .board-content {
    margin: 0 auto;
    margin-left: 15px;
    margin-right: 15px;
}

#flexiboard .flexiboard-title {
    font-weight: 500;
}

#flexiboard .option-button {
    background: white;
    color: gray;
}

.flexiboard-theme-selection button.selected {
    background: #337ab7;
    color: #fff;
}

#flexiboard .flexiboard-title.custom {
    color: white;
}

#flexiboard .title-icon {
    margin-right: 12px;
}

#flexiboard .presentation-mode-header {
    padding: 10px;

}

#flexiboard .presentation-mode-header.custom h1 {
    color: white;
}

#flexiboard .presentation-mode-header.custom .presentation-mode-subtitle {
    color: #c0c0c0;
}

#flexiboard #connectedUserList {
    margin: 0;
    max-width: max(0px, calc((100% - 120px)*999));
    overflow: hidden;
    max-height: 70px;
}

#flexiboard #boardContainer #board-toolbar-table {
    border-spacing: 0;
}

.flexiboard-menu .icon {
    margin-right: 6px;
    margin-left: -6px;
    color: green;
}

.widget-fullscreen-button {
    z-index: 1001;
    position: absolute;
    margin: 0.8rem;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--digilean-greydark-default);
}

/*  Make sure Froala inline editor doesn't appear behind menu */
#flexiboard .fr-inline {
    z-index: 1050;
}

#flexiboard .ribbon-container {
    background: #f3f6fb;
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#flexiboard .ribbon {
    min-height: 40px;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}


#flexiboard .ribbon-content {
    height: 80%;
}

#flexiboard .ribbon-title {
    height: 20%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    background: white;
    border-radius: 5px;
}

#flexiboard .app-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#flexiboard .app-list li {
    display: inline;
}

#flexiboard .app-image {
    height: 85px;
    width: 80px;
    border: 1px solid darkgray;
    border-radius: 10px;
    margin: 5px;
}

#flexiboard .option-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 3px;
    margin-right: 6px;
    height: 12px;
    width: 12px;
}

#flexiboard .tabs-container.default .option-icon ul {
    background: var(--digilean-secondary-background);
}

#flexiboard .tabs-container.default .option-icon ul li {
    background: var(--digilean-secondary-background);
}

#flexiboard .tabs-container.default .option-icon ul li:hover {
    background: var(--digilean-primary-background);
}

#flexiboard .delete-page-icon-position {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 2px;
    padding-right: 2px;
    margin-right: -1px;
}

#flexiboard .add-page-button {
    margin: 12px;
}

.flexiboard-background-preview-image {
    float: left;
    margin: 10px;
}

.flexiboard-background-preview-image img {
    border: 2px solid white;
    height: 80px;
    width: 120px;
}

.flexiboard-background-preview-image img.selected {
    border: 4px solid #337ab7;
}

#flexiboard .tabs-container {
    border-bottom: none;
}

#flexiboard .tabs-container .nav-tabs {
    border-bottom: none;
}

#flexiboard .nav-tabs>li {
    font-size: 10pt;
}

#flexiboard .tabs-container.default ul {
    background: transparent;
}

#flexiboard .tabs-container.custom ul {
    background: rgba(0, 0, 0, 0.15);
}

#flexiboard .tabs-container li {
    background: rgba(0, 0, 0, 0.01);
    border: none;
    border-right: 1px solid #e7eaec;
    border-radius: 0;
}

#flexiboard .tabs-container li.custom {
    background: rgba(0, 0, 0, 0.05);
    border: none;
    border-right: 1px solid #c0c0c0;
    border-radius: 0;
}

#flexiboard .tabs-container.custom .nav>li>a {
    color: #e0e0e0;
}

#flexiboard .tabs-container.custom .nav>li>a:hover {
    color: #f0f0f0;
}

#flexiboard .tabs-container.custom .nav>li.active>a {
    color: #f0f0f0;
}

#flexiboard .nav>li>a {
    color: #878787;
    border-bottom: 3px solid transparent;
}

#flexiboard .nav>li>a:hover {
    color: #555555;
}

#flexiboard .nav>li.active>a {
    border: none;
    color: #555555;
    border-left: 1px solid #e7eaec;
    background: transparent;
    border-bottom: 3px solid #0099DE;
}

#flexiboard .border-bottom {
    padding-bottom: 0px;
}

#flexiboard .ibox-header-digilean {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border: none;
}

#flexiboard .ibox-header-digilean .ibox-title {
    background-color: transparent;
    border: none;
    border-bottom: none !important;
    padding-bottom: 0px;
}

.widget-drag-handle {
    right:0;
}
.widget-menu-button {
    right: 28px;
}
.circle {
    width: 20px;
    height: 20px;
    background: white;

    text-align: center;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

#flexiboard .digilean-widget {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.digilean-widget {
    background: var(--digilean-primary-background);
    height: 100%;
}

.digilean-widget .widget-component {
    height: inherit;
    padding: 0px;
    margin-bottom: 0px;
    overflow: hidden;
}

.digilean-widget .widget-component-container {
    height: 100%;
}

.digilean-widget .widget-component-container-a3viewer {
    height: 100%;
    overflow-y: auto;
}


.digilean-widget .widget-component .ibox:first-child {
    height: 100%;
}

.digilean-widget .ibox,
.digilean-widget .ibox-title {
    margin-bottom: 0px;
    border-style: none;
}

.digilean-widget .widget-content {
    height: 100%;
    overflow: visible;
}

.widget-header {
    color: white;
    background-color: #0099DE;
    background-color: #34B3E4;
    border-color: #0077B5;
    cursor: move;
}

.widget-header .add-app-button:hover {
    color: #0099DE;
    background: white;
}

.richtext-styling ul li {
    list-style-type: disc;
}

.richtext-styling ol li {
    list-style-type: decimal;
}

.global-time-period-selector {
    width: 100px;
}

#flexiboard .nav-button {
    margin-left: 10px;
}

#flexiboard .preview-app {
    max-height: 150px;
    overflow-y: hidden
}

.preview-app-image {
    max-width: 170px;
    opacity: 0.3;
    margin: auto;
    display: flex;
}

.preview-app-name-panel {
    position: absolute;
    top: 100px;
    left: 0;
    max-height: 170px;
    width: 100%;
    background: red;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-app-name {
    position: absolute;
    text-transform: uppercase;
    opacity: 0.6;
}

/* GRID LAYOUT */
.ibox-app {
    display: grid;
    grid-template-rows: auto 1fr;
}

.btn.scalable-btn {
    font-size: 1em;
}

widget .ng-table-counts button.btn.btn-default {
    font-size: 1em;
}

resolution-displayer.current {
    color: var(--digilean-primary);
    font-weight: 600;
}

.flexiboard-menu-h4 {
    margin-block-start: 1rem;
    margin-block-end: 0;
    margin-bottom: 0.2rem;
}
.flexiboard-menu-description {
    color:grey;
    font-style:italic;
    display: block;
    margin-block-start: 0;
    margin-block-end: 1rem;
    font-size: 1.2rem;
}

.flexiboard-help-text {
    color:grey;
    font-style:italic;
}