deviation-action-list {
    .title-field {
        min-width: 150px;min-height:15px;
        display: flex;
        input {
            max-width: 62rem;
            flex-basis: 150px;
            flex-grow: 1;
            flex-shrink: 0;
        }
    }
}
