improvement-column-result {
    flex: 1 1 auto;
}

improvement-column {
    flex: 1;
}

improvement-column .sortable-list {
    min-height: 500px;
}

improvement-column ul.ongoing-draggable,
improvement-column div.improvement-column-wrapper {
    height: 100%;
}

improvement-column ul.ongoing-draggable {
    background-color: var(--digilean-white-bright);
    padding: 5px;
}

improvement-column ul.ongoing-draggable.hover {
    background-color: var(--digilean-primary-background);
}

improvement-column-result .filter-indicator {
    color: var(--digilean-important);
    margin-left: 12px;
}

div.improvement-ongoing-list {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

div.improvement-ongoing-list .result-box-outer {
    box-sizing: border-box;
    height: 100%;
}

div.improvement-ongoing-list .result-box-outer.is-app-context {
    box-sizing: border-box;
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
}

.float-e-margins .ongoing-improvement-container {
    font-size: 1.1rem;
}

div.result-box-outer .ibox-content {
    height: calc(100vmin - 260px);
    max-height: calc(100vmin - 260px);
    overflow-y: auto;
}
@media (max-width: 767px) {
    div.result-box-outer .ibox-content {
        height: 100vmin;
        max-height: 100vmin;
    }
}
div.result-box-outer.is-app-context .ibox-content {
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
}

div.result-box-outer .ibox-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
div.result-box-outer .ibox-title .lefthand-side {
    flex: 1 1 auto;
}
div.result-box-outer .ibox-title .righthand-side {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}