app-header-title {
    display: block;
    min-width: 0;
    width: 100%;
    padding-bottom: 0.5em;
    text-align: left;
}

app-header-title div.app-title {
    display: inline-flex;
    flex-direction: row;
    max-width: 100%;
}

app-header-title h3.read-title {
    display: inline-block;
    font-size: 1em;
    min-height: 1em;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

app-header-title div.app-title .edit-field {
    flex-basis: 20rem;
    flex-grow: 0;
    flex-shrink: 1;
}

app-header-title .app-title .edit-field input {
    padding-bottom: 0;
    font-weight: 600;
    width: 100%;
}