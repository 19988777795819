[node-id] rect {
    /* fill: #016e25; */
    /* fill-opacity: 80%; */
}
.swal-button--cancel {
    margin-right: 20px;
}

.field_0 {
    font-family: Impact;
    text-transform: uppercase;
    fill: #a3a3a3;
}

/* #strategy-org-chart{
    width: 100%;
    height: 100%;
    position: relative;
} */
.strategy-viewer  .strategymapchart {
    height: calc(100vmin - 100px);
    min-height: calc(100vmin - 100px);
}


.strategy-viewer hr {
    margin-top: 5px;
    margin-bottom: 5px;
}