date-picker {
    display: block;
    width: 100%;
    max-width: 100%;
}
.digilean-datepicker {
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
}
.digilean-datepicker .datepicker-input {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.4rem;
}
.digilean-datepicker .datepicker-button {
    font-size: 1.4rem;
}
.uib-datepicker-popup.dropdown-menu {
    z-index: 1000000;
}

date-picker-slot {
    display: inline-block;
}
div[uib-datepicker-popup-wrap] {
    color: var(--digilean-grey);
}
.digilean-datepicker-slot {
    display: inline-block;
}

date-picker.scalable .digilean-datepicker .datepicker-input{
    font-size: 1em;
    height: 2.4em;
}
date-picker.scalable .digilean-datepicker .datepicker-button {
    font-size: 1em;
    padding: 0.4em 0.8em;
}
datetime-viewer {
    display: inline;
}