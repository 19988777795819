.jobadmin.btn {
    color: var(--digilean-primary-text);
    outline-color: var(--digilean-blue-sky);
    background: var(--digilean-blue-sky);
    box-sizing: border-box;
    border-radius:5px;
    border-width: 1px;
    padding: 0.5rem 1rem;
    transition: background 1s linear;
}
.jobadmin.btn.collapsed {
    color: var(--digilean-secondary-text);
    outline-color: var(--digilean-secondary-text);
    border-color: var(--digilean-grey-light);
    background: var(--digilean-grey-lightest);
}