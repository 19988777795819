help-search {
    display: inline-block;
    header {
        color: var(--digilean-primary-text);
        h3 {
            color: var(--digilean-primary-text);
        }
    }
    search {
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        digilean-input {
            flex: 1 1 auto;
        }
    }
    section#search-result2 {
        margin-top: 3rem;
        min-height: 5rem;
        font-size: medium;
        ol {
            list-style-type: decimal;  /* This is the default for <ol>, but ensure it's set */
            padding-left: 40px;        /* Adds indentation for the list */
        }

        li{
            display: list-item;
        }

    }
}

.help-search-icon-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 18px;
    color: var(--digilean-primary-link);
}

.help-search-icon-button:hover {
    color: var(--digilean-primary-link-focus);
}


#help-search-popover #back-button{
    background: none;
    border: none;
    color: #0099DE;
    padding: 0;
    font-size: 1.3rem;
    text-decoration: none;
}

#article-link {
    text-decoration: none; /* By default, no underline */
    cursor: pointer; /* Makes the text look clickable */
}

#article-link:hover {
    text-decoration: underline;
}

#help-search-popover #back-button:hover{
    text-decoration: underline;
}

#help-search-button {
	anchor-name: --help-search-anchor;
}

#input-container {
    position: relative;
    width: 100%;
}

#clear-button 
{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    z-index: 1;
  }

#search-input {
    width: 100%;
}

#help-search-popover[popover] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--digilean-secondary-background);
    border: 1px solid var(--digilean-input-border);
    width: 60rem;
    padding: 2rem;
    margin: 0;
    position:absolute;
    position-anchor: --help-search-anchor;
    inset: unset;
    top: anchor(bottom);
    right: 0;
    /*left: anchor(left);*/
    /*justify-self: anchor-center;*/

    box-shadow: var(--digilean-box-shadow);
    display: none;
    opacity: 0;
    transition:
        opacity 500ms,
        display 500ms allow-discrete,
        overlay 500ms allow-discrete;

    &:popover-open {
        display: block;
        opacity: 1;

        @starting-style {
            opacity: 0;
        }
    }
#search-spinner-container{
    display: flex;
    align-items: center;     /* Vertically centers the spinner */
    justify-content: center; /* Horizontally centers the spinner */
    width: 100%;             /* Full width of the parent element */
}
    
#search-spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

}