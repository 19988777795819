.commentscontainer {
    /* background: #f3f3f4; */
    margin: -15px;
}
.commentscontainer .social-body {
    padding-top: 5px;
}
.commentscontainer .social-footer {
    /* border: 0; */
    padding: 0;
}
.comment-editor {
    padding: 15px;
    /* width: 80%;
    min-width: 300px; */
}
.comment-editor img {
        margin-bottom: 10px;
}
.comment-editor .fr-wrapper {
    border: 2px solid #e7eaec;
    min-height: 60px;
}

.comment-editor .fr-element {
    padding: 10px;
}

.comment-container-box {
    /* border-bottom: 1px solid #e7eaec; */
    background: #fff;
    /* margin-bottom: 5px; */
}
.comment-reply-container {
    padding: 0;
    border-left: 3px solid #e7eaec;
}
.comment-container-box .comment-display {
    background: #f9f9f9;
    border: 1px solid #e7eaec;
    padding: 10px;
    border-radius: 0px;
}
.tribute-container .mention-profile-image {
    height: 32px;
    width: 32px;
    margin: 3px;
    float: none;
    border: 1px solid #c0c0c0;
}
.tribute-container .profile-name {
    margin-left: 12px;
}
.tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    display: block;
    z-index: 999999;
    border: 2px solid#f0f0f0;
  }
  .tribute-container li {
    background: #f0f0f0;
    background: white;
    color: black;
    text-align: left;
}

.dropdown-menu.dropdown-menu-right {
    z-index: 999999;
}

.mention-profile-image-initials {
    margin: 3px;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 32px;
    vertical-align: middle;
    border-radius: 50%;
    background: #0099DE; /* skyblue; */
    color: white;
    text-align: center;
    font-family: "Roboto Mono", monospace;
    cursor: pointer;
    /* border: 1px solid white; */
}