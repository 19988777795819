.message-replies .reply-box {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 11px;
    line-height: 14px;
    max-width: 80%;
    background: #f3f3f4;
    margin-bottom: 10px;
}
.modal-body, .message-replies-container, .message-replies {
    box-sizing: border-box;
} 
.modal-body .message-replies-container {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.header-message {
    display: flex;
    justify-content: space-between;
}
.header-message .message-badge {
    display: flex;
    width:70px;
    max-width:70px;
    flex-direction: column;
    justify-content: flex-start;
}
.header-message .message-badge span.text-center {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    border-radius:5px;
    color:white;
    height:23px;
}
.header.message .message-badge span.badge-text {
    color:white;
    margin:0;
    padding:0;
    font-weight: normal
}

div.modal-header.message-reply-header {
    padding: 15px 30px;
}
.full-image-container .image-file-preview {
    height: 100%;
}
.full-image-container img {
    display: block;
    height: 0;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
  
 }