#dashboard .dashboard-header-element {
    margin-bottom: 10px;
    padding-left: 15px; 
    border-top: 5px solid #e7eaec;
}

#dashboard .presentation-mode-header {
    padding: 10px;
}

/*  Make sure Froala inline editor doesn't appear behind menu */
#dashboard .fr-inline {
    z-index: 1050;
}
#dashboard .ribbon-container {
    background: #f3f6fb;
    border: 1px solid #e7eaec;
    border-radius: 3px;
}

#dashboard .ribbon {
    min-height: 40px;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}


#dashboard .ribbon-content {
    height: 80%;
}
#dashboard .ribbon-title {
    height: 20%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    background: white;
    border-radius: 5px;   
}
#dashboard .app-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
#dashboard .app-list li {
    display: inline;
}

#dashboard .app-image {
    height: 85px;
    width:  80px;
    border: 1px solid darkgray;
    border-radius: 10px;
    margin: 5px;
}
#dashboard-page .page-content {
    padding: 0;
    background: transparent;
}

#dashboard .delete-page-icon {
    color: red;
    position: absolute;
    top: 0;
    right: 0;
    margin-top:3px;
    margin-right:6px;
    height: 12px;
    width: 12px;
}

#dashboard .delete-page-icon-position {
    padding-top:0px;
    padding-bottom:0px;
    padding-left:2px;
    padding-right:2px;
    margin-right:-1px;
}

#dashboard .add-page-button {
    margin: 12px;
}


#dashboard .nav-tabs > li {
    font-size: 10pt;
}

#dashboard .nav > li.active > a {
    background: #f3f6fb;
    border-top: 2px solid #0099DE;
    margin-bottom: -1px;  
    /* font-size: 12pt; */
} 

#dashboard .border-bottom {
    padding-bottom: 0px;
}

#dashboard .ibox-header-digilean {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border: none;

}

#dashboard .ibox-header-digilean .ibox-title {
    background-color: transparent;
    border: none;
    border-bottom:  none !important;
    padding-bottom: 0px;
}


#dashboard-page .icon-button {
    padding-top: 3px;
    padding-bottom: 1px;
    padding-right: 3px;
    padding-left: 3px;
    font-weight: lighter;
}

#dashboard-page .icon-button-position {
    z-index:1001; /*z-index:1500; had to reduce this since the buttons overlayed popups and dropdowns*/ 
    position:absolute;
    margin-top:-6px;
    margin-right:-6px;
}


.circle {
    width: 20px;
    height: 20px;
    background: white;
    padding-top: 3px;
    text-align: center;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.richtext-styling ul li {
    list-style-type: disc;
}

.richtext-styling ol li {
    list-style-type: decimal;
}

.global-time-period-selector {
    /* display:flex; */
    width: 100px;
    /* flex-direction: column; */
}

#dashboard .nav-button{
    margin-left:10px;
}

#dashboard .preview-app{
    max-height: 150px;
    overflow-y: hidden
}

.preview-app-image{
    max-width: 170px;
    opacity: 0.3;
    margin: auto;
    display: flex;
}

.preview-app-name-panel{
    position: absolute;
    top:100px;
    left:0;
    max-height: 170px;
    width: 100%;
    background: red;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-app-name{
    position: absolute;
    text-transform: uppercase;
    opacity: 0.6;
}

