.improvement-list-result {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
}
.improvement-list-result th.improvement-list-header {
    width: 150px;
    text-align: left;
}
.improvement-list-result th.improvement-list-header:hover {
    background: #f0f0f0;
}
/* Header styling */
.improvement-list-result th .sort-icon {
    margin-left: 20px;
    font-size: 14px;
}
.improvement-list-result th.status {
   text-align: center;
}
.improvement-list-result th.title {
    width: auto;
    min-width: 250px;
}
.improvement-list-result th.dueDate {
    text-align: center;
    width: 100px;
 }
 .improvement-list-result th.cost {
    text-align: right;
    width: 100px;
 }
 .improvement-list-result th.costHours {
    text-align: center;
    width: 120px;
 }
 .improvement-list-result th.benefit {
    text-align: right;
    width: 100px;
 }
 .improvement-list-result th.benefitHours {
    text-align: center;
    width: 120px;
 }
 .improvement-list-result th.priorityStatus {
    text-align: center;
    width: 50px;
 }
/* Cell styling */
.improvement-list-result td.improvement-list-cell {
    
}
.improvement-list-result td.status {
    text-align: center;
}
.improvement-list-result td.title {
    color: #676a6c;
    font-weight: 600;
    font-size: 14px;
}
.improvement-list-result td.dueDate {
    text-align: center;
}
.improvement-list-result td.dueDate.over-due {
    /* background:rgb(251, 233, 243); */
    font-weight: 500;
    color: red;
    /* border-bottom: 1px solid red; */
}
.improvement-list-result td.priorityStatus {
    text-align: center;
}
.priority-status-box {
    display: inline-block ;
    height: 20px;
    width: 26px;
    border-radius: 3px;
}

.improvement-list .badge-white{
    border: 1px solid #e7eaec;
}