.personal-task-list .task-link {
    color: #676a6c;
}

.personal-task-list .fa-square {
    color: #676a6c;
}

.personal-task-list .fa-times-circle {
    margin-right: 2px;
    color: #FF0000;
}

.personal-task-list .fa-check-square {
    margin-right: 2px;
    /*color: #23c6c8;*/
    color: #5cb85c;
}
.personal-task-list .fa-square {
    margin-right: 2px;
    color: gray;
}

.personal-task-list .connected {
    color: #0077B5;
}

.personal-task-list .users {
    display: inline;
    margin-left: 12px;
    cursor: pointer;
}

.action-task-list .fa-times-circle {
    margin-right: 2px;
    color: #E9573F;
}

.action-task-list .fa-check-square {
    margin-right: 2px;
    color: #8CC152;
}
.action-task-list .fa-square {
    margin-right: 2px;
    color: gray;
}

#taskform .selected-text {
    color:#676a6c;
    font-size:14px;
}

#taskform .tagit-close {
    color: var(--digilean-primary-text);
}

#newBoardTask.html .tagit-close {
    color: var(--digilean-primary-text);
}

.table-td-task-list {
    border-top: none;
    border-bottom: 1px solid #e7eaec;
    padding: 15px 10px;
    vertical-align: middle;
}
