asset-selector-dropdown {
    font-size: 1.4rem;
}
asset-selector-dropdown.scalable {
    font-size: unset;
}
asset-selector-dropdown .dropdown-icon {
    font-size:1.8rem;
    margin-top: 0.15em; 
    margin-right: -7px;
}
asset-selector-dropdown button.asset-selector-button {
    font-size: 1em;
}
asset-selector-dropdown ul.dropdown-menu {
    height: 360px;
    max-height: 360px;
}
asset-selector-dropdown ul.dropdown-menu div {
    padding:6px;
}
asset-selector-dropdown ul.dropdown-menu div input {
    width:150px;
    height:26px;
    margin-left: 6px;
    margin-right: 6px;
    padding: 0;
    padding-left: 3px;
}
asset-selector-dropdown ul.dropdown-menu li div.li-content {
    height: 30px;
}
asset-selector-dropdown ul.dropdown-menu li div.li-inner-content {
    float: left;
    margin-left:10px;
}
asset-selector-dropdown ul.dropdown-menu li span.li-inner-content {
    display: inline-block;
    margin-left:12px;
    margin-top:5px;
    margin-bottom:7px;
}