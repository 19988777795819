.task-actions-container {
    width: 24px;
    display: inline-block;
    text-align: center;
    margin-right: 0px;
}
.options-toggle {
    background: var(--digilean-primary-background);
}
.task-actions-container .options-toggle {
    width: 100%;
    /* padding: 3px; */
}
.task-actions-container .options-toggle:hover {
    background:#e6e6e6;
}