.tasklist-table td.task-cell {
    border-top: none;
    border-bottom: 1px solid #e7eaec;
    padding: 15px 10px;
    vertical-align: middle;
}

.a3-table-app {
    height: 100%;
}

a3-table {
    display: block;
    height: 100%;
    overflow: hidden;
    div.a3-table-header {
        padding: 0.3rem 0 0.3rem 0;
        border-bottom: 1px solid var(--digilean-grey-fog);
    }
    div.a3-table-board {
        height: 100%;
        display: flex;
        flex-direction: column;
        div.a3-table-board-container {
            flex: 1 1 auto;
            padding: 0 1rem 1rem 1rem;
        }
    }
    div.a3-table-board-container {
        height: 100%;
        overflow-y: auto;
    }
}


a3table-app {
    display: block;
    height: 100%;
}