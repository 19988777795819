pdf-displayer, pdf-viewer {
    display: block;
    height: 100%;
}
.pdf-displayer-app {
    display: flex;
    flex-direction: column;
}
.pdfviewer-wrapper {
    flex: 1 1 auto;
}