
.production-board .category-cell-size {
    width: 400px;
}
.production-board-table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    min-height: 40px;
}
.production-board .day-header-subtitle {
    background: #f0f0f0;
}
.production-board td {
    min-height: 30px;
    font-size: 12px;
    height: 30px;
    border-top: 1px solid white;
    border-right: 1px solid white;
}
.production-board td.project-cell {
   border-top: 1px solid #c0c0c0c0;
   border-bottom: 1px solid #c0c0c0c0;
   border-left: 1px solid #c0c0c0c0;
   padding: 5px;
}
.production-board td.activity-cell {
    border-top: 1px solid #c0c0c0c0;
    border-bottom: 1px solid #c0c0c0c0;
    border-right: 1px solid #c0c0c0c0;
    padding: 5px;
 }
.production-board input {
    padding-right: 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 6px;
}
.production-board td.total {
    font-weight: bold;
    font-size: 18px;
    text-align: right;
    padding-right: 6px;
}
.production-board td .hour-cell-has-value {
    /* border: 1px solid #c0c0c0c0;
    color: darkgrey; */
    /* background: lightblue; */
}

.production-board td.day-total {
    /* background: #c0c0c0c0; */
    color: rgb(103, 106, 108);
    border-top: 1px solid silver;
    border-bottom: 3px double silver;
}
.production-board td.week-total {
    /* background: #c0c0c0c0; */
    background: white;
    border-top: 1px solid silver;
    border-bottom: 3px double silver;
}

/* Day cell - input registration */
.production-board td.day-cell {
    text-align: right;
    padding-right: 3px;
    
    /* background: #fefefe; */
    /* background: red; */
}
.production-board td.day-cell.row-total {
    font-size: 14px;
  
    /* background: #fefefe; */
    /* background: red; */
    border-bottom: 1px solid silver;
    /* border-right: 1px solid black;
    border-left: 1px solid black; */
}
.production-board td.day-cell.status.row-total {
    border-right: 1px solid silver;
}


.production-board td.day-cell.status {
    text-align: center;
    font-size: 18px;
    /* background: #fefefe; */
    /* background: red; */
}
.production-board td .day-cell-value {
    display:inline-block;
    /* background: white; */
   
    height: 100%;
    width: 100%;
    
}
.production-board td .day-cell-container {
    height: 100%;
    width: 100%;
}
.production-board td .day-cell-container.green {
    background: #EDF7E3;
    color: #8CC152;
    font-weight: bold;
}
.production-board td .day-cell-container.red {
    background: #FEE4DF;
    color: red;
    font-weight: bold;
}
.production-board td.day-cell.status {
    text-align: left;
    padding-left: 12px;
}
.production-board td .status-comment {
    font-size: 16px;
}

.production-board td .day-dropdown-toggle {
    display:inline-block;
    height: 100%;
    width: 100%;
    /* background: #c0c0c0c0; */
    font-size: 12px;
    text-align: right;
    padding-right: 6px;
    padding-top: 4px;
}
.production-board td.day-cell:hover  {
    background: lightblue;
}
.production-board-day-cell-dropwdown {
    min-width: 300px;
    /* min-height: 300px; */
    padding: 15px;
    padding-top: 5px;
}
.production-board-day-cell-dropwdown .day-registration {
    
}

