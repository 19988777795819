task-board-selector {
    display: block;
    color: var(--digilean-primary-text); /*color: var(--digilean-grey);*/
    #boards-selector-popover {
        margin: 0;
        inset: unset;
        min-height: 10rem;
        max-height: 36rem;
        top: anchor(bottom);
        right: anchor(right);
        position-try-fallbacks:
            --popup-selector-highest, --popup-selector-high,
            --popup-selector-low, --popup-selector-lowest;

        background: var(--digilean-secondary-background);
        border: 1px solid var(--digilean-input-border);
        border-radius: 3px;
        box-shadow: var(--digilean-popup-shadow);

        section.boards-popover-content {
            min-width:23rem;
            color: var(--digilean-primary-text);
            div.row-column-placement {
                padding: 1.5rem;
            }
            div.label-text {
                width: 22rem;
                margin-bottom: 1.5rem;
            }
        }

        ul {
            list-style: none outside none;
            margin-block-start: 0;
            margin-block-end: 0; 
            padding: 1rem;
            li {
                color: var(--digilean-primary-text);
                height: 3.5rem;
                &.search-form {
                    height: 4rem;
                    div {
                        height: 3rem;
                        padding-bottom: 1rem;
                    }
                    input {
                        width: 21rem;
                    }
                }
                a {
                    color: var(--digilean-primary-text);
                }
            }
        }
    }
}

@position-try --popup-selector-highest {
    height: 36rem;
}
@position-try --popup-selector-high {
    height: 27rem;
}
@position-try --popup-selector-low {
    height: 22rem;
}
@position-try --popup-selector-lowest {
    height: 18rem;
}