/*
 Fullscreen Dialog
*/
div.modal.digilean-diagram-edit {
    padding: 0 !important;
}
div.modal.digilean-diagram-edit .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
}
div.modal.digilean-diagram-edit .modal-header {
    background: var(--digilean-main-menu-background);
    padding: 0.3rem 1rem;
}
div.modal.digilean-diagram-edit .modal-content {
    height: 100%;
    max-height: 100%;
    border: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
}
div.modal.digilean-diagram-edit .modal-body {
    overflow-y: auto;
    flex: 1;
}

div.modal.digilean-diagram-edit div.modal-footer #templatesBtn {
    display: none;
}
div.modal.digilean-diagram-edit div.modal-footer #templatesBtn.show-templates {
    display: inline;
}
/* Widget
*/
digilean-diagram-app div.digilean-diagram-widget {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
digilean-diagram-app div.diagram-wrapper {
    flex: 100% 1 1;
    padding: 0.5em;
}
ul.dropdown-menu.dropdown-menu-right.digilean-diagram-widget-menu {
    z-index: 1000;
}
/* svg sizing
*/
digilean-diagram div#digilean-diagram svg {
    width: 100%;
    height: 100%;
}

/*
digram app in flexiboard
*/
div.diagram-wrapper digilean-diagram #digilean-diagram:not(.edit-possible) {
    cursor: text;
}

div.diagram-section-wrapper digilean-diagram #digilean-diagram:not(.edit-possible) {
    cursor: text;
}

digilean-diagram {
    display: block;
    box-sizing: border-box;
    max-height: 100%;
}

digilean-diagram div.digilean-diagram {
    width: 100%;
    height: 100%;
    overflow: hidden;
}