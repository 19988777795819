.a3-template-card {
    margin-top: -50px;
}
.a3-template-card .widget {
    height: calc(100% - 2px);
}

.a3-template-card .ibox-content {
    border: none;
    padding:0;
}

    .a3-template-card .preview-header {
        padding:6px; 
        margin-top:0px;
        padding-left:7px;
    
    }

    .a3-template-card .widget .widget-component {
        height: inherit;
        height: 100%;
        overflow-y: scroll;
    }

    .a3-template-card .widget .widget-header {
        color: gray;
        background-color: #f0f0f0;
        border-color: silver;
    }

    .a3-template-card label {
        margin-top: 5px;
    }

    .a3-template-card .widget .widget-content {
        /*height: inherit;*/
        height: 100%;
        overflow-y: hidden;
        border: 2px solid #f0f0f0;   
    }

    .a3-template-card .widget .add-app-button {
        display: none;
    }



