scalable-text {
    display: inline-block;
    height: 100%;
}
scalable-text svg {
    width: 100%;
    height: 100%;
}
svg text.scalable-text {
    fill: var(--digilean-primary-text);
}

/*
special cases
*/
daily-kpi-date svg text.scalable-text {
    fill: white;
}

.greencross-green svg text.scalable-text,
.greencross-blue svg text.scalable-text,
.greencross-yellow svg text.scalable-text,
.greencross-red svg text.scalable-text {
    fill: white;
}
