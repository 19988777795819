.feed-element .feed-icon {
    margin-right: 6px;
   
}

.daily-feed-item {
    background-color: #f8f8f9;
    overflow-wrap: break-word;
}

.daily-feed-toggle {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 0px;
    font-size: 13px;
    color: #676a6c;
    text-transform: uppercase;
}
