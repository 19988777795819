.modal.full-screen {
    padding: 0 !important;
    height: 100%;
    box-sizing: border-box;
}

.modal.full-screen .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
}
.modal.full-screen #fullscreen-widget {
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
}
.modal.full-screen .modal-content {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: 0;
    background: var(--digilean-primary-background);
    box-sizing: border-box;
}

.modal.full-screen .modal-header {
    padding: 5px 15px;
    background: var(--digilean-primary-background);
    box-sizing: border-box;
}

.modal.full-screen .modal-body {
    overflow-y: auto;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    background: var(--digilean-secondary-background);
    box-sizing: border-box;
}

.modal.full-screen .digilean-widget.ibox {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    max-height: fit-content;
}
.modal.full-screen .digilean-widget .widget-content {
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    max-height: fit-content;
    overflow: hidden;
}

.modal.full-screen .month-selector-text {
    align-self: center;
    width: 40%;
}

.modal.full-screen .kpi-month-selector {
    font-size: 3rem;
}

.modal.full-screen projectboard-app .ibox-content {
    border-top: none;
}

.modal.full-screen .five-s-container table{
    width: 100vmin;
}