.planMainCategory{
    font-weight: bold;
    width: 245px;
    text-align: left;
    margin-left: 0px;
}

.planSubCategory{
    
    width: 245px;
    text-align: left;
    padding-left: 22px;
}
/* Timeframe selector */
.timeframe-dropdown .timeframe-option {
    padding: 0;
}
/* Gridster styles */
.demo {
    margin: 3em 0;
    padding: 7.5em 0 5.5em;
    background: #004756;
}
    
.richtext-styling ul li {
    list-style-type: disc;
}

.richtext-styling ol li {
    list-style-type: decimal;
}

.dataserie-modal-window .modal-dialog {
    width: 800px;
    height: 800px;
}

.table-hover .selected-item{
    background-color: #e8e8e8;
}
.table-hover>tbody>tr:hover{
    background-color: var(--digilean-primary-background)
}

#dashboard .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
}

#component-setting .dropdown-submenu>.dropdown-menu {
    top: 0;
    right: -70%;
    margin-top: -6px;
    color: red;
    background: green;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px;
    border-radius: 6px 0 6px 6px;
  }

  /*
  #component-setting .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 70%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }*/


#systemdashboard integrations-monitor .wrapper,
#systemdashboard integrations-monitor .ibox-content {
    padding: 0;
}
