task-user-selector {
    display: block;
    #users-selector-popover {
        margin: 0;
        inset: unset;
        height: 36rem;
        top: anchor(bottom);
        right: anchor(right);
        position-try-fallbacks:
            --popup-selector-highest, --popup-selector-high,
            --popup-selector-low, --popup-selector-lowest;

        background: var(--digilean-secondary-background);
        border: 1px solid var(--digilean-input-border);
        border-radius: 3px;
        box-shadow: var(--digilean-popup-shadow);

        section.users-popover-content {
            color: var(--digilean-primary-text);
            div.clear-responsible {
                margin-top: 0.5rem;
                padding: 0.3rem 2rem;
            }
            div.search-form {
                width: 22rem;
                height: fit-content;
                margin: 0.2rem 0.5rem;
            }
        }
        ul {
            min-width: 20rem;
            overflow-y: auto;
            list-style: none outside none;
            margin-block-start: 0;
            margin-block-end: 0; 
            padding: 1rem;
            li {
                height: 4.5rem;
            }
        }
    }
}
