.personal-board .smart-kanban {
    overflow: none;
}
.personal-board .smart-kanban ul {
    list-style: none;
    padding-left: 6px;
    padding-right: 6px;
    
}

.personal-board .smart-kanban li {
    list-style: none;
}
.personal-board .smart-kanban .personal-card {
    padding: 0px;
}
.personal-card{
    border: none;
    position:relative;
    padding:0px;
    margin-bottom: 0px;
    word-wrap: break-word;    
 }

 .personal-card .date{
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 1;
    background:#999999;
    width: 40px;
    height: 40px;
    padding: 5px 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #FFFFFF;
    font-weight: 700; 
    text-align: center;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
 }
 .personal-board .personal-card .personal-card-priority {
     position: absolute;
     bottom: 0px;
     right: 10px;
 }


 .personal-card .overdue{
    background: var(--digilean-danger);
}

.personal-card .post-content .noimage{
    margin-top:38px;;
}

 .personal-card .date .day {
    font-size: 13px;
  }

  .personal-card .date .month {
    font-size: 10px;
    text-transform: uppercase;
  }


  .personal-card  .post-content  {
    padding: 10px 15px;
}

.personal-card .sub_title {
    color: var(--digilean-danger);
  }

  .personal-card .post-meta{
    color: #999999;
    text-decoration: none;
}

  .personal-card .date .day {
          font-size: 13px;
        }
    
    .personal-card .date .today {
        font-size: 12px;
        margin-top: 6px;
    }
    .personal-card .date .month {
          font-size: 10px;
          text-transform: uppercase;
        }
      
    .personal-card .post-module .thumbnail{
            border: none;
            position:relative;
            padding:0px;
            margin-bottom: 0px;
        }


    .personal-card .post-module .thumbnail .date {
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 1;
            background: var(--digilean-danger);
            width: 40px;
            height: 40px;
            padding: 5px 0;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            color: #FFFFFF;
            font-weight: 700; 
            text-align: center;
            -webkti-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
           
        }

        .personal-card .post-content  {
            padding: 10px 10px;
            padding-right: 40px;
        }

        .personal-card .sub_title {
            color: var(--digilean-danger);
          }

       .personal-card .post-meta{
            color: #999999;
            text-decoration: none;
        }

        .personal-card .post-meta .info{
            margin-left: 6px;
        }