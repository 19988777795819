.dashboard-preview .tabs-container ul {
    /* border-bottom: 1px solid #0099DE; */
    background: white;
} 
.dashboard-preview .tabs-container li {
    /* border-bottom: 1px solid #0099DE; */
    border: none;
    font-weight: 600;
    border-right: 1px solid silver;
    border-bottom: 2px solid silver;
    border-radius: 0;
    padding-left: 6px;
    padding-right: 6px;
    padding: 6px;
    /* background: red; */
    min-width: 100px;
} 
.dashboard-preview .tabs-container li.selected {
    border-bottom: 2px solid #0099DE;
} 
.dashboard-preview .dashboard-preview-image  {
    object-fit: fill;
} 