#postItContainer {
    margin-top: -10px;
}
#postItContainer .post-it {
    height: 24px;
    width: 24px;
    margin: 5px;
    border: 1px solid darkgrey;
}

#postItContainer .large-post-it {
    height: 42px;
    width: 120px;
    margin: 5px;
    border: 1px solid darkgrey;
}
#postItContainer .selected {
    height: 28px;
    width: 28px;
    border: 3px solid darkgrey;
}

.post-it-dragged {
    z-index: 1100;
    height: 120px;
    width: 160px;
    padding: 12px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.20);
}
.post-it.gu-mirror {
    min-width: 60px;
    min-height: 60px;
    border-radius: 6px;
}
