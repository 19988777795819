single-value-registration .ibox-content {
    padding: 1em;
}
single-value-registration .ibox-content h1 {
    font-size: 2em;
}

single-value-registration .ibox-content .single-data-source {
    height:100%;
    width:100%;
    padding: 0.7em;
}