.text-editor .fr-box {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.text-editor  .fr-wrapper {
    flex: 1;
}
.text-editor  .fr-element {
    min-height: 100%;
}

text-editor .fr-element.fr-view {
    p {
        font-size: 1em;
    }
    h1 {
        font-size: 2em;
        font-weight: 500;
    }
    h2 {
        font-size: 1.5em;
        font-weight: 500;
    }
    h3 {
        font-size: 1.17em;
        font-weight: 500;
    }
    h4 {
        font-size: 1em;
        font-weight: 500;
    }
    h5 {
        font-size: .83em;
        font-weight: 500;
    }
    h6 {
        font-size: .67em;
        font-weight: 500;
    }
}