/*  FIXED HEADER EXPERIMENT */
.board-table-container {
    height: 600px;
    padding-right: 15px;
    overflow-y: auto;
    overflow-x: hidden;
}
.board-table-container table.board {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    min-height: 40px;
}
.board-table-container thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    background: white;
    top: 0;
    z-index: 1000;
}

.board-table-container thead th.category-header {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    background: white;
    top: 0;
    z-index: 1000;
    width: 120px;    
}

.board-table-container thead th:first-child {
    left: 120;
    z-index: 1;
    border-radius: 0;
}
.board-table-container thead th:first {
    /* width: 40px; */
}
.board-table-container tbody th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    left: 0;
  
}
.board-table-container .category-cell-size {
    /* padding-top: 15px; */
    width: 80px;
    max-width: 80px;
}
.board-table-container .category-cell-size.vertical-text {
    /* padding-top: 15px; */
    width: 40px;
    max-width: 40px;
}
.action-list-container {
    min-height: 600px;
    /* margin-left: 5px;
    margin-right: 5px; */
}
.actionlist-content {
    width: 100%;
    min-height: 300px;
    overflow-y: auto;
}
/*  END HEADER EXPERIMENT */


.floatThead-table {
    background: white;
}

.boardtemplate-selection table {
    border-collapse: separate;
   border-spacing: 2px; 
}
.boardtemplate-selection .template-item {
    background: var(--digilean-template-item);
    padding-top: 4px;
    padding-bottom: 4px;
}
.boardtemplate-selection .selected-template {
    background:var(--digilean-template-item-selected);
}

.actionlist {
    min-height: 60px;
}




#myTask {
    font-size: 13px;
}
/* Action list */
#boardContainer .col-lg-2 {
    padding-left: 0;
}

.boardcategoryaction-list {
    /*position: fixed;*/
}

/* New deviation*/
.newdeviation-modal-window .modal-dialog {
    width: 800px;
    height: auto;
}

.fixedwidth-modal-window .modal-dialog {
    width: fit-content;
    min-width: 800px;
    height: auto;
    max-width: 90vw;
    overflow: auto;
}

.modal-dialog .well {
    max-height: 100px;
    overflow: auto;
}

#boardContainer {
    height: calc(100% - 30px);
}
#boardContainer .ibox{
    margin-bottom: 0;
}
#boardContainer .ibox-content{
    padding-bottom: 5px;
}
#boardContainer table {
    border-spacing: 4px;
    border-collapse: separate;
}

#boardContainer .timeboard table {
    border-spacing:  0px;
    border-collapse: unset;
}


.timeboard .fc-event {
    background: red;
}

    #boardContainer .delete-board {
        color: red;
    }

    #boardContainer #boardTable {
        /* background: #f5f5f5; */
        background: white;
        width: 100%;
        height: 100%;
        margin: auto;
    }

    #boardContainer .image-container {
        /*border-color: #253746;
        border-width: thin;
        border: 1px solid #c0c0c0;*/
    }
    

.board td {
    /* border: 1px solid rgb(226, 228, 230); */
    vertical-align: top;
       background: #f5f5f5;
    /*background: rgb(226, 228, 230)*/
}

.board th {
    background: #c0c0c0;
    color: gray;
    height: 80px;
    height: 45px;
    border-radius: 5px;
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
    margin: 0px;
    margin-right: 5px;
    vertical-align: top;
    padding: 0px;
    white-space: nowrap;
    z-index: 100;
    /* border: 1px solid rgb(226, 228, 230); */
    /*padding: 15px;*/
}

#boardContainer td {
    /*color: gray;*/
    color: black;
    color: #676a6c;
    color: #253746;
}

#boardContainer #board-toolbar-table {
    background: transparent;
    margin-top: -6px;
    width: 100%;
}

#board-toolbar-table td {
    vertical-align: top;
    background: transparent;
}

#boardContainer .boardtitle {
    width: 200px;
}

#boardContainer .actionlist-toggle {
    margin: 6px;
}

.weekcontainer {
    width: 200px;
    margin-right: 12px;
}

 .weekcontainer h3 {
        float: left;
        width: 120px;
        text-align: center;
        font-size: 20px;
    }

  .weekcontainer .fc-button {
        float: left;
        /*font-size: 18px;*/
    }

   .weekcontainer .fc-button:hover {
            color: #0099DE;
        }

    .weekcontainer .date {
        float: left;
        margin-right: 32px;
        color: silver;
    }

    .weekcontainer .dateRight {
        float: right;
        margin-right: 24px;
        color: silver;
    }

#boardContainer .task-without-tag {
    float: right;
    margin-right: 35px;
}

#boardContainer .clear-filter {
    margin-left: 79px;
}

#boardContainer .hovered {
    background: #34B3E4;
    /* padding: 6px; */
}



    
#boardContainer .activeuser {
    padding: 6px;
    /*color: lightgreen;
    padding: 6px;*/
}

#boardContainer .connected {
    /*color: white;*/
    color: #0077B5;
}

.activeFilter {
    background: #4FC1E9;
    color: white;
}

.filterbutton {
    float: left;
    padding: 0px;
    width: 150px;
    margin: 3px;
    cursor: pointer;
    /*border: 1px solid dimgray;*/
}

.filterbutton-text {
    float: left;
    width: 120px;
    padding: 3px;
    background: #F5F7FA;
}

.filterbutton-tagcount {
    float: right;
    text-align: center;
    background: #E6E9ED;
    padding: 3px;
    width: 30px;
}

.activefilter-text {
    float: left;
    width: 120px;
    padding: 3px;
    background: #4FC1E9;
}

.activefilter-tagcount {
    float: right;
    text-align: center;
    background: #3BAFDA;
    padding: 3px;
    width: 30px;
}

.tags .dropdown-menu {
    width: 400px;
    min-height: 400px;
}

#tagfilter {
    max-height: 30px;
}

.connected-users-list {
    padding: 10px;
    width: 400px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.connected-users-list li {
    margin: 6px;
    display: inline;
    cursor: pointer;
}
.connected-users-list .user-card {
    text-align: center;
    /* width: 50px; */
}

#connectedUserList {
    margin: 0px;
    margin-top: -15px;
    margin-right: 24px;
    /* max-height: 60px; */
}

    #connectedUserList .fa {
        /* margin-right: 6px; */
    }

    #connectedUserList li {
        display: inline;
        margin-left: 6px;
        color: gray;
        cursor: pointer;
    }

    @media (max-width: 1100px) { #connectedUserList {
            display:none;
        }
    }

.iconStyling {
    padding-left: 3px;
    padding-right: 3px;
    color: silver;
    background: rgb(243, 243, 244);
}


.board td {
    height: 100%;
}
.board td.board-table-cell {
    height: 1px;
}
.board .rotate-row-headers {
    text-align: center;
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

    .board .rotate-row-headers:hover {
        color: #4fc1e9;
        font-size: 18px;
    }

.board .list-inline {
    margin-bottom: 0px;
    margin-top: 0px;
}

.board .rowcategory {
    /* max-width: 60px; */
    background: darkgray;
    color: #fff;
    font-size: 1em;
    text-align: center;
    height: 1px;
    border-radius: 5px;
    min-width: 50px;
}

.board .category-day-container {
    float: right;
    /* margin: 2px; */
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    color: #253746;;
    width: 30px;
    height: 30px;
    background:white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.board .top-right {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -2px;
    margin-right: -2px;
}

.board .responsible-for-category {
    cursor: pointer;
}


.board .responsible-for-category-container {
    float: right;
    margin-right: 6px;
    font-size: 10px;
    font-weight: normal;
}


.board td .responsible-for-category {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 11px;
    width: 100%;
    text-align: center;
}

.board .rowcategory-user-container {
    height: 100%;
    min-height: 40px;
    width: 100%;
    position: relative;
}
.rowcategory-user-container .userinfo {
    /* background: white; */
    /* margin-left: -10px;
    margin-bottom: -3px; */
    padding: 5px;
    margin: auto;
    /* border: 2px solid #e7eaec;
    border-radius: 0px 0px 5px 0px; */
    color: gray;
    /* float: right; */
    /* width: 80%; */
}
.rowcategory-user-container .category-displayname {
    min-width: 35px;
    display:inline-block;
    text-align: center;
}
.responsibleuser .userinfo img {
    border: 1px solid silver;
    border-radius: 50;
    height: 32px;
    width: 32px;
}
.board .hide-button {
    float: right;
    margin-top: -6px;
    margin-left: 6px;
    font-size: 16px;
    font-family: helvetica;
    color: red;
    display: none;
}

.board div:hover .hide-button {
    display: block;
    color: red;
}

.board .vertical {
    position: relative;
    top: 50%;
    max-width: 100px;
    /* height: 100%; */
    /* width: 100%; */
    /* transform: translateY(-50%); */
    transform: rotate(-90deg);
    text-align: center;
    font-weight: bold;
    /* word-wrap: break-word; */
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
}

.board .horizontal {
    position: relative;
    top: 50%;
    word-wrap: break-word;
    transform: translateY(-50%);
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
    font-weight: bold;
    max-width: 120px;
}

/*Expander text */
.board .verticalText {
    transform: rotate(90deg);
    transform-origin: -12px 40px;
    white-space: nowrap;
    width: 20px;
}

.board .verticaldateText {
    text-align: center;
    vertical-align: bottom;
    margin: auto;
    font-weight: normal;
    font-size: 1em;
    /*margin-left: 34px;
    margin-top: -18px;*/
    /*transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg); 
    -moz-transform: rotate(-90deg); 
    -o-transform: rotate(-90deg); 
    -ms-transform: rotate(-90deg); */
}

.board .boardcell {
    height: 100%;
    min-height: 40px;
    word-wrap: break-word;
    padding-bottom: 1em;
}

.board .today {
    transition: all 2s ease;
    background: #d9edf7;
    /*padding-bottom: 1em;*/
}

.board th .headerText {
    overflow: hidden;
    font-size: 12px;
    margin-left: 12px;
    margin-top: 6px;
}

.board th .dateText {
    font-size: 10px;
    margin-left: 12px;
    color: silver;
}

.board th .fa-caret-square-o-left {
    color: #c0c0c0;
    float: right;
    margin-right: 16px;
    margin-top: 0px;
}

.board th .fa-caret-square-o-right {
    color: #c0c0c0;
    margin-top: 12px;
    margin-left: 12px;
}

.portlet {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.portlet:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.portlet .taskdescription, .tasktitle {
    /*width: 80%; */
    margin-right: 20px;
    min-height: 20px;
}

.portlet .taskdescription, .taskheader {
    color:#3d3f40;
    font-weight:550;
    font-size:12px;
}

.kamishibai .taskheader {
    color:#ffffff;
    font-weight:550;
    font-size:16px;
}

.kamishibai .portlet .taskdescription {
    color:#ffffff;
    font-weight:550;
    font-size:16px;
}

.portlet .status {
    float:right;
    margin-left:2px;
}

.kamishibai .portlet .status {

        float:right;
        margin-left:2px;
    }

.column-minimize {
    width: 40px;
    margin: auto;
    background: silver;
    display: none;
}

.column-expand {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
}


@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.portlet.tilt.right {
    transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
}

.portlet.tilt.left {
    transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
}



.weekInfo {
    font-size: 1.6em;
    width: 100%;
    margin: auto;
}

    .weekInfo .fa {
        margin-right: 20px;
        margin-left: 20px;
    }


#boardContainer .fa-times-circle {
    margin-right: 2px;
    color: #FF0000;
    color: var(--digilean-danger);
}


#boardContainer .fa-check-square {
    margin-right: 2px;
    color: #23c6c8;
    color: #5cb85c;
}

#boardContainer .kamishibai .fa-check-square {
    background: #8cc152;
    color: #ffffff
}

#boardContainer .kamishibai .far .fa-times-circle {
    color: #ffffff; 
    background: var(--digilean-danger);
    border: none;
}

#boardContainer .fa-square {
    margin-right: 2px;
    color: gray;
}

#boardContainer .activeCell {
    background: #4FC1E9;
}

#boardContainer .btn-danger {
    float: left;
}

.portlet {
    position: relative;
    margin: 0.5em;
    margin-bottom: 1em;
    padding: 0.3em;
    background: white;
    /* box-shadow: 0 0 5px 1px rgba(0,0,0,.20); */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    height: auto;
}


.portlet-header {
    /* background: #fafafa; */
    background: transparent;
    position: relative;
    cursor: move;
    width: auto;
    min-height: 20px;
}

    .portlet-header .fa, .portlet-header .fas, .portlet-header .far {
        cursor: pointer;
    }

    /* Safari 4.0 - 8.0 */
    @-webkit-keyframes okColor {
        to {background-color: #edf7e3;}
       /* to {background-color: #e0fbf5;}*/ 
    }
    
    @keyframes okColor {
        /*
        10%  {background: #e0fbf5;}
        20%  {background: #b1f8e9;} 
        50%  {background: #e0fbf5;}
        70%  {background: #b1f8e9}
        100% {background: #e0fbf5;}
        */
        10%  {background: #edf7e3;} 
        20%  {background: #bce2bc;} 
        50%  {background: #edf7e3;}
        70%  {background: #bce2bc;}
        100% {background: #edf7e3;}
    }

        /* Safari 4.0 - 8.0 */
        @-webkit-keyframes okKamishibaiColor {
            to {background-color: #8cc152;} 
        }
        
        @keyframes okKamishibaiColor {
            to {background-color: #8cc152;} 
        }

    @-webkit-keyframes unacceptableKamishibaiColor {
        
        to {background-color: var(--digilean-danger);}
    }
    
    @keyframes unacceptableKamishibaiColor {
        
        to {background-color: var(--digilean-danger);}
    }
    
    @-webkit-keyframes unacceptableColor {
        
        to {background-color: #f2dede;}
    }
    
    @keyframes unacceptableColor {
        
        to {background-color: #f2dede;}
    }

.portlet .OK {
    -webkit-animation: okColor 1s;  /* Safari 4.0 - 8.0 */
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation: okColor 1s;
    animation-fill-mode: forwards;
    /* background: #e0fbf5; */
}

.kamishibai-icon {
    color:white;
}
.kamishibai .portlet .OK {
    -webkit-animation: okKamishibaiColor 1s;  /* Safari 4.0 - 8.0 */
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation: okKamishibaiColor 1s;
    animation-fill-mode: forwards;
    /* background: #e0fbf5; */
}
.portlet .statusOK {
    border: 3px solid #01c298
}

.portlet .unacceptable {
    -webkit-animation: unacceptableColor 1s;  /* Safari 4.0 - 8.0 */
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation: unacceptableColor 1s;
    animation-fill-mode: forwards;
}

.kamishibai .portlet .unacceptable {
    -webkit-animation: unacceptableKamishibaiColor 1s;  /* Safari 4.0 - 8.0 */
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation: unacceptableKamishibaiColor 1s;
    animation-fill-mode: forwards;
}

.portlet-placeholder {
    border: 1px dotted darkgray;
    background: #f0f0f0;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.10);
    min-height: 40px;
}

.portlet .date-container {
    font-size: 12px;
    margin-top: 3px;
    margin-left: 3px;
    margin-bottom: 3px;
}

    .portlet .date-container .fa {
        font-size: inherit;
        color: #676a6c;
    }
    .portlet .date-container .overdue {
        font-size: inherit;
        color: red;
    }

    .kamishibai .portlet .date-container .overdue {
        font-size: inherit;
        color: yellow;
    }
    .portlet .date-container span, .portlet .date-container date-viewer {
        margin-left: 2px;
        font-weight: bold;
        font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

.portlet .info-container {
    float: right;
    color: silver;
    /*background: rgb(243, 243, 244);*/
}

.portlet .tag-container {
    padding-left: 6px;
    padding-top: 6px;
}

    .portlet .tag-container small {
        background: rgb(243, 243, 244);
    }

    .portlet .tag-container li {
        font-weight: bold;
        margin-left: -3px;
        margin-bottom: 6px;
    }

.portlet .content-wrapper {
    display: table;
    min-height: 18px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-top: 1px solid #f0f0f0; /*border between task title and user*/
}

.portlet .kamishibai-content-wrapper {
    display: table;
    min-height: 18px;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.portlet .content {
    display: table-row;
   
}

    .portlet .content > div {
        display: table-cell;
    }

.commentscontainer span {
    text-align: right;
    margin-left: 4px;
}

.portlet .commentscontainer {
    position: relative;
    text-align: right;
    cursor: pointer;
    /*border-top: 1px solid #f0f0f0;*/
}

.attachmentcontainer span {
    text-align: right;
    margin-left: 4px;
}

.attachmentcontainer {
    position: relative;
    text-align: right;
    cursor: pointer;
    /*border-top: 1px solid #f0f0f0;*/
}


.portlet .responsiblecontainer {
    position: relative;
    text-align: right;
    cursor: pointer;
    width: 54px;
}

.responsibleuser img {
    border: 1px solid silver;
    border-radius: 50;
    height: 48px;
    width: 48px;
}

.responsibleuser span {
    text-align: left;
    /*float: left;*/
    margin-left: 4px;
}

.responsibleuser .fa {
    /*float: left;*/
    margin-left: 6px;
}
/* actioncontainer */

.portlet .actioncontainer {
    text-align: left;
    position: relative;
    /* height: 24px; */
    display: table-cell;
    /*border-top: 1px solid #f0f0f0;*/
}

    .portlet .actioncontainer .dropdown {
        max-width: 30px;
        /* float: left; */
        /* height: 24px; */
    }

    .portlet .actioncontainer .fa,
    .portlet .actioncontainer .fas,
    .portlet .actioncontainer .far,
    .portlet .actioncontainer .fal {
        margin-right: 6px;
        margin-left: 6px;
        cursor: pointer;
    }

    .portlet .actioncontainer .delete-task {
        color: red;
    }

/* Cloneindicator */
.portlet .clonecontainer {
    height: 24px;
    display: table-cell;
    vertical-align: central;
   /* border-top: 1px solid #f0f0f0;*/
    text-align: center;
    color: gray;
    margin-right: 6px;
}

.portlet .clone-icon:hover {
    background: #4FC1E9;
    cursor: pointer;
}

.portlet .clone-icon {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 6px;
    margin-right: 3px;
}

.portlet .info-icon {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 6px;
    margin-right: 3px;
}

.portlet .improvement-icon:hover {
    background: #4FC1E9;
    cursor: pointer;
}

.portlet .deviation-icon:hover {
    background: #4FC1E9;
    cursor: pointer;
}

/*
.portlet .repeat-icon {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 6px;
    margin-right: 3px;
}*/



/* Durationcontainer */



.portlet .durationcontainer {
    text-align: left;
    height: 24px;
    display: table-cell;
    vertical-align: central;
}

    .portlet .durationcontainer .dropdown {
        max-width: 30px;
        float: left;
        height: 24px;
    }

    .portlet .durationcontainer .clockpickertext {
        font-weight: bold;
        margin-left: 6px;
    }

    .portlet .durationcontainer .fa-clock-o {
        margin-top: 9px;
        height: 24px;
        cursor: pointer;
    }

    .portlet .durationcontainer .durationvalue {
        float: left;
        margin-top: 6px;
        margin-left: 6px;
    }

    .portlet .durationcontainer .dropdown .durationvalue {
        float: none;
        height: 24px;
        margin-top: 6px;
        margin-left: 6px;
        margin-bottom: 6px;
        font-size: 1.5em;
    }

#taskform {
    max-height: 300px;
}

    #taskform #imageSpacing {
        margin-right: 12px;
        margin-bottom: 12px;
    }

    #taskform .checked-ok {
        color: #8cc152;
    }

    #taskform .checked-unacceptable {
        color: #FF0000;
    }

    #taskform .blank {
        color: gray;
    }

    #taskform .disabled-status {
        color: #e7eaec; /* set not selected color */
    }

/* ref old modal popup
#myTask .modal-dialog {
    width: 350px;
}*/
/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/

.fc-icon {
    display: inline-block;
    font-size: 2em;
    line-height: .5em;
    height: .5em; /* will make the total height 1em */
    font-family: "Courier New", Courier, monospace;
}

.fc-icon-left-single-arrow:after {
    content: "\02039";
    font-weight: bold;
}

.fc-icon-right-single-arrow:after {
    content: "\0203A";
    font-weight: bold;
}

.fc-icon-left-double-arrow:after {
    content: "\000AB";
}

.fc-icon-right-double-arrow:after {
    content: "\000BB";
}

.fc-icon-x:after {
    content: "\000D7";
}
/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/

.fc button {
    /* force height to include the border and padding */
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* dimensions */
    margin: 0;
    height: 2.1em;
    padding: 0 .6em;
    /* text & cursor */
    font-size: 1em; /* normalize */
    white-space: nowrap;
    cursor: pointer;
}

    /* Firefox has an annoying inner border */
    .fc button::-moz-focus-inner {
        margin: 0;
        padding: 0;
    }

.fc-state-default { /* non-theme */
    border: 1px solid;
}

    .fc-state-default.fc-corner-left { /* non-theme */
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .fc-state-default.fc-corner-right { /* non-theme */
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

/* icons in buttons */

.fc button .fc-icon { /* non-theme */
    position: relative;
    top: .05em; /* seems to be a good adjustment across browsers */
    margin: 0 .1em;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/

.fc-state-default {
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    color: #333333;
    background-color: #e6e6e6;
}

.fc-state-hover {
    color: #333333;
    text-decoration: none;
    background-position: 0 -15px;
    -webkit-transition: background-position 0.1s linear;
    -moz-transition: background-position 0.1s linear;
    -o-transition: background-position 0.1s linear;
    transition: background-position 0.1s linear;
}

.fc-state-down,
.fc-state-active {
    background-color: #cccccc;
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-disabled {
    cursor: default;
    background-image: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
}


#modal-board-task .commentscontainer {
    max-height: 400px;
    overflow-y: auto;
}

#modal-board-task .historycontainer {
    max-height: 400px;
    overflow-y: auto;
}

.descriptiontextcontainer .description {
    display: none;
    margin-top: 4px;
    margin-bottom: 4px;
}



