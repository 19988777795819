.inbox .nav-link.active{
    background: var(--digilean-secondary-background);
    border-left: 4px solid #0099DE;
    margin-right: -17px;
    border-top: 1px solid #E3E7EB;
    border-bottom: 1px solid #E3E7EB;
}

.inbox-notifications {
    /* background: red; */
}
.inbox-notifications .section-header {
    color: #6c757d;
    
    /* border-bottom: 1px solid #6c757d; */
}
.inbox-notifications td.unseen-item {
    border-left: 3px solid rgb(0, 153, 222);
}
.message-card-container {
    padding-top: 10px;
    /* background-color: #f3f3f4; */
}
 .message-card {
    background: var(--digilean-secondary-background);
    margin: 5px;
    margin-left: 0px;
    padding: 10px; 
    border-top: 1px solid #e7eaec;
    border-bottom: 1px solid #e7eaec;
    border: 1px solid #e7eaec;
    /* border-radius: 8px; */

}
.task-assigned-message {
    width: 800px;
}
.task-comment-mention-message .task-info-card {
    /* border: 10px solid #e3e3e3; */
    padding: 10px;
    margin-bottom: 20px;
}

.updated-responsible-deviation-message {
    max-width: 650px;
}
.updated-responsible-suggestion-message {
    max-width: 650px;
}
.inbox-notifications .archive-button  {
    margin-top: 10px;
    background: #fbfbfb;
    color: gray;
}
.inbox-notifications .archive-button:hover  {
    background: #0099DE;
    color: white;
}
.inbox-notifications .archive-button .archive-icon {
    font-size: 20px;
}
.inbox-notifications .deviation-card, .improvement-card {
    max-width: 800px;
}
.inbox-notifications .deviation-card.low-severity {
    border: 1px solid #23c6c8;
    border-top: 1px solid #23c6c8;
    border-radius: 5px;
    background: #bdedee;
}

.inbox-notifications .deviation-card.medium-severity {
    border: 1px solid #FFCE54;
    border-top: 1px solid #FFCE54;
    border-radius: 5px;
    background: #fce6cd;
}

.inbox-notifications .deviation-card.high-severity {
    border: 1px solid #DA4453;
    border-top: 1px solid #DA4453;
    border-radius: 5px;
    background: #f9ccd0;
}

.inbox-notifications .improvement-card.highEffectHighEffort {
    border: 2px solid #d9edf7;
    border-bottom: 3px solid #008CC9;
}

.inbox-notifications .improvement-card.highEffectLowEffort {
    border: 2px solid #dff0d8;
    border-bottom: 3px solid #8CC152;   
}

.inbox-notifications .improvement-card.lowEffectHighEffort {
    border: 2px solid #f2dede;
    border-bottom: 3px solid #DA4453;
}

.inbox-notifications .improvement-card.lowEffectlowEffort {
    border: 2px solid #fcf8e3;
    border-bottom: 3px solid #FFCE54;
}

.inbox-notifications .selected-comment {
    border: 1px solid #0099DE;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.inbox-notifications .toast {
    background-color: #fbfbfb;
    border-color: #e7eaec;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    background-clip: padding-box;
    backdrop-filter: blur(10px);
    border-radius: .25rem;
    font-size: .875rem;
    
  }
  .inbox-notifications .toast.show {
    display: block;
    opacity: 1;
}
.inbox-notifications .toast .toast-header {
    font-size: 14px;
    display: flex;
    align-items: center;
    
    background-clip: padding-box;
    padding: .25rem .75rem;
    color: #6c757d;
    border-bottom: 1px solid rgba(0,0,0,.05);
  }
  .inbox-notifications .toast.toast-bootstrap .toast-body {
    background-color: #fbfbfb;
    font-size: 11px;
    padding: 10px;
    /* font-size: .775rem; */
  }




.card-shadow-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.card-shadow-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.card-shadow-2 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.card-shadow-2:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.skeleton-card {
    margin: auto;
		width: 500px;
		height: 150px; /* change height to see repeat-y behavior */
    
		background-image:
			radial-gradient( circle 50px at 50px 50px, lightgray 99%, transparent 0 ),
			linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 );

		background-repeat: repeat-y;

		background-size:
			100px 200px, /* circle */
			50px 200px, /* highlight */
			150px 200px,
			350px 200px,
			300px 200px,
			250px 200px;

		background-position:
			0 0, /* circle */
			0 0, /* highlight */
			120px 0,
			120px 40px,
			120px 80px,
			120px 120px;

		animation: shine 1s infinite;
	}

	@keyframes shine {
		to {
			background-position:
				0 0,
				100% 0, /* move highlight to right */
				120px 0,
				120px 40px,
				120px 80px,
				120px 120px;
		}
	}
