web-viewer {
    display: block;
    height: 100%;
    div.image-displayer {
        display: flex;
        flex-direction: column;
    }
    div.frame-wrapper {
        width: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    iframe.webcontent-frame {
        width: 100%;
        flex: 1 1 auto;
        border: 0;
    }
}
