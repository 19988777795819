.live-trend-component .realtime-value {   
    float: left;
}
.live-trend-component .realtime-value-change {
    font-size: 0.5em;
    margin-left: 6px;
    font-weight: 400;
    float: left;
}
.live-trend-component .realtime-value-change-positive {
    color: #8CC152;
}
.live-trend-component .realtime-value-change-negative {
    color: red;
}

.live-trend-value {
    padding: 1em;
}

.live-trend-component .graph-content {
    padding: 0 1em;
}