/* background */
.smiley-app-smiley-container {
    position: relative;
    min-height: 120px;
}
.smiley-app.smile {
    background: #EDF7E3;
}
.smiley-app.meh {
    background: #FFF6DF;
}
.smiley-app.frown {
    background: #FEE4DF;
}


 .smiley-app .neutral {
    color: #8CC152; /* set selected color */
}

/* 
 * The Smily:
 */

.smiley-app-smile {
    color: #8CC152; /* set selected color */
}

/* 
 * The meh
 */

 .smiley-app-meh {
    color: #FFCE54; /* set selected color */
}

/* 
 * The frown:
 */
.smiley-app-frown {
    color: #DA4453; /* set selected color */
}
.smiley-app-comment-title {
    font-size: 18px;
    font-weight: bold;
}
.smiley-app-comment-input {
    min-height: 120px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
}

  
  /* container */
  .smiley-app .container {
    padding: 5% 5%;
  }
  .smiley-app .speech-bubble {
    display: inline-block;
    position: absolute;
	background: lightyellow;
    border-radius: .4em;
    top: 10px;
    left: 9em;
    font-size: 0.9em; /* scalable */
    padding: 5px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.20);
}
.smiley-app .speech-bubble-text {
    text-align: left;
    width: 180px;
    max-width: 200px;
}
.smiley-app .speech-bubble-date {
    text-align: right;
    font-size: 0.9em; /* scalable */
}
.smiley-app .speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 22px solid transparent;
	border-right-color:lightyellow;
	border-left: 0;
	border-top: 0;
	margin-top: -11px;
    margin-left: -22px;
}