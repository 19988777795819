.a3-template-container-card {
    float: left;     
    width: 460px;
    height: 440px;
    max-height: 440px;
    /* background: red; */
    margin: 20px;
    padding: 5px;
    -webkit-box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);-moz-box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);
}
.a3-template-container-card .a3-template-image {
    max-width: 460px;
    width: 460px;
    height: 320px;
    max-height: 320px;
    /* overflow: hidden; */
}
.a3-template-container-card .template-info {
    margin: 10px;
}