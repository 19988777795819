.allowed-modules {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.3rem;
    max-width: fit-content;
}

.allowed-module-label {
    display: inline-block;
    padding: 4px 9px;
    margin-bottom: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    
}