
/* CUSTOM */
.asset-tree-node {
    background: #f8faff;
    border: 1px solid #dae2ea;
    color: #7c9eb2;
    padding: 10px 10px;
    margin-bottom: 3px;
}
.asset-tree-node:hover {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
}
/* .angular-ui-tree-handle {
    background: #f8faff;
    border: 1px solid #dae2ea;
    color: #7c9eb2;
    padding: 10px 10px;
}

.angular-ui-tree-handle:hover {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
} */

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

tr.angular-ui-tree-empty {
    height:100px
}

.group-title {
    background-color: #687074 !important;
    color: #FFF !important;
}


/* --- Tree --- */
.tree-node {
    border: 1px solid #dae2ea;
    background: #f8faff;
    color: #7c9eb2;
}

.nodrop {
    background-color: #f2dede;
}

.tree-node-content {
    margin: 10px;
}
.tree-handle {
    padding: 10px;
    background: #428bca;
    color: #FFF;
    margin-right: 10px;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.participants-businessunit .badge.badge-warning {
    background-color: var(--digilean-primary);
}