.task-list-result th.task-list-header {
    width: 150px;
    text-align: left;
}
.task-list-result th.task-list-header:hover {
    background: #f0f0f0;
}
/* Header styling */
.task-list-result th .sort-icon {
    margin-left: 20px;
    font-size: 14px;
}
.task-list-result th.status {
   text-align: center;
}
.task-list-result th.title {
    width: auto;
    min-width: 250px;
}
.task-list-result th.boardName {
    width: auto;
    min-width: 200px;
}
.task-list-result th.dueDate {
    text-align: center;
    width: 100px;
 }

 .task-list-result th.priorityStatus {
    text-align: center;
    width: 50px;
 }
/* Cell styling */
.task-list-result td.task-list-cell {
    
}
.task-list-result td.status {
    text-align: center;
}
.task-list-result td.title {
    color: #676a6c;
    font-weight: 600;
    font-size: 14px;
}
.task-list-result td.dueDate {
    text-align: center;
}
.task-list-result td.dueDate.over-due {
    /* background:rgb(251, 233, 243); */
    font-weight: 500;
    color: red;
    /* border-bottom: 1px solid red; */
}
.task-list-result td.priorityStatus {
    text-align: center;
}
.priority-status-box {
    display: inline-block ;
    height: 20px;
    width: 26px;
    border-radius: 3px;
}

.task-list .badge-white{
    border: 1px solid #e7eaec;
}