/* USERS */
#asset-tree .level-0 {
    font-weight: bold;
}

#asset-tree .level-1 {
    padding-left: 18px;
}

#asset-tree .level-2 {
    padding-left: 36px;
}

#asset-tree .level-3 {
    padding-left: 52px;
}

#asset-tree .level-4 {
    padding-left: 68px;
}

#asset-tree .level-5 {
    padding-left: 84px;
}

#adminuserlist {
    overflow-y: auto;
    max-height: 1000px;
}

.filter-active-admin {
    background:var(--digilean-template-item-selected);
}

.assetparticipants-modal-window .modal-dialog {
    width: 1200px;
    height: auto;
}

.assetselector-modal-window .modal-dialog {
    width: 500px;
    height: auto;
}
/* Data */
#datasource-form .form-group {
    margin-left: 0;
    margin-right: 0;
}

/* Logo admin */
.logo-container-image {
    position:relative;
    max-height: 300px;
     max-width: 600px;
    padding: 5px;
}

.admin-role-counter {
    font-size: 20px;
    margin-left: 6px;
    font-weight: 600;
}

.admin-role-widget {
    height: 125px;
    padding: 25px;
    background-color: var(--digilean-secondary-background);
}

.admin-header-new-button {
    float: right; 
    margin-top: -24px; 
    margin-right:-8px;
}
#datasourceTags .tagit-close {
    color: var(--digilean-primary-text);
}
#datasourceTags .tagit-choice-editable{
    font-size: 12px;;
}

/**
 * Nestable
 */

.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    max-width: 600px;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

    .dd-list .dd-list {
        padding-left: 30px;
    }

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
}

.dd-handle {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    background: #fafafa;
    background: -webkit-linear-gradient(to top, #fafafa 0%, #eee 100%);
    background: -moz-linear-gradient(to top, #fafafa 0%, #eee 100%);
    background: linear-gradient(to top, #fafafa 0%, #eee 100%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

    .dd-handle:hover {
        color: #2ea8e5;
        background: #fff;
    }

.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}

    .dd-item > button:before {
        content: '+';
        display: block;
        position: absolute;
        width: 100%;
        text-align: center;
        text-indent: 0;
    }

    .dd-item > button[data-action="collapse"]:before {
        content: '-';
    }

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

    .dd-dragel > .dd-item .dd-handle {
        margin-top: 0;
    }

    .dd-dragel .dd-handle {
        -webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
        box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
    }

/**
 * Nestable Extras
 */

.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}

#nestable-menu {
    padding: 0;
    margin: 20px 0;
}

#nestable-output,
#nestable2-output {
    width: 100%;
    height: 7em;
    font-size: 0.75em;
    line-height: 1.333333em;
    font-family: Consolas, monospace;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
    color: #fff;
    border: 1px solid #999;
    background: #bbb;
    background: -webkit-linear-gradient(to top, #bbb 0%, #999 100%);
    background: -moz-linear-gradient(to top, #bbb 0%, #999 100%);
    background: linear-gradient(to top, #bbb 0%, #999 100%);
}

    #nestable2 .dd-handle:hover {
        background: #bbb;
    }

#nestable2 .dd-item > button:before {
    color: #fff;
}

@media only screen and (min-width: 700px) {

    .dd {
        float: left;
        width: 100%;
    }

        .dd + .dd {
            margin-left: 2%;
        }
}

.dd-hover > .dd-handle {
    background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */

.dd3-content {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px 5px 40px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    background: #fafafa;
    background: -webkit-linear-gradient(to top, #fafafa 0%, #eee 100%);
    background: -moz-linear-gradient(to top, #fafafa 0%, #eee 100%);
    background: linear-gradient(to top, #fafafa 0%, #eee 100%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

    .dd3-content:hover {
        color: #2ea8e5;
        background: #fff;
    }

.dd-dragel > .dd3-item > .dd3-content {
    margin: 0;
}

.dd3-item > button {
    margin-left: 30px;
}

.dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #aaa;
    background: #ddd;
    background: -webkit-linear-gradient(to top, #ddd 0%, #bbb 100%);
    background: -moz-linear-gradient(to top, #ddd 0%, #bbb 100%);
    background: linear-gradient(to top, #ddd 0%, #bbb 100%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

    .dd3-handle:before {
        content: '≡';
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        width: 100%;
        text-align: center;
        text-indent: 0;
        color: #fff;
        font-size: 20px;
        font-weight: normal;
    }

    .dd3-handle:hover {
        background: #ddd;
    }

/**
 * Socialite
 */
.socialite {
    display: block;
    float: left;
    height: 35px;
}
#excelimport table{
    padding:20px;
}
#excelimport tr{
    padding:5px;
}
#excelimport td{
    padding-left:15px;
}

#excelimport .excel-data{
    max-height: 700px;
    overflow-y: auto;
}

#excelimport input[type=checkbox]{
    padding: 5px;
}

#excelimport input[type=text]{
    width:100px;
    margin:5px;
}

#excel-metadata input[type=text]{
    width: 100%;
}
#newjob .well-small {
    max-height:400px;
}
.admin-list-item {
    height:46px;
    border:2px solid #e7eaec;
    background-color: #fff;
    display:block;
    width: 100%;
    border-radius: 3px;
    margin-bottom:4px;
}
#deviation-configuration .step-content {
    height: auto;
    min-height: 560px;
    background: rgb(238, 238, 238);
    overflow-y: auto;
}

.license-type button {
    font-size: 1.3rem;
    margin-right: 1rem;
}
.license-type .selected-element {
    color: #FFF;
    background-color: #0075ff; /*#337ab7; */
}
.question-type-label label.fa-question-circle {
    margin-left: 10px;
    margin-bottom: 0px;
    font-size: 14px;
}
.css-1xxuftl-Box {
    padding: 16px 24px;
    height: 100%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(230, 230, 230);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 4px;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.admin-checkbok-medium{
    width:20px; 
    height:20px;
}
.admin-title-label {
    font-size: 14px;
    color: var(--digilean-primary-text);
    font-weight: 600;
}
span.lookup-button {
    margin-left: 1rem;
    cursor: pointer;
}
span.ad-group-name {
    font-size: 1.2rem;
}
i.remove-azure-group {
    margin-left: 1rem;
    color: red;
    cursor: pointer;
}
small.external-id {
    color: var(--digilean-disabled);
}

.admin-small-info-text {
    font-style:italic;
    font-size: 0.9em;
    color: var(--digilean-grey-default);
}
