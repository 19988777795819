.heart-animation-container {
    position: relative;
    /* float: left; */
    height: 16px;
    width: 16px;
    /* margin-right: 6px;
    margin-bottom: 6px; */
    cursor: pointer;
    /* background: red; */
}

.heart-animation-container .heart-animation {
    position: absolute;
    top: -68px;
    left: -66px;
    height: 150px;
    width: 150px;
}
