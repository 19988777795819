/* override boothstrap - should be moved to layout */
.dark-theme .table-hover tbody tr:hover td {
    background: var(--digilean-blue-night);
    color: white;
}
/* end bootstrap */
.three-c-table {
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
    height: 100%;
    display: block;
}
.three-c-table.is-stand-alone {
    margin: 15px;
}
.three-c-table table  {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background: var(--digilean-secondary-background);
}
.three-c-table table th {
    border: 1px solid #ddd;
    font-size: 12px;
    /* position: relative; */
    background: var(--digilean-tertiary-background);
    cursor: pointer;
    position: relative;
    position: -webkit-sticky;
    cursor: pointer;
    /* for Safari */
    position: sticky;
    top: 0;
    z-index: 999;
}
.three-c-table table th.is-sorted {
    background: var(--digilean-primary-background);
    border-bottom: 2px solid var(--digilean-blue-sharp);
}
.three-c-table table th.is-sorted .sort-arrow{
    color: var(--digilean-blue-sharp);
}
/* Columns */
.three-c-table table th.date-raised,.owner,.due-date {
    width: 100px;
}
.three-c-table table th.concern,.cause,.countermeasure {
    min-width: 180px;
    width: 25%;
}
.three-c-table table th.pdca {
    width: 80px;
}
.three-c-table table tr.row-added {
    background-color: var(--digilean-green-bright);
    transition: background-color 1s ease-out;
}
.three-c-table table tr.row-change {
    background-color: var(--digilean-blue-lighter);
    transition: background-color 1s ease-out;
}
.three-c-table table tr.row-deleted {
    background-color: var(--digilean-pink);
    transition: background-color 1s ease-out;
}
.three-c-table table td {
    /* background: #fefefe; */
    border-right: 1px solid var(--digilean-grey-light);
    font-size: 11px;
    height: 1px;
}
.three-c-table table th small {
    font-weight: normal;
    /* font-size: xx-small;
    color: red; */
}
.three-c-table table th small {
    font-weight: normal;
    /* font-size: xx-small;
    color: red; */
}
/* Cells */
.three-c-table table .three-c-text-cell {
    background: transparent;
    height: 100%;
    width: 100%;
}
.three-c-table table .three-c-text-cell:focus {
    background: var(--digilean-secondary-background);
    color: var(---digilean-primary-text);
    border: 1px solid var(--digilean-blue);
    min-height: 30px;
    padding: 5px;
}
.three-c-table table .three-c-date-cell .date-picker-slot {
    background: transparent;
    text-align: left;
    border: none;
    width: 40px;    
    cursor: pointer;

}
.three-c-table table .three-c-date-cell .date-picker-slot:focus {
    border: none;
}
.three-c-table table .three-c-owner-cell {
    text-align: center;
    display: flex;
    justify-content: center;
}
.three-c-table table .three-c-owner-cell .default-person, .owner-cell-dropdown .default-person {
    height: 30px;
    width: 30px;
    background: var(--digilean-grey-lightestx);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--digilean-grey);
    font-size: 18px;
    
}
.three-c-table table .three-c-owner-cell .display-name {
    font-size: 11px;
}
.three-c-table table .three-c-owner-cell img.owner-profile-image {
    height: 30px;
    width: 30px;
}

.owner-cell-dropdown img.owner-profile-image {
    height: 30px;
    width: 30px;
}

.three-c-table .options-button {
    width: 24px;
    height: 24px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
}


three-c-table {
    display: block;
    height: 100%;
    
}

three-c-table-app {
    display: block;
    height: 100%;
    div.ibox-content {
        height: 100%;
        padding-bottom: 10;
        padding-right: 10;
    }
}