
/*.as-sortable-dragging {
    max-width: 300px !important;
}*/

#improvementmatrix {
    background: red;
}

/*
.highEffectHighEffort {
    border: 1px solid #FFCE54;
    border: 1px solid #faebcc;
    background: #fcf8e3;
}*/

.highEffectHighEffort {
    border: 1px solid #008CC9;
    border: 2px solid #bce8f1;
    background: #d9edf7;
}

.highEffectLowEffort {
    border: 1px solid #8CC152;
    border: 2px solid #d6e9c6;
    background: #dff0d8;
}

.lowEffectHighEffort {
    border: 1px solid #DA4453;
    border: 2px solid #ebccd1;
    background: #f2dede;
}

.lowEffectlowEffort {
    border: 1px solid #FFCE54;
    border: 2px solid #faebcc;
    background: #fcf8e3;
}

ul.notes li {
    margin-bottom:30px;
    margin-right:40px;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 100px;
    width: 130px;
    padding: 0.4em;
    position: relative;
    word-wrap: break-word;
}

.btn-simpel {
    border-radius: 5px;
    font-size: 10px;
    line-height: 1.5;
    padding: 1px 2px 1px 5px;
    line-height: 1.13;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
}


.btn-simpel-white {
    background: white;
    border: 1px solid #e7eaec;
}

.agile-list li.highEffectHighEffort {
    border: 2px solid #d9edf7;
    border-bottom: 3px solid #008CC9;
}

.agile-list li.highEffectLowEffort {
    border: 2px solid #dff0d8;
    border-bottom: 3px solid #8CC152;   
}

.agile-list li.lowEffectHighEffort {
    border: 2px solid #f2dede;
    border-bottom: 3px solid #DA4453;
}

.agile-list li.lowEffectlowEffort {
    border: 2px solid #fcf8e3;
    border-bottom: 3px solid #FFCE54;
}

.tag-margin {
    margin-right: 12px;
}

.suggestionItem {
    margin: 0.5em;
    padding: 0.3em;
    background: white;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.10);
    height: auto;
    /*background: rgba(255,255,200, 0.9);*/
}

.suggestioncontainer {
    padding: 1em;
    height: 400px;
}

.prioritycontainer {
    overflow-y: auto;
}

#assesmentContainer .spacestyling {
    margin-top: 12px;
}

#activateContainer .spacestyling {
    margin-bottom: 30px;
}

#improvementsearch .spacestyling {
    margin-top: 20px;
}

#suggestionList .form-control {
    font-size: 11px;
}

#suggestionList h4 {
    font-size: 12px;
}

#suggestionList .comment-text {
    font-size: 11px;
}

.margin {
    margin-bottom: 6px;
}

#activateForm {
    margin-bottom: 30px;
}

#assesmentContainer .socialbuttons {
    display: none;
}

#assesmentContainer li {
    list-style-type: none;
    margin-left: 12px;
    margin-bottom: 12px;
}

#assesmentContainer ul {
    padding: 0px;
    margin: 12px;
}
/*
#assesmentContainer li:before {
    font-family: 'FontAwesome';
    content: '\f0eb';
    margin: 0 5px 0 -15px;
    font-size: 14px;
}*/

#suggestionActionList i {
    display: none;
}

#suggestionActionList .socialbuttons {
    display: none;
}

#suggestionActionList li {
    list-style-type: none;
    margin-left: 12px;
    margin-left: 1px;
}

    #suggestionActionList li:before {
        font-family: 'FontAwesome';
        content: '\f096';
        margin: 0 5px 0 -5px;
        font-size: 14px;
    }

#suggestionActionList i {
    display: none;
}

#suggestionActionList .small {
    display: none;
}

#improvementWheelfilter {
    float: right;
    margin-left: 15px;
    margin-top: -8px;
}

    #improvementWheelfilter .dropdown-menu {
        width: 300px;
    }

#improvementfilter {
    float: left;
    margin-left: 250px;
    margin-top: -8px;
}

    #improvementfilter .dropdown-menu {
        width: 300px;
    }

#improvementfilterresult {
    font-size: 10px;
    font-style: italic;
}

#improvementactivate {
    float: right;
    margin-right: 8px;
    margin-top: -8px;
}

#suggestionList {
    min-height: 600px;
    max-height: 910px;
    overflow-y: auto;
    background: var(--digilean-secondary-background);
    font-size: 13px;
}

#activateList {
    min-height: 132px;
    max-height: 200px;
    overflow-y: auto;
    background: #f3f3f4;
    font-size: 13px;
}

#activateList li{
    list-style-type: none;
    margin-left: 12px;
    margin-bottom: 12px;
    padding:10px;
}

.simple-suggestion {
    border: 0px;
    background: transparent;
    font-size: 12px;
}

#activateContainer .activateBox {
  height: 160px;
  background: #f3f3f4; 
  font-size: 13px;
  border: 2px solid #e7eaec; 
}
/*#activateContainer .activateBox {
    height: 124px;
    background: transparent;
    font-size: 13px;
    border: 2px solid silver;
    border-radius: 10px;
}*/



    #activateContainer .activateBox ul {
        height: inherit;
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 100px;
    }

    #activateContainer .activateBox li {
        list-style-type: none;

        margin-top: 12px;
        margin-left: 12px;
/*        
        margin: 0;
        margin-right: 12px;*/
        height: 100%;
        background: #0099DE;
        background: white;
        font-size: 9pt;
        /*color: white;*/
    }

    

#activateContainer .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

#improvementwheel {
    background: #fff;
}

.external-suggestion {
    border-left: 3px solid #0099DE;
    border-left: 3px solid #1ab394;
    padding-left: 9px;
}

.DigiLean {
    color: #2196D6;
}

.dotTolls {
    color: #9FC6E9;
}


#feedback textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.layouttextarea {
    min-height: 200px;
}

.editSuggestion-modal-window .modal-dialog {
    max-width: 800px;
    height: auto;
}

.fullSuggestion-modal-window .modal-dialog {
    max-width: 800px;
    height: auto;
}

#searchpopup #imageSpacing {
    margin-right: 12px;
    margin-bottom: 12px;
}

.improvement-modal-footer {
    padding: 20px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

    .improvement-modal-footer .btn + .btn {
        margin-bottom: 0;
        margin-left: 5px;
    }

    .improvement-modal-footer .btn-group .btn + .btn {
        margin-left: -1px;
    }

    .improvement-modal-footer .btn-block + .btn-block {
        margin-left: 0;
    }

#improvementSearchResult .element-title {
    font-size: 14px;
    color: #676a6c;
    font-weight: 600;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    max-width: 600px;
}

#improvementSearchResult .element-date {
    font-size: 9.35px;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}


/* 
 * The Smily:
 */

#evaluationstatus .disabled-smily {
    color: #e7eaec; /* set not selected color */
}

#evaluationstatus .checked-smily {
    color: #8CC152; /* set selected color */
}

/* 
 * The meh
 */

#evaluationstatus .disabled-meh {
    color: #e7eaec; /* set not selected color */
}

#evaluationstatus .checked-meh {
    color: #FFCE54; /* set selected color */
}

/* 
 * The frown:
 */

#evaluationstatus .disabled-frown {
    color: #e7eaec; /* set not selected color */
}

#evaluationstatus .checked-frown {
    color: #DA4453; /* set selected color */
}

#activateForm .row {
    margin-bottom: 10px;
}

#activateForm .fa-select {
    font-family: sans-serif, 'FontAwesome';
}

/* hinder unwanted route changes due to use of Bootstrap href in angular project, see https://angular-ui.github.io/bootstrap/ */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

@media (max-width: 576px) {
    .five-column {
        width: 100%;
    }
  }
  @media (min-width: 577px) and (max-width: 991px) {
    .five-column  {
        width: 50%;
    }
  }

  @media (min-width: 992px) {
    .five-column  {
        width: 20%;
    }
  }


  /*
  .thumbnail img {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      transform: scale(1.1);
    }*/

.ongoing-improvements ul.agile-list {
    /* background: #f0f0f0; */
    border: 1px solid #f0f0f0;
    background: #FAFAFA;
    padding: 5px;
    margin-bottom: 20px;
}
  
 li.improvement-card {
    /* border: 1px solid #f0f0f0; */
    position:relative;
    padding:0px;
    margin-bottom: 12px;
    word-wrap: break-word;    
    background:white;
    box-shadow: 1px 10px 23px 0px rgba(232,232,232,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    min-height: 60px;
 }
 li.improvement-card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
 

 .improvement-card img {
    max-height: 100px;
    width: 100%;
    height: 100px;
    object-fit: cover;
    border: none;
 }
.improvement-card h3.title {
    font-size: 14px;
}
 .improvement-card .date{
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
    background:#999999;
    width: 40px;
    height: 40px;
    padding: 5px 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #FFFFFF;
    font-weight: 700; /*ok*/
    text-align: center;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
 }


 .improvement-card .overdue{
    background: #e74c3c;
}

.post-content .noimage{
    margin-top:38px;;
}

 .improvement-card .date .day {
    font-size: 13px;
  }

  .improvement-card .date .month {
    font-size: 10px;
    text-transform: uppercase;
  }


  .improvement-card  .post-content  {
    padding: 10px 15px;
}

.improvement-card .sub_title {
    color: #e74c3c;
  }

  .improvement-card .post-meta{
    color: #999999;
    text-decoration: none;
}

     .date .day {
          font-size: 13px;
        }

    .date .month {
          font-size: 10px;
          text-transform: uppercase;
        }
      
        .post-module .thumbnail{
            border: none;
            position:relative;
            padding:0px;
            margin-bottom: 0px;
        }


        .post-module .thumbnail .date {
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 1;
            background: #e74c3c;
            width: 40px;
            height: 40px;
            padding: 5px 0;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            color: #FFFFFF;
            font-weight: 700; /*ok*/
            text-align: center;
            -webkti-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
           
        }

        .post-content  {
            padding: 10px 10px;
        }

        .sub_title {
            color: #e74c3c;
          }

        .post-meta{
            color: #999999;
            text-decoration: none;
        }

        .post-meta .info{
            margin-left: 6px;
        }

        .feed-actions-container {
            margin: 10px;
            display: grid;
            width: 100px;
            grid-template-columns: auto 30px auto 30px;
            grid-gap: 10px;
        }
        .feed-actions-container .comment-button:hover {
            color: #0099DE;
        }
        .feed-actions-container .like:hover {
            color: red;
        }

    
priority-matrix-app,
priority-matrix-app .priority-matrix-list {
    display: block;
    height: 100%;
    max-height: 100%;
}
priority-matrix-app .priority-matrix-content {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}