.strategy-gray .btn.btn-default {
    background-color: inherit;
    border: 1px solid #d2d2d2;
    color: inherit;
}

.strategy-gray .btn-default:hover,
.btn-default:focus,
.btn-default:active {
    color: inherit;
    background: white;
    border: 1px solid #e7eaec;
}

.ongoing-strategy ul.agile-list {
    /* background: #f0f0f0; */
    border: 1px solid #f0f0f0;
    background: #FAFAFA;
    padding: 5px;
    margin-bottom: 20px;
}


.strategy-column-name{
    border: 0px;
    border-bottom: 1px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%; /*calc(100% - 47px);*/
}

.project-board-table .shift-icon{
    position: absolute;
    top: 10;
    left: 0;
    z-index: 2;
}

.strategy-overdue {
    color: #e9573f
}

.strategy-on-target {
    background:#8cc153;
    color:white
}

.strategy-off-target {
    background:#e95840;
    color:white
}

.strategy-bowler-chart td .value-cell
{    
    width: 100%;
    height: 100%;
    padding: 8px;
}
.value-cell-toggle{
    height: 100%;
}

    .strategy-bowler-chart td {
        padding: 0px;
        min-height: 26px;
        height: 26px;
        }
        
table.bowler-chart .bowler-chart-value-cell{
    padding:0px;
}