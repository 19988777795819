.member-board-selector {
    width: 100%;
}
.member-board-selector button {
    width: 100%;
    text-align: left;
}
.member-board-selector .board-icon {
  margin-right: 12px;
}
.member-board-selector span.caret {
    font-size: 24px;
}
.member-board-selector .board-search-box {
    width:210px;
    height:26px; 
    margin: 10px; 
    margin-left: 30px; 
    margin-right: 5px;
}
.member-board-selector ul {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.member-board-selector li a{
    line-height: 1.5;
}
.member-board-selector .board-name {
    font-size: 14px;
    font-weight: 600;
}
.member-board-selector .asset-name {
    margin-left: 28px;
    font-size: 11px;
}
