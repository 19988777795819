.strategy-task-list-result th.improvement-list-header {
    width: 150px;
    text-align: left;
}
.strategy-task-list-result th.improvement-list-header:hover {
    background: #f0f0f0;
}
/* Header styling */
.strategy-task-list-result th .sort-icon {
    margin-left: 20px;
    font-size: 14px;
}
.strategy-task-list-result th.status {
   width: 100px;
   text-align:left;
}
.strategy-task-list-result th.title {
    width: auto;
    min-width: 250px;
}
.strategy-task-list-result th.goal {
    width: auto;
    min-width: 250px;
}
.strategy-task-list-result th.boardDate {
    text-align: center;
    width: 100px;
 }

.strategy-task-list-result td.status {
    text-align: left;
}
.strategy-task-list-result td.title {
    color: #676a6c;
    font-weight: 600;
    font-size: 14px;
}
.strategy-task-list-result td.boardDate.over-due {
    font-weight: 500;
    color: red;
}
