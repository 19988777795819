.weeklyboard #boardContainer {
    padding: 0 5px 5px 5px;
}
.weeklyboard .action-list-container {
    background: #f0f0f0;
}
.weeklyboard .actionlist {
    margin: 0 5px 5px 5px;
}
.weekselector  {
    /* padding-top: 15px; */
    max-width: 130px;
    margin: 0px;
}
.weekselector  .previous-period{
    width: 50px;
    text-align: right;
    padding: 6px;
    display: table-cell;
}
.weekselector  .period-content{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    display: table-cell;
}
.weekselector  .week-prefix{
    
    font-size: 14px;
    
}
.weekselector .next-period{
    width: 50px;
    text-align: left;
    padding: 6px;
    display: table-cell;
}

.weeklyboard .action-list-container.col-lg-2 {
    padding-right: 0;
}