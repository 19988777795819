.app-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 1rem;
    gap: 0.5rem;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
}
.app-header .app-tools {
    margin-top: 2rem;
    flex-basis: 1rem;
    flex-grow: 0;
    flex-shrink: 1;
}
.app-header i.app-icon {
    margin: 0.5rem 0 0 0.5rem;
    padding: 0.1em;
    font-size: 1.8em;
}

.app-header timeframe-selector {
    margin-top: 1rem;
    margin-right: 1rem;
}

.app-header h3 {
    font-size: 1.2em;
}
.app-content {
    border-color: var(--digilean-grey-fog);
    border-style: solid solid none;
    border-width: 1px 0;
}


.digilean-app-list-title {
    color: inherit;
    font-size: 1em;
    margin-bottom: 2px;
}

.app-tools .dropdown-menu-right .dropdown-toggle-button {
    color: var(--digilean-primary-text);
    font-size: 1.4em;
    margin-right: 1rem;
    padding: 0.1em 0.7em;
    text-align: center;
}
.app-tools .dropdown-menu-right .dropdown-toggle-button:hover {
    background: var(--digilean-grey-transparent);
    border-radius: 50%;
}

.graph-component {
    background-color: var(--digilean-secondary-background);
    display: flex;
    flex-direction: column;
    height: 100%;
}
.graph-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 0.5em;
}
.graph-content-inner {
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
}
