.actionList .calendar {
    margin-top: 10px;
}
.timeboard .content{display:table-row}
.timeboard .actions-container {
    padding: 6px;
}

.actionList .actions-container-table {
    margin: 12px;
    padding: 6px;
    /* background: red; */
    /* height: 20px; */
}

.fc-view
{
    width: 100%;
    overflow: visible;
}

.actionList .dropdown-menu > li > a {
    background: transparent;
}
.action-list-app .ibox-tools .btn {
    margin: 1rem 2rem 0.5rem 0.5rem;
}

action-list-app .simpel-tag-list {
    font-size: 0.8em;
}

action-list-app .app-header i.app-icon {
    margin: 0.5em 0 0 0.5em;
    padding: 0.5em;
    font-size: 1em;
}