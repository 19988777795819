

.form-control {
    background-color: var(--digilean-secondary-background);
    background-image: none;
    border: 1px solid var(--digilean-input-border);
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 1.4rem;
}

.form-control:focus {
    border-color: var(--digilean-blue-sharp) !important;
    outline: 0;
}
.form-control::placeholder {
    color: var(--digilean-grey-pigeon);
}
.suggestion-title {
    font-size: 14px;
    color: var(--digilean-primary-text);
    font-weight: 600;
}

feedback-status {
    display: block;
}

label.small-label {
    font-weight: 400;
}

.digilean-primary-link {
    color: var(--digilean-primary-link);
}

.dropdown-item-hover:hover {
    color: var(--digilean-text-on-dark);
    background: var(--digilean-primary-text);
    cursor: pointer;
}

.filter-button.active-filter {
    color:  var(--digilean-primary);
    
}

.export-pdf-button {
    color: var(--digilean-dark-red);
}
