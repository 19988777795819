.task-status-container {
    position: relative;
    float: left;
    height: 16px;
    width: 16px;
    margin-right: 6px;
    margin-bottom: 6px;
    cursor: pointer;
}

.task-status-container .ok-animation {
    position: absolute;
    top: -20px;
    left: -24px;
    height: 64px;
    width: 64px;
}

.task-status-container svg path[fill="rgb(0,0,0)"] {
    fill: white;
  }

.task-status-container .fa-square {
    color: #676a6c;
}

.task-status-container .fa-times-circle {
    margin-right: 2px;
    color: #FF0000;
}

.task-status-container .fa-square {
    margin-right: 2px;
    color: gray;
}