
.greencross-description-box {
    height: 35px;
    margin: 10px;
    font-size: 11px;
    padding: 6px;
}
.greencross-container {
    height: 0;
    padding-bottom: 50%;
    position: relative;
    /* min-height: 350px;
    min-width: 650px; */
}
.greencross-container .r-b-top-left {
    border-top-left-radius: 20px;
    border-radius: 20px;
    /* min-height: 350px;
    min-width: 650px; */
}
.greencross-container table {
    width: 95%;
    height: 100%;
    position: absolute;
    top: left;
    table-layout: fixed;
    border-spacing: 2px;
    border-collapse: separate;
}
/* .greencross-container td {
    padding: 3px;
} */
.greencross-item {
    min-height: 15px;
    min-width: 15px;
    text-align: center;
    padding: 0px;
    background:white;
    border-radius: 3px;
    font-size:11px;
    overflow: hidden;
    /*border: 1px solid #cccccc;*/
    height: 15px;
    
}

.greencross-item-cell-month {
    font-size: 9px;
    text-align: center;
    padding: 0;
    background: #f0f0f0;
    border-radius: 6px;
}
.greencross-item-cell-container {
    height: 100%;
}
td.greencross-item-cell {
    text-align: center;
    padding: 0;
    background: #f0f0f0;
    border-radius: 6px;
    /* margin: 2px; */
    /* border: 1px solid #cccccc; */
    /*border: 1px solid black;*/
}
div.greencross-item-cell {
    height: 100%;
    border-radius: 6px;
    /* margin: 2px; */
    /* border: 1px solid #cccccc; */
    /*border: 1px solid black;*/
}
div.greencross-item-month-status {
    border-radius: 0px;
    padding: 2px;
    /* border: 1px solid #cccccc; */
    /*border: 1px solid black;*/
}
.greencross-item-cell-area {
   height: inherit;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.greencross-border {
    border: 1px solid #cccccc;
}

.greencross-green {
    background: #8CC152;
    color: white;
}

    .greencross-green a {
        color: white;
    }

.greencross-blue {
    background: #008CC9;
    color: white;
}

    .greencross-blue a {
        color: white;
    }

.greencross-yellow {
    background: #FFCE54;
}

.greencross-red {
    background: #DA4453;
    color: white;
}

    .greencross-red a {
        color: white;
    }

.greencross-center {
    padding-left: 1px;
    padding-right: 5px;
    padding: 0px;
    font-weight: bold;
    text-align: center;
    font-size: 0.688em;
}

.green-cross-table {
    width: 600px;
}

.green-cross-table td {
    width: 9%;
}

/* kpi month selector */
kpi-month-selector .month-year-row {
    font-size: 1.1em;
}
kpi-month-selector .main-selector-row {
    display: flex;
    flex-direction: row;
    gap: 6%;
    width: 100%;
}
kpi-month-selector .main-selector-row .change-year-button {
    flex-basis: 4%;
    flex-grow: 0;
    flex-shrink: 0;
}
kpi-month-selector .main-selector-row .change-year-button button {
    font-size: 0.8em;
    margin: 0;
    height: 100%;
    width: 100%;
}
kpi-month-selector .main-selector-row  .month-selector-container {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
}
.center-month-selector {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.daily-kpi-drop.dropdown-menu > li > a {
    word-wrap: break-word;
    max-width:250px; 
    white-space: normal;
}

.id-name-viewmode {
    margin-right: 18px
}

div.identification {
    margin: 1rem 1rem 1rem 0;
}

div.identification span.scalable {
    font-size: 0.8em;
}