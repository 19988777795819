.project-grid-status {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 6px 12px;
    position: absolute;
    top: -32px;
    right: 0;
}
.project-grid .product-box.project-is-star {
    /* border: 3px solid gold; */
}
table.project-list .project-grid-status {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 6px 12px;
    position: relative;
    top: unset;
    right: unset;
}

.project-link-table {
    vertical-align:middle;
}

.project-link-table-icon{
    font-size:16px;
    margin-top:3px;
}

.project-link-table-col1{
    width:10px;
    vertical-align:middle;
}

#projectdetailsheadertable .table > tbody > tr:first-child > td {
    border-top:none;
}

#project .milestone-completed {
    color: #fff;
    background-color: #0099DE;
    border-color: #a2c6e5;
    
    color: #fff;
    background-color: #8cc152;
    border-color: #8cc152;
}

#project .milestone-missed {
    color:#d9534f
}


#project .milestone-completed-filled {
    /*color:#5cb85c*/
    color:#659234 /*#8cc152*/
}

#project .milestone-future {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc;
}

#project .milestone-future-color {
    color: #292b2c;
}

#project .list-group-item {
    position: relative;
    display: block;
    padding-right: 0px;
    margin-bottom: -1px;
    list-style: none;
    border: none;
  }
#project .list-group {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .project-admin .project-customer{
    color:#676a6c;
    font-size: 14px;
}
.project-message-wrapper {
    /* position: relative; */
}
.project-message {
    background: #ffc; 
    text-decoration: none;
    color: #000;
    background:rgb(223, 243, 251);
    display: block;
    min-height: 50px;
    /* width: 210px; */
    max-width: 360px;
    padding: 1em;
    box-shadow: 3px 3px 5px rgba(33, 33, 33, 0.7);
    -moz-transition: -moz-transform 0.15s linear;
    -o-transition: -o-transform 0.15s linear;
    -webkit-transition: -webkit-transform 0.15s linear;
}
.project-message .project-message-body {
    font-size: 14px;
}


/* --------------------------------- */
/* --------- Stats style ------------ */
/* -------------------------------- */
.customer-stats .heat-map-value {
    font-weight: bold;
    text-align: center;
}

.customer-stats .level-1 {
    background:#EDF7E3;
    border: 1px solid rgb(113, 194, 27);
}
.customer-stats .level-2 {
    background:rgb(225, 243, 206);
    border: 1px solid rgb(113, 194, 27);
}
.customer-stats .level-3 {
    background:rgb(206, 241, 170);
    border: 1px solid rgb(113, 194, 27);
}
.customer-stats .level-4 {
    background:rgb(113, 194, 27);
    border: 1px solid#EDF7E3;
    color: white;
}
.customer-stats .level-5 {
    background:#3BAFDA;
    border: 1px solid rgb(113, 194, 27);
}