.a3-preview-step li{
    list-style-type: initial;
}

#A3Overview #A3result-ibox-content {
    min-height: 350px;
}

#a3SearchResult .label-style {
    margin-bottom: 6px;
    display:inline-block;
}

#a3SearchResultHeader .dropdown-heading-style {
    border-top: 1px solid #ddd;
}

#a3SearchResultHeader .delete-styling {
    margin-right: 30px;
}

#a3Actionlist a {
    color: #676C6A;
}

.smart-action-list .ibox-tools .dropdown-menu {
    color: #676C6A;
}

#a3report .milestonemissed {
    color:#d9534f;
}

    #a3report .table-big-text {
    font-size: 14px;
    color: #676a6c;
}
#a3report .table-big-text-bold {
    font-size: 14px;
    color: #676a6c;
    font-weight: 600;
}
.digilean-milestone-button {
    padding-left:14px; 
    padding-right:14px;
    padding-top:2px; 
    padding-bottom:2px;
    margin-top:-6px;
}

#a3report .milestone-completed {
    color: #fff;
    background-color: #0099DE;
    border-color: #a2c6e5;
}

#a3report .milestone-missed {
    color:#d9534f
}



#a3report .milestone-future {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc;
}


#a3Designer .note-editing-area .panel-body {
    overflow-y: auto;
    height: 248px;
}


#A3document .ibox-content{
    padding: unset;
}


.search-tag-list li {
    float: left;
}

    .search-tag-list li a {
        padding: 5px 12px;
        margin-right: 5px;
        margin-top: 5px;
        display: block;
    }

.a3-filter .selected-element {
    color: #fff;
    background-color: #63a0d4;
    border-color: #4f93ce;
        background-color: #337ab7;
    border-color: #337ab7;
}

.a3Plan {
    border: 1px solid #434348;
    border: 1px solid #d7d7da;
    background: #f6f6f6;
}

.a3Progress {
    border: 1px solid #0099de;
    border: 1px solid #b7e9ff;
    background: #f2fbff;
}

.a3Implemented {
    border: 1px solid #23c6c8;
    border: 1px solid #bcf3f3;
    background: #ddf9f9;
}

#a3SearchResultHeader .status {
    height:18px;
    width:10px;
}

#a3SearchResultHeader .new {
    color: #d1dade;
    background-color: #d1dade;
}

#a3SearchResultHeader .plan {
    color: #434348;
    background-color: #434348;
} 

#a3SearchResultHeader .do {
    color: #0099DE;
    background-color: #0099DE;
} 

#a3SearchResultHeader .check {
    color: #23c6c8;
    background-color: #23c6c8;
}

#a3SearchResultHeader .act {
    color: #f8ac59;
    background-color: #f8ac59;
} 

.a3Evaluated {
    border: 1px solid #f8ac59;
    border: 1px solid #fcdbb7;
    background: #fef7f0;
}

#A3Wizard .previewbutton {
    color: rgb(103, 106, 108);
    cursor: pointer;
    font-size: 12px;
}
#A3Wizard .ibox-title{
    min-height: 52px;
}
#A3Wizard .button-row{
    display:flex; 
    flex-direction: row;
    float: right;
    height: 30px;
}
#A3Wizard .row-button{
    margin:5px;
    margin-right: 20px;
    margin-top: 0px;
}

#A3Wizard .row-button-view{
    margin:5px;
    margin-right: 20px;
    margin-top: -8px;
    height: 34px;
}

#A3Wizard .approve-panel{
    
    margin-right: 80px;
    float:right;
}

#A3Wizard .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
}

#a3Designer .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
}


#a3step-wizard ul.steps-list li {

        display: inline-block;
        float: left;
        min-width: 100px;
        margin-right: 6px;
        margin-top: 3px;
        background: #2f4050; 
        color: #fff;
        font-size: 12px;
        min-height: 25px;
        width: 150px;
        margin: 6px;
        border-radius: 5px;
        padding: 6px;
        cursor: pointer;
}

    #a3step-wizard ul.steps-list li.disabled {
        background: var(--digilean-white-wash);
        color: var(--digilean-grey-andesite);
        opacity: 0.6;
    }
    #a3step-wizard ul.steps-list li.step-done {
        background: var(--digilean-wizard-unselected);
        color: var(--digilean-text-on-dark);
    }


    #a3step-wizard .step-title {
        margin-top: 10px;
    }

    #a3step-wizard .step-approved-icon {
        color:white;
        background-color: transparent;
        margin-left: 12px;
    }

    #a3step-wizard .step-content {
        background: var(--digilean-secondary-background);
        height: 620px;
        overflow-y: hidden;
        border: none;
        /* border: 10px solid rgb(238, 238, 238); */
        /* border-radius: 5px; */
    }

    #a3step-wizard .fr-element{
        font-size: 14px;
    }

    

    #a3step-wizard .fr-box.fr-basic .fr-element {
        height: 537px;
    }
    #action-list-step .fr-box.fr-basic .fr-element {
        height: 200px;
    }

    #a3step-wizard .action-list{
        height: 290px;
    }

    #a3step-wizard .actions .a3step {
        width: 70px;
        text-align: center;
    }


    #a3step-wizard .note-editor .note-toolbar {
        background-color: #f5f5f5;
    }

    #a3step-wizard .A3stepbutton {
        float: right;
        margin-right: 12px;
        margin-top: 6px;
    }

    #a3step-wizard .approvedstepbutton {
        color: #1ab394;
        margin-top: 6px;
        margin-right: 19px;
    }

    #a3step-wizard .newbutton {
        float: right;
        margin-right: 19px;
        margin-top: 6px;
    }

    #a3step-wizard .chat-count {
        outline: none;
        border: none;
    }

    #a3step-wizard .stepDescription{
        outline: none;
        border: none;    
    }

#a3metadata #newVersionButton {
    float: right;
    margin-right: 0px;
}


#a3SearchResultHeader #deleteButton {
    width: 22px;
    padding-left: 5px;
}



.adjustToIbox {
    padding-bottom: 6px;
    padding-top: 6px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
/*
.borderless td, .borderless th {
    border: none;
}

#headertable .table > tbody > tr:first-child > td {
    border: none;
}*/


#a3preview .ibox-content {
    margin: 3px;
    border: 1px solid #e7eaec;
}



#a3step-wizard .form-group {
    margin-bottom: 0px;
}



/*#a3step-wizard .step-content {
    background: #eee;
    display: block;
    margin: 5px 5px 10px 5px;
    min-height: 120px;
    overflow: hidden;
    position: relative;
    width: auto;
}*/

#a3step-wizard > .steps .done a,
#a3step-wizard > .steps .done a:hover,
#a3step-wizard > .steps .done a:active {
    background: #6fd1bd;
    color: #fff;
}

#a3step-wizard .col-lg-10 {
    padding: 0px;
}

#a3Actionlist {
    /* width: 660px; */
    padding-left: 20px;
    overflow-y: auto;
}

#action-list-step #a3Actionlist{
    height: 320px;
    width: unset;
    margin-top: 5px;
    padding-left: 20px;
    /* overflow-y: auto; */
    border: 1px solid lightgray;
}
    #a3Actionlist .header {
        margin-left: 6px;
    }

    #a3Actionlist .fa-check-square {
        margin-right: 2px;
        color: #23c6c8;
    }

    #a3Actionlist .fa-times-circle {
        color: var(--digilean-danger);
    }
    /* .a3Actionlist input {
        border: none;
        background: transparent;
    }

    .a3Actionlist .input-date {
        width: 70px;
    } */

    #a3Actionlist .input-x-lg {
        width: 400px;
    }

    #a3Actionlist .input-2x-lg {
        width: 600px;
    }

    #a3Actionlist textarea {
        min-height: 12px;
        max-height: 100px;

        resize: none;
        outline: none;
        overflow: hidden;
        border:0;
    }

    #a3Actionlist textarea:focus {
        border : 2px solid #85BFFD !important;
    }

    #a3Actionlist .input-s {
        width: 60px;
    }

    #a3Actionlist .closeButton {
        float: right;
        margin-right: 0px;
    }


    
.makeActionListItem {
    padding: 0px 6px 0px 6px;
    border: none;
    background: none;
    margin-bottom: -6px;
}
/* CHAT */
.a3-small-chat-box {
    background: #fff;
    border: 1px solid #e7eaec;
    height: 100%;
    /*width: 230px;
    height: 320px;*/
    border-radius: 4px;
}

    .a3-small-chat-box.ng-small-chat {
        display: block;
    }

.body-small .a3-small-chat-box {
    bottom: 70px;
    right: 20px;
}

.a3-small-chat-box.active {
    display: block;
}

.a3-small-chat-box .heading {
    background: #f5f5f5;
    padding: 8px 15px;
    height: 55px;
    font-weight: bold;
    color: darkgrey;
    border-top:  10px solid rgb(238, 238, 238);
}

.a3-small-chat-box .chat-date {
    opacity: 0.6;
    font-size: 10px;
    font-weight: normal;
}

.a3-small-chat-box .content {
    padding: 15px 15px;
}

    .a3-small-chat-box .content .author-name {
        font-weight: bold;
        margin-bottom: 3px;
        font-size: 11px;
    }

    .a3-small-chat-box .content > div {
        padding-bottom: 20px;
    }

    .a3-small-chat-box .content .chat-message {
        padding: 5px 10px;
        border-radius: 6px;
        font-size: 11px;
        line-height: 14px;
        max-width: 80%;
        background: #f3f3f4;
        margin-bottom: 10px;
    }

        .a3-small-chat-box .content .chat-message.active {
            background: silver;
            color: #fff;
        }

    .a3-small-chat-box .content .left {
        text-align: left;
        clear: both;
    }

        .a3-small-chat-box .content .left .chat-message {
            float: left;
        }

    .a3-small-chat-box .content .right {
        text-align: right;
        clear: both;
    }

        .a3-small-chat-box .content .right .chat-message {
            float: right;
        }

.a3-small-chat-box .form-chat {
    padding: 10px 10px;
}

.chat-count {
    position: relative;
    height: 45px;
    width: 30px;
    display: block;
}
.chat-count .badge {
    position: absolute;
    top: -6px;
    right: -5px;
}

.a3-small-chat-box .content {
    height: 486px;
    overflow-y: auto;
}

.panel-default>.panel-heading .badge {
    background-color: #f8ac59;
}

#a3step-wizard .alert-warning {
    color: #8a6d3b;
    background-color: #FFCE54;
    background-color: #faebcc;
    
    
    border-color: #f8ac59;
    padding: 6px;
    padding-left: 15px;
    font-size: 12px;
}
/*
#a3step-wizard .alert-warning {
    background-color: #f8ac59;
    border-color: #f8ac59;
    color: #FFFFFF;
}*/


.three-dot-loading-animation span {
      background: #8a6d3b;
    min-width: 6px;
    min-height: 6px;
    border-radius: 6px;
    padding: 0px;
    margin-left: 6px;
    margin-top: 2px;
    height: 6px;
    width: 6px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: opacity;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
}


#cost td{
    padding-right : 20px;
}

#cost input{
    background: white;
    cursor: default;
}
