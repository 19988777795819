customer-lookup {
    display: block;
}

customer-lookup #customer-lookup {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

customer-lookup .customer-lookup-btn {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}
customer-lookup .customer-dropdown-menu {
    display: none;
}
customer-lookup .customer-dropdown-menu.open {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
customer-lookup .customer-dropdown-menu ul {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}
customer-lookup .customer-dropdown-menu ul li {
    list-style-type: none;
}
customer-lookup .customer-dropdown-menu ul li:hover {
    background: var(--digilean-blue-dark);
    color: var(--digilean-white);
}

customer-lookup digilean-input {
    width: 100%;
}