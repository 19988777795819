.deviation-list-result th.deviation-list-header {
    width: 150px;
    text-align: left;
}
.deviation-list-result th.deviation-list-header:hover {
    background: #f0f0f0;
}
/* Header styling */
.deviation-list-result th .sort-icon {
    margin-left: 20px;
    font-size: 14px;
}
.deviation-list-result th.status {
   text-align: center;
}
.deviation-list-result th.title {
    width: auto;
}
.deviation-list-result th.dueDate {
    text-align: center;
    width: 100px;
 }
 .deviation-list-result th.priorityStatus {
    text-align: center;
    width: 50px;
 }
/* Cell styling */
.deviation-list-result td.deviation-list-cell {
    
}
.deviation-list-result td.status {
    text-align: center;
}
.deviation-list-result td.title {
    color: #676a6c;
    font-weight: 600;
    font-size: 14px;
}
.deviation-list-result td.title a {
    color: #676a6c;
    font-weight: 600;
    font-size: 12px;
}
.deviation-list-result td.dueDate {
    text-align: center;
}
.deviation-list-result td.boardDate.over-due a .date-selector-button{
    font-weight: 500;
    color: red;
}
.deviation-list-result td.dueDate.over-due {
    /* background:rgb(251, 233, 243); */
    font-weight: 500;
    color: red;
    /* border-bottom: 1px solid red; */
}
.deviation-list-result td.severity {
    text-align: center;
}
.severity-status-box {
    display: inline-block ;
    height: 20px;
    width: 26px;
    border-radius: 3px;
}
.severity-status-box.not-set-severity {
    border: 2px solid #f0f0f0;
    background: white;
}
.severity-status-box.low-severity {
    border: 2px solid #abeff0;
    background: #ddf9f9;
}

.severity-status-box.high-severity {
    border: 2px solid #ebccd1;
    background: #f2dede;
}

.severity-status-box.medium-severity {
    border: 2px solid #faebcc;
    background: #fcf8e3;
}

.deviation-list .badge-white{
    border: 1px solid #e7eaec;
}