flexiboard-page-container {
    display: block;
    min-width: 1024px;
}

flexiboard-page-container .grid-stack-item {
    overflow: hidden;
}
flexiboard-page-container #digilean-grid-stack-container #digilean-grid-stack .grid-stack-item-content {
    overflow-y: hidden;
    padding: 5px;
    text-align: left;
}
/* flexiboard-page-container #digilean-grid-stack-container {
    margin-bottom: 3rem;
} */
/* use .is-landscape-mode if not want to show for default mode */
#flexiboard .is-admin-mode flexiboard-page-container #digilean-grid-stack {
    background-color: var(--digilean-grey-light);
    background-size: calc(100%/40) calc(100%/var(--gs-current-row, 30));
    background-image:
        linear-gradient(to right, #FFF 1px, transparent 1px),
        linear-gradient(to bottom, #fff 1px, transparent 1px);
}

/* Display line number per 10*/
flexiboard-page-container .grid-marking-line {
    display: none;
}
#flexiboard .is-admin-mode flexiboard-page-container .grid-marking-line {
    display: inline-block;
    position: absolute;
    top: calc(((100%/var(--gs-current-row, 30))*var(--grid-marking-row, 10)) - 1.3rem);
    left: -2rem;
    z-index: 10000;
}

/* hide resizable arrows */
flexiboard-page-container #digilean-grid-stack-container {
    .grid-stack-item>.ui-resizable-nw {
        background: none;
    }
    .grid-stack-item>.ui-resizable-se {
        background: none;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 0;
        border-color: transparent transparent var(--digilean-grey-stone) transparent;
        right: 11px;
        bottom: 11px;
        transform: rotate(270deg);
    }
    .grid-stack-item>.ui-resizable-sw {
        background: none;
    }
}

.grid-stack-item-content {
    text-align: center;
}

#digilean-grid-stack .digilean-widget-menu .icon-button-position {
    margin-top: -6px;
    margin-right: 0;
}

#digilean-grid-stack {
    .digilean-widget-menu {
        display: none;
    }
    .digilean-widget-menu.show {
        display: block;
    }
}

#digilean-grid-stack .widget-fullscreen-button {
    right: 0.6rem;
}
/*
 CSS for gridster with 40 columns - DigiLEAN

 this style is directly bound to the column setting in gridstack

  1 - 2.5% */
#digilean-grid-stack > .grid-stack-item {
    width: 2.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="1"] {
    left: 2.5%;
}
/* 2 - 5% */
#digilean-grid-stack > .grid-stack-item[gs-w="2"] {
    width: 5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="2"] {
    left: 5%;
}
/* 3 - 7.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="3"] {
    width: 7.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="3"] {
    left: 7.5%;
}
/* 4 - 10% */
#digilean-grid-stack > .grid-stack-item[gs-w="4"] {
    width: 10%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="4"] {
    left: 10%;
}
/* 5 - 12.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="5"] {
    width: 12.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="5"] {
    left: 12.5%;
}
/* 6 - 15% */
#digilean-grid-stack > .grid-stack-item[gs-w="6"] {
    width: 15%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="6"] {
    left: 15%;
}
/* 7 - 17.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="7"] {
    width: 17.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="7"] {
    left: 17.5%;
}
/* 8 - 20%*/
#digilean-grid-stack > .grid-stack-item[gs-w="8"] {
    width: 20%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="8"] {
    left: 20%;
}
/* 9 - 22.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="9"] {
    width: 22.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="9"] {
    left: 22.5%;
}
/* 10 - 25% */
#digilean-grid-stack > .grid-stack-item[gs-w="10"] {
    width: 25%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="10"] {
    left: 25%;
}
/* 11 - 27.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="11"] {
    width: 27.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="11"] {
    left: 27.5%;
}
/* 12 - 30% */
#digilean-grid-stack > .grid-stack-item[gs-w="12"] {
    width: 30%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="12"] {
    left: 30%;
}
/* 13 - 32.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="13"] {
    width: 32.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="13"] {
    left: 32.5%;
}
/* 14 - 35% */
#digilean-grid-stack > .grid-stack-item[gs-w="14"] {
    width: 35%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="14"] {
    left: 35%;
}
/* 15 - 37.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="15"] {
    width: 37.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="15"] {
    left: 37.5%;
}
/* 16 - 40% */
#digilean-grid-stack > .grid-stack-item[gs-w="16"] {
    width: 40%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="16"] {
    left: 40%;
}
/* 17 - 42.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="17"] {
    width: 42.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="17"] {
    left: 42.5%;
}
/* 18 - 45% */
#digilean-grid-stack > .grid-stack-item[gs-w="18"] {
    width: 45%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="18"] {
    left: 45%;
}
/* 19 - 47.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="19"] {
    width: 47.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="19"] {
    left: 47.5%;
}
/* 20 - 50% */
#digilean-grid-stack > .grid-stack-item[gs-w="20"] {
    width: 50%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="20"] {
    left: 50%;
}
/* 21 - 52.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="21"] {
    width: 52.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="21"] {
    left: 52.5%;
}
/* 22 - 55% */
#digilean-grid-stack > .grid-stack-item[gs-w="22"] {
    width: 55%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="22"] {
    left: 55%;
}
/* 23 - 57.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="23"] {
    width: 57.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="23"] {
    left: 57.5%;
}
/* 24 - 60% */
#digilean-grid-stack > .grid-stack-item[gs-w="24"] {
    width: 60%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="24"] {
    left: 60%;
}
/* 25 - 62.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="25"] {
    width: 62.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="25"] {
    left: 62.5%;
}
/* 26 - 65% */
#digilean-grid-stack > .grid-stack-item[gs-w="26"] {
    width: 65%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="26"] {
    left: 65%;
}
/* 27 - 67.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="27"] {
    width: 67.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="27"] {
    left: 67.5%;
}
/* 28 - 70% */
#digilean-grid-stack > .grid-stack-item[gs-w="28"] {
    width: 70%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="28"] {
    left: 70%;
}
/* 29 - 72.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="29"] {
    width: 72.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="29"] {
    left: 72.5%;
}
/* 30 - 75% */
#digilean-grid-stack > .grid-stack-item[gs-w="30"] {
    width: 75%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="30"] {
    left: 75%;
}
/* 31 - 77.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="31"] {
    width: 77.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="31"] {
    left: 77.5%;
}
/* 32 - 80% */
#digilean-grid-stack > .grid-stack-item[gs-w="32"] {
    width: 80%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="32"] {
    left: 80%;
}
/* 33 - 82.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="33"] {
    width: 82.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="33"] {
    left: 82.5%;
}
/* 34 - 85% */
#digilean-grid-stack > .grid-stack-item[gs-w="34"] {
    width: 85%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="34"] {
    left: 85%;
}
/* 35 - 87.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="35"] {
    width: 87.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="35"] {
    left: 87.5%;
}
/* 36 - 90% */
#digilean-grid-stack > .grid-stack-item[gs-w="36"] {
    width: 90%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="36"] {
    left: 90%;
}
/* 37 - 92.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="37"] {
    width: 92.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="37"] {
    left: 92.5%;
}
/* 38 - 95% */
#digilean-grid-stack > .grid-stack-item[gs-w="38"] {
    width: 95%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="38"] {
    left: 95%;
}
/* 39 - 97.5% */
#digilean-grid-stack > .grid-stack-item[gs-w="39"] {
    width: 97.5%;
}
#digilean-grid-stack > .grid-stack-item[gs-x="39"] {
    left: 97.5%;
}
/* 40 - 100% */
#digilean-grid-stack > .grid-stack-item[gs-w="40"] {
    width: 100%;
}
