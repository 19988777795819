.kanban-board #boardContainer {
    padding: 0 5px 5px 5px ;
}
.kanban-board .action-list-container {
    background: var(--digilean-white-almost);
}
.kanban-board .actionlist {
    margin: 0 5px 5px 5px;
}

.kanban-board .action-list-container.col-lg-2 {
    padding-right: 0;
}