#a3Designer .delete-category {
    color: red;
    background: white;
    /*border: 1px solid silver;*/
    text-align: center;
    height: 16px;
    width: 16px;
    float: right;
    margin-top: -6px;
    margin-right: -6px;
}

    #a3Designer .delete-category span {
        color: red;
    }

    #a3Designer  .step-content {
        background: #fff;
        height: 600px;
        /* overflow-y: hidden; */
        border: none;
        /* border: 10px solid rgb(238, 238, 238); */
        /* border-radius: 5px; */
    }

    #a3Designer .fr-box.fr-basic .fr-element {
        height: 537px;
    }


#a3Designer .add-column-button {
    float: right;
    margin: 12px;
    margin-right: 18px;
}

#a3step .gridster-item{
    font-size: 14px;
}

#a3steps-container {
    /*display: inline-block;*/
    /*height: 40px;*/
    float: left;
    margin-left: 15px;
}

    #a3steps-container .as-sortable-item {
        display: inline-block;
        float: left;
        min-width: 100px;
        margin-right: 6px;
        margin-top: 3px;
        background: rgb(238,238,238);
        color: rgb(170,170,170);
        font-size: 11px;
        min-height: 45px;
        width: 140px;
        margin: 6px;
        border-radius: 5px;
        padding: 6px;
        cursor: pointer;
    }

      #a3steps-container .selected-step {
        color: white;
        background: #1ab394;
    }

    #a3steps-container .as-sortable-item-handle {
        /*height: inherit;
        max-height: 50px;
        padding: 6px;
        background: silver;
        color: white;*/
    }

    #a3steps-container .as-sortable-placeholder {
        display: inline-block;
        max-height: 50px;
        min-height: 20px;
        float: left;
    }

    #a3steps-container .sortable-row:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    #a3-step-content-designer {
        margin: 6px;
    }

    .a3info-container {
        max-width: 94%;
        margin-left: 70px;
        margin-top: 50px;
        min-height: 150px;
        margin-bottom: -20px;
    }

    #a3info{
        min-height:150px;
        font-size: 14px;
    }