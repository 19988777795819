deviation-column-result {
    flex: 1 1 auto;
}

deviation-column {
    flex: 1;
}

deviation-column .sortable-list {
    min-height: 500px;
}

deviation-column ul.ongoing-draggable,
deviation-column div.deviation-column-wrapper {
    height: 100%;
}

deviation-column ul.ongoing-draggable {
    background-color: var(--digilean-white-bright);
    padding: 5px;
}

deviation-column ul.ongoing-draggable.hover {
    background-color: var(--digilean-primary-background);
}

deviation-column-result .filter-indicator {
    color: var(--digilean-important);
    margin-left: 12px;
}

div.deviation-ongoing-list {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

div.deviation-ongoing-list .result-box-outer {
    box-sizing: border-box;
    height: 100%;
}

div.deviation-ongoing-list .result-box-outer.is-app-context {
    box-sizing: border-box;
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
}

.float-e-margins .ongoing-deviation-container {
    font-size: 1.1rem;
}