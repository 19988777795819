
/*Override default Highcharts styles */

/* .highcharts-grid-line {
    fill: none;
    stroke: #e6e6e6; 
}   */
/* vertical grid lines */
.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 1; 
    /* stroke: #e6e6e6;  Farge på strek */
} 

/* Horizontal grid lines */
.highcharts-yaxis-grid .highcharts-grid-line {
    stroke-width: 1; 
    /* stroke: #e6e6e6;  Farge på strek */
} 
.highcharts-axis-line {
    stroke-width: 0; 
}