/*#dataserieselector .as-sortable-item {
    height: 40px;
    width: 600px;
    margin: 12px;
    padding: 6px;
    background: red;
}*/

#dataserieselector .as-sortable-item-handle {
    
}

#dataserieselector .as-sortable-placeholder {
    /*max-height: 50px;*/
    /*width: 600px;
    background: red;*/
    min-height: 20px;

}

#dataserieselector .as-sortable-drag {
    opacity: 1;
    width: 600px;
}

.icon {
    margin-left: 6px;
}

.radio-button {
    vertical-align: sub;
}
.as-sortable-dragging {
    /*min-width: 800px;
    max-width: 800px;
    width: 800px;
    padding: 0;*/
    /*background: white;*/
    /*background: red;*/

}

