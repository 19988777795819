/* 
The smartlist is based on Project-board styles. Only add or override default styles to avoid duplication 
*/
smart-action-list-app h2{
    font-size: 2.4em;
}

/* .smart-action-list-table  {
    table-layout: fixed;
} */

/* .smart-action-list .btn {
    font-size: 1.4em;
} */

smart-action-list-app .smart-action-list .ibox-tools .btn-group .btn {
    font-size: 1.4rem;
}

smart-action-list-app .smart-action-list select.form-control {
    font-size: 1.4rem;
}

smart-action-list-app .smart-action-list input.form-control {
    font-size: 1.4rem;
}

smart-action-list-app .smart-action-list .form-control,
smart-action-list-app .smart-action-list .single-line {
    font-size: 1.4em;
}

smart-action-list-app .smart-action-list .project-board-table td, 
smart-action-list-app .smart-action-list .project-board-table th,
smart-action-list-app .smart-action-list .yearly-board-table th {
    font-size: 1.2em;
}

smart-action-list-app .smart-action-list .task-row-table .task {
    font-size: 1.3em;
}

smart-action-list-app .yearly-board-container .row-category {
    font-size: 1.2em;
}

.smart-action-list-table th {
    width: 100px;
    height: 40px;
    background-color: var(--digilean-secondary-background);
    padding: 6px;
    text-align: center;
    font-size: 12px;
    position: relative;
    position: -webkit-sticky;
    cursor: pointer;
    /* for Safari */
    position: sticky;
    top: 0;
    z-index: 999;
}
.smart-action-list-table th.is-sorted {
    background: var(--digilean-cell-contrast);
    border-bottom: 2px solid #0099DE;
}
.smart-action-list-table thead th:first-child {
    left: 0;
    z-index: 998;
  }
  .smart-action-list-table tbody td:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 997;
  }
.smart-action-list-table th.title {
    width: 300px;
    border-bottom: 1px solid var(--digilean-cell-contrast);
    min-width: 200px;
}
.smart-action-list-table th.progress {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}
.smart-action-list-table input.project-name{
    width: calc(100% - 20px);
}
.smart-action-list-table .default-labels {
    min-width: 200px;
 
}
.smart-action-list-table th.date {
    width: 75px;
    min-width: 75px;
    max-width: 75px;
}

.smart-action-list-table th.subtasks {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}

