.hours-registration .category-cell-size {
    width: 400px;
}
.hours-registration-table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    min-height: 40px;
}

.hours-registration td {
    font-size: 14px;
    min-height: 40px;
    font-size: 12px;
    min-height: 40px;
    height: 50px;
    border-top: 2px solid white;
    border-right: 2px solid white;
}
.hours-registration td.project-cell {
   border-top: 1px solid #c0c0c0c0;
   border-bottom: 1px solid #c0c0c0c0;
   border-left: 1px solid #c0c0c0c0;
   padding: 5px;
   background: whitesmoke;
}
.hours-registration td.activity-cell {
    border-top: 1px solid #c0c0c0c0;
    border-bottom: 1px solid #c0c0c0c0;
    border-right: 1px solid #c0c0c0c0;
    padding: 5px;
 }
.hours-registration input {
    padding-right: 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 6px;
}
.hours-registration td.total {
    font-weight: bold;
    font-size: 18px;
    text-align: right;
    padding-right: 6px;
}
.hours-registration td .hour-cell-has-value {
    /* border: 1px solid #c0c0c0c0;
    color: darkgrey; */
    /* background: lightblue; */
}

.hours-registration td.day-total {
    /* background: #c0c0c0c0; */
    color: rgb(103, 106, 108);
    border-top: 1px solid silver;
    border-bottom: 3px double silver;
}
.hours-registration td.week-total {
    /* background: #c0c0c0c0; */
    background: white;
    border-top: 1px solid silver;
    border-bottom: 3px double silver;
}

/* Day cell - input registration */
.hours-registration td.day-cell {

    /* background: #fefefe; */
    /* background: red; */
}
.hours-registration td .day-cell-value {
    display:inline-block;
    /* background: white; */
   
    height: 100%;
    width: 100%;
    
}
.hours-registration td .day-cell-container {
    height: 100%;
    width: 100%;
}
.hours-registration td .day-dropdown-toggle {
    display:inline-block;
    height: 100%;
    width: 100%;
    /* background: #c0c0c0c0; */
    font-size: 16px;
    text-align: right;
    padding-right: 6px;
    padding-top: 10px;
}
.hours-registration td.day-cell:hover  {
    background: var(--digilean-blue-sky-lightest);
}
.hours-registration-day-cell-dropwdown {
    min-width: 300px;
    min-height: 300px;
    padding: 15px;
    padding-top: 5px;
}
.hours-registration-day-cell-dropwdown .day-registration {
    
}

