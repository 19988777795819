:root {
    /* color definitions */
    --digilean-black: #171717;
    --digilean-black-one: #242424;
    --digilean-black-two: #343434;
    --digilean-black-smokey: #0E0E0E;
    --digilean-black-onyx: #0f0f0f;
    --digilean-black-dark: #181513;
    --digilean-black-dark-grey: #181818;
    --digilean-black-dark-greyx: #141414;
    --digilean-blue-sky: #76c3ed;
    --digilean-blue-moon: #9dc4e7;
    --digilean-blue-sky-light: #acd3ef;
    --digilean-blue-sky-lighter: #d1e9f8;
    --digilean-blue-sky-lightest: #d9edf7;
    --digilean-blue: #1c93d3;
    --digilean-blue-diamond: #008cc9;
    --digilean-blue-frog: #2196d6;
    --digilean-blue-bird: #286090;
    --digilean-blue-alt: #2095d5;
    --digilean-blue-light: #58b2e3;
    --digilean-blue-lighter: #9BC9EB;
    --digilean-blue-sharp: #0099de;
    --digilean-blue-sleep: #2882c6;
    --digilean-blue-core: #1c84c6;
    --digilean-blue-night: #114f75;
    --digilean-blue-twilight: #265a88;
    --digilean-blue-dark: #2f4050;
    --digilean-blue-darker: #11171d;
    --digilean-blue-deep: #293846;
    --digilean-blue-moon: #34B3E4;
    --digilean-blue-raw: #0077B5;
    --digilean-blue-whale: #337ab7;
    --digilean-blue-berry: #23527c;
    --digilean-blue-turq: #bdedee;
    --digilean-cyan: #2F96B4;
    --digilean-azure: #23c6c8;
    --digilean-greeny: #4cae4c;
    --digilean-green: #51A351;
    --digilean-greens: #5cb85c;
    --digilean-green-turq: #1AB394;
    --digilean-green-turq-light: #6fd1bd;
    --digilean-green-dark: #234923;
    --digilean-green-strong: #5ab700;
    --digilean-green-olive: #7EAD4A;
    --digilean-green-pea: #8cc153;
    --digilean-green-meadow: #6cd96c;
    --digilean-green-bright: #d6ecc1;
    --digilean-greyy: #8c8b8b;
    --digilean-greyz: #3e4b58;
    --digilean-greyx: #5e5e5e;
    --digilean-grey: #676a6c;
    --digilean-grey-slate: #676C6A;
    --digilean-grey-default: #808080;
    --digilean-greydark-default: #A9A9A9;
    --digilean-grey-cement: #b4b6b8;
    --digilean-grey-blue: #637586;
    --digilean-grey-dark: #2f4050;
    --digilean-grey-mountain: #333333;
    --digilean-grey-wall: #555555;
    --digilean-grey-granite: #606060;
    --digilean-grey-andesite: #AAAAAA;
    --digilean-grey-sparrow: #dddddd;
    --digilean-grey-fog: #e7eaec;
    --digilean-grey-rain: #e5e5e5;
    --digilean-grey-smoke: #E5E6E7;
    --digilean-grey-asphalt: #e6e6e6;
    --digilean-grey-bootstrap-input :#ced4da;
    --digilean-grey-haze: #f5f5f5;
    --digilean-grey-light: #d2d2d2;
    --digilean-grey-xlight: #d1dade;
    --digilean-grey-lighter: #d4d4d4;
    --digilean-grey-lightest: #EFEFEF;
    --digilean-grey-very-light: #e7e7e7;
    --digilean-grey-very-lightx: #ebebeb;
    --digilean-grey-lightestx: #f3f3f4;
    --digilean-grey-pigeon: #999999;
    --digilean-grey-stone: #9B9B9B;
    --digilean-grey-stone-dark: #4d4d4d;
    --digilean-grey-silver: #C0C0C0;
    --digilean-red: #BD362F;
    --digilean-red-cardinal: #ed5565;
    --digilean-red-cardinal-dark: #9e2632;
    --digilean-red-sunset: #a94442;
    --digilean-red-carpet: #DA4453;
    --digilean-red-sharp: #FF5D5D;
    --digilean-red-default: #FF0000;
    --digilean-red-sharp-light: #FF7D7D;
    --digilean-red-knash: #FF4583;
    --digilean-red-knasher: #FF4081;
    --digilean-red-dark: #721c24;
    --digilean-dark-red: #8B0000;
    --digilean-pink: #f8d7da;
    --digilean-yellow-gold-default: #FFD700;
    --digilean-yellow-gold: #f8c500;
    --digilean-yellow-gold-dark: #8a6d3b;
    --digilean-yellow: #FFCE54;
    --digilean-orange: #F89406;
    --digilean-orange-dark: #8f5708;
    --digilean-mandarin: #F8AC59;
    --digilean-orange-sun: #f0ad4e;
    --digilean-orange-dawn: #eea236;
    --digilean-white-dow: #e8e8e8;
    --digilean-white-wash: #EEEEEE;
    --digilean-white: #ffffff;
    --digilean-white-almost: #f0f0f0;
    --digilean-white-ish: #f9f9f9;
    --digilean-white-box: #FAFCFE;
    --digilean-white-bright: #fafafa;
    --digilean-white-yellow: #fdfdfa;
    --digilean-white-anti-flash: #f1f1f1;
    --digilean-msteams: #f5f5f5;

    --digilean-grey-transparent: #8f8e8e28;
}