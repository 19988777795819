user-badge {
    display: inline-block;
}
user-badge .user-badge {
    border-radius: 50%;
    width: 2.8em;
    height: 2.8em;
}
user-badge .big .user-badge {
    width: 3.8rem;
    height: 3.8rem;
}
user-badge .person-image {
    border: 1px solid silver;
}

user-badge .person-name {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color:white;
    background: var(--digilean-primary);
    text-align: center;
    font-weight: bold;
}