.clock-counter {
    font-size: 16px;
    color: #337ab7;
    margin-right: 24px;
    margin-left: 24px;
    letter-spacing: 2px;
    font-weight: bold;
    width: 120px;
}
.clock-counter .near-the-end {
    color: red;
}

.countdown-counter {
    font-size: 23px;
    color: #337ab7;
    margin-right: 24px;
    margin-left: 24px;
    letter-spacing: 2px;
    font-weight: bold;
}

.countdown-counter .near-the-end {
    color: red;
}