.profilesettings  {
    padding-left: 15px;
}
.profilesettings h1.title {
    margin: 30px;
    margin-left: 200px;
}
.profilesettings .active{
    background: var(--digilean-secondary-background);
    border-left: 4px solid #0099DE;
    margin-right: -17px;
    border-top: 1px solid #E3E7EB;
    border-bottom: 1px solid #E3E7EB;
}
.profilesettings .menu {
    z-index: 2;
    max-width: 200px;
}
.profilesettings ul.nav {
    margin-top: 12px;
}
.profilesettings .settings-content{
    z-index: 1;
    min-height: 600px;
    border: 1px solid #E3E7EB;
    border-radius: 5px;
    padding: 15px;
}
.profilesettings .content-title {
    border-bottom: 1px solid #E3E7EB;
    padding: 10px;
    font-size: 18px;
}
.notification-config-label {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 0px;
    font-size: 13px;
    color: #676a6c;
}

.notification-config-label {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 0px;
    font-size: 13px;
    color: #676a6c;
}