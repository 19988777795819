.forgot-password  {
    margin-top: 50px;
}
.forgot-password a {
    color: white;
    text-decoration: underline;
}
.forgot-password a:hover {
    color: lightblue;
    font-weight: bold;
}
input:-webkit-autofill {
    background-color: bisque;
    color: red;
}