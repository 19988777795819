
.boardcard {
    height: 280px;
    width: 300px;
    background: var(--digilean-secondary-background);
    margin: 15px;
    margin-bottom: 20px;
    position: relative;
    /* overflow: hidden; */
}

.boardcard .product-box {
    height: 280px;
    width: 300px;
    background: var(--digilean-secondary-background);
    border-radius: 10px;
    border: 3px solid var(--digilean-secondary-background);
    box-shadow: var(--digilean-box-shadow);
}
.boardcard .product-box:hover {
    background: var(--digilean-secondary-background);
    box-shadow: var(--digilean-box-shadow-hover);
}
.boardcard .product-imitation {
    height: 150px;
    overflow: hidden;
    margin: 5px;
}
.boardcard .product-desc {
    padding: 5px 15px;
    
    position: relative;
}

.boardcard .preview-table {
    height: 100%;
    width: 100%;
    background: #f0f0f0;
}
.boardcard .preview-table th {
    font-size: 10px;
    max-height: 20px; 
    overflow: hidden;
}
.boardcard .preview-table td {
    font-size: 10px;
}

.boardcard .add-quick-access-button {
    position:absolute;
    bottom:10px;
    right:10px;
    font-size: 18px;
    color: gold;
}
.project-list .add-quick-access-button {
    /* position:absolute;
    bottom:10px;
    right:10px; */
    font-size: 18px;
    color: gold;
}
.project-portfolio-card {
    position: relative;
}

.project-portfolio .add-quick-access-button {
    position:absolute;
    bottom:20px;
    right:24px;
    font-size: 22px;
    color: gold;
}