.performance-board {
    height: 100%;
    width: 100%;
    background: white;
}
.performance-board .performance-row {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: 100%;
}

.performance-board .performance-column {
    flex: 1;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    border: 1px solid #f0f0f0;
    background: transparent;
    background: #f0f0f0;
    margin: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
}
.performance-board .performance-widget {
    height: 100%;
    background: white;
    min-height: 350px;
    margin-bottom: 10px;
}
.performance-board .performance-widget > div {
    height: 100%;
}
/* Angular Material Overrides */
.md-select-menu-container, md-backdrop {
    z-index: 9999 !important;
}
/* Override clock style */
.performance-board .clock-counter {
    font-size: 20px;
    /* color: #337ab7;
    margin-right: 24px;
    margin-left: 24px;
    letter-spacing: 2px;
    font-weight: bold;
    width: 120px; */
}