#appstore {
}

ul .appstore-list {
}

#appstore .digilean-selected-element {
    color: #fff;
    background-color: #63a0d4;
    border-color: #4f93ce;
        background-color: #337ab7;
    border-color: #337ab7;
}

.appstore-list .app-container {
    padding-top: 12px;
    float: left;
    width: 80px;
    height: 80px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #cccccc;
    margin: 6px;
}

    .appstore-list .app-container:hover {
        background: #f0ffff;
    }

    .appstore-list .app-container .fa {
        color: #17987e;
    }

.product-imitation {
    padding: 6px;
}

div.outer-box .ibox-content {
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
}

div.outer-box {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
}
#my-a3s .input-date {
    width: 60px;
}

.image-displayer .ibox{
    height: inherit;
    width: inherit;
    overflow: hidden;

}
.image-displayer  .uploader {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
}
.image-displayer .uploader .dropzone {
    height: 90%;
}
.image-displayer  img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
  /* width: 100%;
  height: 100%; */
  /* max-width: none;
  max-height: none;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
