/* --------- Learning Hub  style ------------ */
.learning-hub .tag-list li.select-button {
    /* width: 70px; */
}
.learning-hub .tag-list li a.selected {
    color: white;
    background-color: #0099DE;
    border-color: #0099DE;
  
}
/* --------- Learning viewer  style ------------ */
.learning-viewer .page-navigation {
    font-size: 14px;
}
.learning-designer .page-navigation {
    font-size: 14px;
}

/* --------- SUMMARY / Innholdsfortegnelse  style ------------ */
.learning-designer .publish-selection-list li {
    margin: 10px;
}
.learning-summary {
    min-height: 300px;   
    font-size: 14px;
}
.learning-summary ul {
    list-style-type:none;
    padding: 0;
}
.learning-summary li {
    margin: 0;
    padding: 3px;
}
.learning-summary .summary-item-sortable-placeholder {
    min-height: 20px;
    width: 100%;
    background: #f0f0f0;
    border: 1px solid silver;
}
.learning-summary .summary-item-title {
    
}
.learning-summary .chapter {
    list-style-type:none;
    
}
.learning-summary .chapter-content {
    border-top: 1px solid gray;
    border-bottom: 1px solid #f0f0f0;
    
}
.learning-summary .page-selected {
    font-weight: bold;
    /* border-bottom: 1px solid lightskyblue; */
}
.learning-summary .chapter .page-title {
    margin-left: 20px;
}
.learning-summary .chapter-title {
    font-weight: bold;
}
.learning-summary .chapter-page-list {
    min-height: 20px;

}

/* --------------------------------- */
/* --------- PAGE style ------------ */
/* --------------------------------- */
.learning-page {
    max-width: 1200px;
    min-height: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.learning-page .page-title-input {
    width: 90%;
}
.learning-page .article-title {
    margin-bottom: 50px;
}
.learning-page .add-section-toolbar {
    border-top: 2px solid silver;
}
.learning-page .add-section-toolbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.learning-page .add-section-toolbar li {
    display: inline-block;
    max-width: 160px;
    font-size: 14px;
}
.learning-page .add-section-button {
    min-width: 160px;
    display: block;
}
.learning-page .add-section-button-text {
    margin-left: 6px;
    font-size: 12px;
}
.learning-page .section-list {
    list-style-type:none;
}
.learning-page .learning-page-section {
    margin-bottom: 20px;
    font-size: 14px;
}
.row.equal {
    display: flex;
    flex-wrap: wrap;
}
.learning-section-drag-container {
    text-align: right;
    background: #f0f0f0;
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
    border-right: 1px solid silver;
    height: 100%;
}
.learning-section-toolbar {
    height: 38px;
}
.learning-page .section-sortable-placeholder {
    min-height: 100px;
    width: 100%;
    background: #f0f0f0;
    border: 1px solid silver;
    margin-bottom: 20px;
}

.learning-page-section {
    /* display: inline; */
}
.learning-page .learning-text-section {
    font-size: 14px;
}
.learning-text-section .fr-wrapper {
    border: 1px solid silver;
}
.social-body .fr-wrapper {
    border: 1px solid silver;
}
/* image-section */
.learning-page .learning-image-section img {
    max-width: 100%;
}
.learning-page .learning-image-section .image-description {
    margin: 10px;
    margin-bottom: -10px;
    padding: 10px;
    font-style: italic;
    background: #f0f0f0;
}
.learning-page .learning-image-section .image-edit-mode, .video-edit-mode {
    border: 1px solid silver;   
}
/* summary-section */
.learning-page .learning-summary-section {
    background: #DFF3FB;
    border: 1px solid #3BAFDA;
        padding: 10px;
}
.learning-summary-section .fr-wrapper {
    background: #DFF3FB !important;
}
/* file-section */
.learning-page .file-section-header {
    border-bottom: 1px solid gray;
}
/* Comments */
.comments .add-comment-box {
    border-width: 5px;
}
.comments .deleted-comment-box {
    background: #e7eaec;
    padding: 10px;
    min-height: 80px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.comments .level-0 {
    
}
.comments .level-1 {
    padding-right: 0;
    margin-top: 5px;
    border-radius: 10px;
}
.comments .level-2 {
    margin-top: 5px;
    margin-right: 0;
}
.comments .level-3 {
    margin-top: 5px;
    margin-right: 0;
}
.comments .level-4 {
    margin-top: 5px;
    margin-right: 0;
}
/* Evaluation */
.learning-page-evaluation {
    text-align: right;
    border-top: 1px solid grey;
    padding: 10px;
}
.learning-page-evaluation .question {
    font-size: 14px;
    margin-right: 20px;
    font-weight: 600;
}
.learning-page-evaluation .option-button {
    font-size: 20px;
    margin-left: 10px;
    color: silver;
}
.learning-page-evaluation .is-useful {
    color:rgb(140, 193, 82);
    font-size: 24px;
    
}
.learning-page-evaluation .is-star {
    color:gold;
    font-size: 24px;
    
}
.learning-page-evaluation .not-useful {
    color:  rgb(233, 87, 63);
    font-size: 24px;
}
/* --------------------------------- */
/* --------- Stats style ------------ */
/* -------------------------------- */
.learning-material-stats .heat-map-value {
    font-weight: bold;
    text-align: center;
}

.learning-material-stats .level-1 {
    background:#EDF7E3;
    border: 1px solid rgb(113, 194, 27);
}
.learning-material-stats .level-2 {
    background:rgb(225, 243, 206);
    border: 1px solid rgb(113, 194, 27);
}
.learning-material-stats .level-3 {
    background:rgb(206, 241, 170);
    border: 1px solid rgb(113, 194, 27);
}
.learning-material-stats .level-4 {
    background:rgb(113, 194, 27);
    border: 1px solid#EDF7E3;
    color: white;
}
.learning-material-stats .level-5 {
    background:#3BAFDA;
    border: 1px solid rgb(113, 194, 27);
}
/* .learning-material-stats td.heat-map-value {
    padding: 0;
    height: 20px;
    min-height: 20px;
    text-align: center;
}
.learning-material-stats .heat-map-cell {
    padding-top: 8px;
    font-size: 12px;
    border: 1px solid black;
} */