#teamtask .centerText{
  text-align: center;
  margin-bottom:0px;
}

#teamtask .break-word {
  word-wrap: break-word;
}

@media only screen and (min-width: 720px) {
  #teamtask .vl {
    border-left: 1px solid #D7D9D8;
    min-height: 300px;
  }
}

@media only screen and (max-width: 700px) {
  #teamtask .vl {
    padding-left: 0px;
  padding-right: 0px;
  }
}
#teamtask .default-person {
  margin:auto;
  color:white;
  background: #0099DE;
  height: 28px; 
  width: 28px; 
  border-radius: 50%; 
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  margin: 3px;
}

.teamtask .person-image-container {
  height: 33px; 
  width: 33px; 
  margin: 3px;
}


@media only screen and (max-width: 700px) {
  .team-tasks .connectList {
    min-height: 0px;
  }
}
/*
  .team-tasks .connectList {
    min-height:100px
  }
*/
@media only screen and (min-width: 635px) {
  .team-tasks{   
  min-height: 100px;
  }
}