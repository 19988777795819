
.task-row-table .task {
    position: relative;
    margin: 6px;
    padding: 6px;
    min-height: 40px;
    border-radius: 6px;
    border: 1px solid silver;
    background: var(--digilean-secondary-background);/*white;*/
    font-size: 1.3rem;
}
.task-row-table .task-end-outside-period {
    margin-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0;
}
.task-row-table .task-start-outside-period {
    margin-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0;
}
.task-row-table .task-status-symbol {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;    
}

.task-row-table .task .title {
    font-weight: bold;
    overflow: hidden;
    text-overflow: clip;
}
.task-row-table .task .title .status-symbol  {
    margin-right: 3px;
}
.task-row-table .task .title.OK {
    /* text-decoration: underline;
    text-decoration-color: #23c6c8; */
}
.task-row-table .task .OK .status-symbol  {
    border-radius: 50%;
    /* background: #23c6c8; */
    color: #23c6c8;
    padding: 3px;
    font-size: 14px;
}
.task-row-table .task .title.unacceptable {
    /* text-decoration: line-through;
    text-decoration-color: #FF0000; */
}
.task-row-table .task .unacceptable .status-symbol  {
    border-radius: 50%;
    /* background: white; */
    color: white;
    padding: 3px;
    font-size: 16px;
    color:  #FF0000;
}

.task-status-selection .disabled-status {
    color: #e7eaec; /* set not selected color */
}

.task .task-responsible {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 3px;
    text-align: right;
    min-height: 30px;
    min-width: 30px;
    font-size: 26px;
    color: #C0C0C0;
    
}
.task .options-container {
    /* min-height: 30px; */
    /* padding: 3px; */
}
.task .task-options {
    height: 30px;
    width: 30px;
    text-align: center;
    padding-top: 6px; 
    /* background: #3BAFDA; */
}
.task .task-options i {
    font-size: 16px;
    /* background: #3BAFDA; */
}
.task .options-container .task-responsible:hover,
.task .task-options:hover {
    background: #f1f1f1;
}
.dropdown-menu .menu-item-icon {
    margin-left: 3px;
    margin-right: 12px;
}
.task .person-image-container {
    height: 33px; 
    width: 33px; 
    margin: 3px;
}
.task .person-image-container img {
    height: 28px; 
    width: 28px; 
    border-radius: 50%; 
    border: 1px solid silver;
}

.task .default-person {
    margin:auto;
    color:white;
    background: #0099DE;
    height: 28px; 
    width: 28px; 
    border-radius: 50%; 
    padding-top: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin: 3px;
}

.task-status-selection .ok-button {
    background: #23c6c8;
    color:white;
}

.task-status-selection .unacceptable-button {
    background: #FF0000;
    color: white;
}


.task-status-selection .blank-button {
    color: #C0C0C0;
}
