#boardDesigner .delete-category {
    color: red;
    background: white;
    /*border: 1px solid silver;*/
    text-align: center;
    height: 16px;
    width: 16px;
    position: absolute;
    top: -6px;
    right: -6px;
}

#boardDesigner #connectedUserList {
    margin: 0;
    margin-right: 30px;
}

#boardDesigner .delete-category span {
    color: red;
}

.as-sortable-drag .delete-category {
    display: none;
}

#boardDesigner .row-category {
    position: relative;
}

.row-container .as-sortable-item {
    height: 40px;
    width: 140px;
    margin: 12px;
    color: white;
    border: 0;
}

.row-container .as-sortable-item-handle {
    height: 40px;
    color: white;
    padding: 6px;
}

.row-container .as-sortable-placeholder {
    /*max-height: 50px;*/
    min-height: 20px;
}

#boardDesigner .add-column-button {
    float: right;
    margin: 12px;
    margin-right: 18px;
}

#column-container {
    /*display: inline-block;*/
    /*height: 40px;*/
    float: left;
}
#column-container .column-category {
    position: relative;
}

#column-container .as-sortable-item {
    display: inline-block;
    height: 40px;
    float: left;
    min-width: 100px;
    margin-right: 6px;
    margin-top: 3px;
}

#column-container .as-sortable-item-handle {
    height: inherit;
    max-height: 50px;
    padding: 6px;
    background: silver;
    color: white;
}

#column-container .as-sortable-placeholder {
    display: inline-block;
    max-height: 50px;
    min-height: 20px;
    float: left;
}

#column-container .sortable-row:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

#boardCategoryForm .category-preview {
    height: 30px;
    padding: 6px;
}

#boardCategoryForm .category-preview-title {
    margin-left: 6px;
    font-size: 12px;
}