.my-notifications {
    max-height: 40px;
    /* height: 40px;
    position: relative; */
}
.my-notifications .notification-toggle {
    width: 40px;
    position: relative; 
}
.my-notifications .notification-icon{
    font-size: 18px;
    width: 50px;
}
.my-notifications .notification-item-count {
    position: absolute;
    top: 12px;
    left: 10px;
}
.my-notifications-dropdown {
    max-width: 400px;
}
.my-notifications-list {
    padding-bottom:0px; 
    overflow-y: auto; 
    max-height: 500px; 
    min-width: 360px;
    list-style: none;
}
.my-notifications-dropdown .inbox {
    height: 40px;
    border-top: 2px solid #f3f3f4;
    padding: 10px;
    font-size: 16px;
    /* background: #f3f3f4; */
    text-align: center;
}
.my-notification-item-row{
    margin-right: 15px;
    margin-left: 15px;
    min-height: 60px;
    padding: 5px;
    border-top: 1px solid #c0c0c0;
}
.my-notification-item-row.is-new {
    background:#f3f3f4;
}
.my-notification-item-row .info {
    /* margin: 3px;
    padding: 5px; */
    color: black;
    padding-top: 5px;
    /* background: #f8f8f9;
    border: 1px solid #c0c0c0;
    border-radius: 5px; */
}
.my-notification-item-row .info .action{
    color: silver;
}

.my-feedback-item:not(:first-child) {
    border-top: 1px solid #eee;
  }

