/* spinkit only the classes we need */
 .sk-spinner-three-bounce.sk-spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;
}

.sk-spinner-three-bounce div {
    width: 18px;
    height: 18px;
    background-color: #0099DE;
    border-radius: 100%;
    display: inline-block;
    animation: sk-threeBounceDelay 1.4s infinite ease-in-out;
    animation-fill-mode: both;
}

.sk-spinner-three-bounce .sk-bounce1 {
    animation-delay: -0.32s;
}

.sk-spinner-three-bounce .sk-bounce2 {
    animation-delay: -0.16s;
}

@keyframes sk-threeBounceDelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}