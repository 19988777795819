.a3-template-app {
    height: 310px;
    overflow: hidden;
}

.a3-template-store .tag-list li a  {
    background: white;
}
.a3-template-store .tag-list li a.selected  {
    color: white;
    background-color: #0099DE;
    border-color: #0099DE;
}
.tag-list li a.selected  {
    color: white;
    background-color: #0099DE;
    border-color: #0099DE;
}