/* .table-row:nth-child(even) {background: #CCC}
.table-row:nth-child(odd) {background: #FFF} */

.table { display:table; }
.table-header-group { display: table-header-group; }
.table-row-group {
    display: table-row-group;
    margin-bottom: 0.5rem;
}
.table-row {
    display:table-row;
    border: 1px solid var(--digilean-white);
}
.table-cell {
    display: table-cell;
    padding: 3px;
    border: 1px solid var(--digilean-white);
}
.table-header-group .table-cell {
    font-weight: bold;
}
a.table-cell {
    background-color: var(--digilean-white-box);
}


.div-row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.div-centre {
    display: inline-flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.filter-monitor-logger {
    display: flex;
    display: row;
    gap: 3rem;
}

.connector-job-runs-count {
    width: 60px;
}

div.monitor-header-row {
    display: flex;
    flex-direction: row;
    section {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        padding-right: 2rem;
    }
    section.left {
        flex: 1 1 auto;
    }
}