.image-preview-list {
    /* background: red; */
}
.image-preview-list .image-file-preview {
    max-width: 200px;
    float: left;
    margin: 10px;
}
.image-preview-list .image-file-preview img {
    max-height: 100px;
    width: 100%;
    height: 100px;
    object-fit: cover;
    border: none;
 }