#apiclients table.apiclients {
    border-spacing: 25px;
}

#apiclients table.apiclients thead th, #apiclients table.apiclients tbody td {
    padding: 0.5rem 1rem;
}

#editClientApiForm table.secrets thead th, #editClientApiForm table.secrets tbody td{
    padding: 0.5rem 1rem;
}