.profile-image-container .xs {
    height: 18px;
    width: 18px;
}
.profile-image-container .small {
    height: 36px;
    width: 36px;
}
.profile-image-container .mediumsmall {
    height: 48px;
    width: 48px;
}
.profile-image-container .medium {
    height: 72px;
    width: 72px;
}
.profile-image-container .large {
    height: 144px;
    width: 144px;
}

.profile-status .highcharts-background {
	fill: #f3f3f4;
}
