
.a3viewer {
    overflow: hidden;
}
.a3viewer .widget {
    height: calc(100% - 2px);
}
.a3viewer .digilean-widget {
    box-shadow: none !important;
    border: 1px solid #f0f0f0;
}
.a3viewer .ibox-content {
    border: none;
    padding:0;
}
.a3viewer .preview-header {
    padding:6px; 
    margin-top:0px;
    padding-left:7px;

}
    .a3viewer .widget .widget-component {
        height: inherit;
        height: 100%;
        overflow-y: scroll;
    }

    .a3viewer .widget .widget-header {
        color: gray;
        background-color: #f0f0f0;
        border-color: silver;
    }

    .a3viewer label {
        margin-top: 5px;
    }

    .a3viewer .widget .widget-content {
        /*height: inherit;*/
        height: 100%;
        overflow-y: hidden;
        border: 2px solid #f0f0f0;   
    }

    .a3viewer .widget .add-app-button {
        display: none;
    }

    .a3viewer .participants {
        margin-bottom: 10px;
    }

    .a3viewer .participants li {
        display: inline;list-style-type: none;padding-right: 6px; padding-bottom: 5px;
    }

    .a3viewer .dropdown-menu{
        min-width: 220px;
    }
    

.a3viewer a {
    color: #ccc;
    color: #676C6A;
}

.a3document a {
    color: #676C6A;
}



.a3document{
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    flex:1 ;
    vertical-align: top;
    transform-origin: top left;

}

form {
    margin-bottom: 0;
}

.a3viewer .widget-component {
    border: 1px solid #ccc;
    /* box-shadow: 0 0 5px 1px rgba(0,0,0,.10); */
    max-height: 100%;
    overflow-y: auto;
}

.a3background{
    overflow: auto;
    display: block;
}

.preview-header-buttons{
    display:flex;
    flex-direction: row;
    max-width: 615px;
    width: 615px;
    float: right;
    margin-right: 10px;
}

.a3viewer .fr-box.fr-basic .fr-element{
    /* height: 300px; */
    font-size: 14px;
}

#A3document .icon-button-position {
    z-index:6;
    position:absolute;
    margin-top:-6px;
    margin-right:-6px;
}

