.lightbox-nav {
  position: relative;
  margin-bottom: 12px; /* the font-size of .btn-xs */
  height: 22px;
  text-align: center;
  font-size: 0; /* prevent the otherwise inherited font-size and line-height from adding extra space to the bottom of this div */
}

.lightbox-nav .btn-group {
  vertical-align: top;
}

.lightbox-nav .close {
  /* absolutely position this in order to center the nav buttons */
  position: absolute;
  top: 0;
  right: 0;
}

.lightbox-image-container {
  position: relative;
  text-align: center; /* center the image */
}

/* the caption overlays the top left corner of the image */
.lightbox-image-caption {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5em 0.9em; /* the left and right margins are offset by 0.4em for the span box-shadow */
  color: #000;
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  text-shadow: 0.1em 0.1em 0.2em rgba(255, 255, 255, 0.5);
}

.lightbox-image-caption span {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  background-color: rgba(255, 255, 255, 0.75);
  /* pad the left and right of each line of text */
  box-shadow: 0.4em 0 0 rgba(255, 255, 255, 0.75),
    -0.4em 0 0 rgba(255, 255, 255, 0.75);
}
