/* CALENDAR */
.calendar .OK {
    background: #e0fbf5;
    background:#dff0d8;
    border: 1px solid #d6e9c6;
    color: black;
}

.calendar .unacceptable {
    background: #f2dede;
    border: 1px solid #ebccd1;
    color: black;
}
.calendar .blank {
    background: white;
    border: 1px solid silver;
    color: black;
}