/* WHEN hosted in Smartactionlist */
/*.smart-action-list .yearly-board-content-container {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
}*/
.ellipsis  {
    text-overflow: ellipsis;
    max-width: 100px;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

/* END WHEN hosted in Smartactionlist */
.yearlyboard {
    margin-top: 15px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); Looks awful in dark mode */
}
.yearly-board-container {
    overflow-x: auto;
    overflow-y: auto;
    padding-left: 0px;
    padding-right: 0px;
}
.yearly-board-container .category-cell-size {
    width: 180px;
}
.yearly-board-container .category-cell-size .previous-period{
    width: 50px;
    text-align: right;
    padding: 6px;
    display: table-cell;
}
.yearly-board-container .category-cell-size .period-content{
    width: 150px;
    display: table-cell;
}
.yearly-board-container .category-cell-size .next-period{
    width: 50px;
    text-align: left;
    padding: 6px;
    display: table-cell;
}
.yearly-board-container h2 {
    text-align-last: center;
}
.yearly-board-table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    min-height: 40px;
    min-width: 1000px;
}
.yearly-board-table th{
    padding: 6px;
    text-align: center;
    font-size: 1.2rem;
}
.yearly-board-table .header-selectable:hover{
    background: var(--digilean-cell-contrast); /*#f1f1f1;*/
}

.yearly-board-table.view-selection th {
    border-bottom: 2px solid #CBCBCB;
    border-left: 1px solid #CBCBCB;
    border-right: 1px solid #CBCBCB;
    border-top: 1px solid #CBCBCB;
}
.yearly-board-table .month-selected {
    background: #CFEEF9;
}
.yearly-board-table .week-header {
    /* background: #F2F7F9; */
    border: 2px solid var(--digilean-secondary-background);/*white;*/
    border-bottom: none;
}
.yearly-board-table .week-selected {
    background: #CFEEF9;
}

.yearly-board-table .week-header .week-number { 
    font-size: 14px;
}
.yearly-board-table .week-header .small-date {
    margin-left: 6px; 
    margin-right: 6px;
    font-size: 10px;
    font-weight: 300;
    margin-top: 6px;
}
.yearly-board-table .week-info .medium-date {
    margin-left: 2px; 
    margin-right: 2px;
    font-size: 14px;
    font-weight: 300;
}

.yearly-board-table .day-header.Saturday {
    /* background: #F2F7F9; */
    border-bottom: 2px solid #3BAFDA;
}

.yearly-board-table .day-header.Sunday {
    /* background: #F2F7F9; */
    border-bottom: 2px solid #E9573F;
}

.yearly-board-frame {
    background: var(--digilean-secondary-background);
    margin-top: 1px; 
    width: 100%; 
    padding-top: 5px; 
    padding-bottom: 5px;
}

.yearly-board-container .content-container {
    border-bottom: 2px solid #CBCBCB;
    border-left: 1px solid #CBCBCB;
    border-right: 1px solid #CBCBCB;
    border-top: 0px solid #CBCBCB;
}
.yearly-board-container .board-row {
    position: relative;
    min-height: 50px;
    width: 100%;
    margin-bottom: 2px;
}
.yearly-board-container .row-category {
    padding: 10px;
    /* padding-top: 20px; */
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    height: 100%;
    width: 100%;
    /* border-bottom: 2px solid #CBCBCB;
    border-left: 1px solid #CBCBCB;
    border-right: 1px solid #CBCBCB;
    border-top: 0px solid #CBCBCB; */
}
.yearly-board-container .row-background {
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
}
.yearly-board-container .row-content {
    height: 100%;
    position: relative;
    z-index: 2;
}
.yearly-board-container .drop-row {
    position: absolute;
    height: 100%;
    top: 0;
    background: transparent;
    z-index: 1;
}
.yearly-board-container .loading-row {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    top: 0;
    background: transparent;
    z-index: 4;
}
.drop-row-table {
    width: 100%;
    height: 100%;
    min-height: 40px;
}
.drop-row-table td {
    background: transparent;
    border: none;
}
.drop-row-table .drag-over {
    background: rgb(142, 201, 228);
    opacity: 0.4;
}
.drop-row-table .active-cell {
    background: rgb(142, 201, 228);
    opacity: 0.4;
}
.task-cell .new-task-container {
    display: block;
    height: 100%;
    width: 100%;
}
.yearly-board-table td {
    background: var(--digilean-cell-contrast); /*#f1f1f1;*/
    font-size: 1em;
    min-height: 40px;
    height: 50px;
    border-top: 2px solid var(--digilean-secondary-background);/*white;*/
    border-right: 2px solid var(--digilean-secondary-background);/*white;*/
}
.task-row-table {
    width: 100%;
    min-height: 40px;
    
    /* margin-bottom: 20px; */
}
.task-row-table td{
    height: 10px;
    /* vertical-align: top; */
}
.task-container.gu-mirror {
    min-width: 180px;
    padding: 6px;
    min-height: 30px;
    border-radius: 6px;
    border: 1px solid silver;
}

.task-container.gu-transit {
 display: none; 
}
.loading-row .bounce-container {
    height: 30px;
    width: 100px;
}