#five-s body {
    font-family: "Source Sans Pro";
    background-color: #f0f2f5;
}

.ibox.letter-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ibox.letter-wrapper .ibox-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro';
    background-color: var(--digilean-secondary-background);
}

.ibox.letter-wrapper .ibox-content .five-s-container {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
}

.ibox.letter-wrapper .ibox-content .row {
    padding-top: 50px;
}

/* will not work if append to body is used*/
#daily-kpi-drop-break .dropdown-menu li,#daily-kpi-drop-break .dropdown-menu li a {
    white-space: normal;
    float: left;
    width: 100%;
    height: auto;
    word-wrap: break-word;
}

.daily-kpi-gray {
    background: gray;
    color: white;
}

    .daily-kpi-gray a {
        color: white;
    }
.five-s-container {
    max-height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    /* min-height: 350px;
    min-width: 650px; */
}
.aspect-ratio-letter {
    height: 100%;
    max-height: 100%;
    width: 100%;
    aspect-ratio: 16 / 9;
}
.five-s-container table {
    height: 100%;
    max-height: 100%;
    width: 100%;
    position: absolute;
    border-spacing: 1px;
    border-collapse: separate;
}
.five-s-container table tbody {
    height: 100%;
    max-height: 100%;
}
.five-s-container td {
    min-width: 15px;
    min-height: 15px;
    height: 15px;
    border-radius: 5px;
    margin: 3px;
    /*-webkit-box-shadow: 0px 3px 0px 0px rgba(226, 228, 231, 0.75);
    -moz-box-shadow: 0px 3px 0px 0px rgba(226, 228, 231, 0.75);
    box-shadow: 0px 3px 0px 0px rgba(226, 228, 231, 0.75);*/
}

.five-s-container .five-s-cell {
    background-color: var(--digilean-secondary-background);/*#fff;*/
}

.five-s-container .day-cell {
    background: grey;
}
.five-s-container .empty-cell {
    background-color: silver;
}
.five-s-container .day-cell-container {
    height: 100%;
    cursor: pointer;
}

.five-s-container .rounded-upper-right {
    border-top-right-radius: 80% !important;
}
.five-s-container .rounded-upper-left {
    border-top-left-radius: 80% !important;
}

.five-s-container .rounded-lower-right {
    border-bottom-right-radius: 80% !important;
}
.five-s-container .rounded-lower-left {
    border-bottom-left-radius: 80% !important;
}

.five-s-container .daily-kpi {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.five-s-container .daily-kpi-value  {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.number-month {
    width: 60%;
    height: 70%;
}
