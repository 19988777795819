.drop-archive.drop-archive-container {
    /* background: gold; */
    margin-top: -20px;
}
.drop-archive .drop-archive-icon i {
    margin-top: 10px;
}
.drop-archive ul {
    /* display: inline-block; */
    margin: 0;
    padding: 0;
}
.drop-archive li {
    list-style: none;
}
.drop-archive .as-sortable-placeholder {
    /* display: inline-block; */
    max-height: 60px;
    min-height: 60px;
    max-width: 100px;
    float: left;
}

#deviationevaluation .digilean-selected-element {
    color: #fff;
    background-color: #63a0d4;
    border-color: #4f93ce;
    background-color: #337ab7;
    border-color: #337ab7;
}
#ongoingDeviationContainer .overdue{
    font-weight:bold;
}

.consequence-container {
    margin-top: 9px;
}
.consequence-item {
    margin: 3px;
    
}
.consequence-item .amount {
    font-weight: bold;
}
.consequence-item .unit {

}
.deviation-evaluation {
    margin: 3px;
}

#newdevation .row {
    margin-right:0px;
    margin-left: 0px;
}

#newdevation .step-content{
    padding-top:15px;
}

#newdevation .hr-line-dashed {
    color:#676a6c;
}

#newdevation .actionButton {
    width: 70px;
    text-align: center;
}

#newdevation .col-modal-footer {
    padding-right: 25px;
}

.deviation-type-list li {
    padding: 5px 10px;
    font-size: 14px;
    color: var(--digilean-list-link);
}

    .deviation-type-list li a {
        color: var(--digilean-list-link);
    }

        .deviation-type-list  li:hover {
            background-color: var(--digilean-tertiary-background);
        }

.fullDeviation-modal-window .modal-dialog {
    height: auto;
}

.severity-selection .low-severity {
    border-left: 3px solid #23c6c8;
    text-align: center;
    
}
.severity-selection .medium-severity {
    border-left: 5px solid #FFCE54;
    text-align: center;
}
.severity-selection .high-severity {
    border-left: 7px solid #DA4453;
    text-align: center;
}

.agile-list li.low-severity {
    border: 1px solid #23c6c8;
    border-top: 2px solid #23c6c8;
    border-radius: 5px;
    color: var(--digilean-grey-mountain);
    background: var(--digilean-blue-turq);
}

.agile-list li.medium-severity {
    border: 1px solid #FFCE54;
    border-top: 2px solid #FFCE54;
    border-radius: 5px;
    color: var(--digilean-grey-mountain);
    background: #fce6cd;
}

.agile-list li.high-severity {
    border: 1px solid #DA4453;
    border-top: 2px solid #DA4453;
    border-radius: 5px;
    background: #f9ccd0;
}
.low-severity-button {
    border: 1px solid #23c6c8;
    border: 2px solid #abeff0;
    background: #ddf9f9;
}

.high-severity-button {
    border: 1px solid #DA4453;
    border: 2px solid #ebccd1;
    background: #f2dede;
}

.medium-severity-button {
    border: 1px solid #FFCE54;
    border: 2px solid #faebcc;
    background: #fcf8e3;
}

#ongoingDeviationContainer .info-buttons {
    margin-right: 2px;
    margin-top: 2px;
} 

#ongoingDeviationContainer ul.agile-list {
    /* background: #f0f0f0; */
    border: 1px solid #f0f0f0;
    background: #FAFAFA;
    padding: 5px;
    margin-bottom: 20px;
}
li.deviation-card {
    border: 1px solid var(--digilean-grey-silver);
    border-top: 2px solid var(--digilean-grey-silver);
    border-radius: 5px;
    position:relative;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 12px;
    word-wrap: break-word;    
    background: var(--digilean-secondary-background);
    box-shadow: var(--digilean-box-shadow);
    min-height: 60px;
 }
 .deviation-card img {
    max-height: 140px;
    width: 100%;
    height: 100px;
    object-fit: cover;
    margin-bottom: 5px;
}
.deviation-card h3.title {
    font-size: 14px;
    color: var(--digilean-secondary-text);
}
.deviation-card.low-severity h3.title {
    color: var(--digilean-grey-mountain);
}
.deviation-card.low-severity .created-date {
    color: var(--digilean-grey-mountain);
}
.deviation-card.medium-severity h3.title {
    color: var(--digilean-grey-mountain);
}
/* .deviation-card.medium-severity .created-date {
    color: var(--digilean-grey-mountain);
} */
.deviation-card .created-date {
    font-size: 10px;
    color: var(--digilean-primary-text);
}
 .deviation-card .date{
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
    background:#999999;
    width: 40px;
    height: 40px;
    padding: 5px 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #FFFFFF;
    font-weight: 700; /*ok*/
    text-align: center;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
 }
/* 
 * The Smily:
 */

 #evaluationstatus .disabled-smily {
    color: #e7eaec; /* set not selected color */
}

#evaluationstatus .checked-smily {
    color: #8CC152; /* set selected color */
}

/* 
 * The meh
 */

#evaluationstatus .disabled-meh {
    color: #e7eaec; /* set not selected color */
}

#evaluationstatus .checked-meh {
    color: #FFCE54; /* set selected color */
}

/* 
 * The frown:
 */

#evaluationstatus .disabled-frown {
    color: #e7eaec; /* set not selected color */
}

#evaluationstatus .checked-frown {
    color: #DA4453; /* set selected color */
}


    #deviationActionlist .fa-check-square {
        margin-right: 2px;
        color: #5cb85c;
    }
    #deviationActionlist .fa-times-circle {
        color: #E9573F;
    }
    #deviationActionlist input {
        border: none;
        background: transparent;
    }

    .deviationActionlist .input-date {
        width: 70px;
    }

    #deviationActionlist .input-x-lg {
        width: 400px;
    }

    #deviationActionlist textarea {
        min-height: 12px;
        max-height: 100px;

        resize: none;
        outline: none;
        overflow: hidden;
        border:0;
    }

    #deviationActionlist .input-s {
        width: 60px;
    }

div.ongoing-deviation {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}
div.ongoing-deviation-scroll-y {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
}